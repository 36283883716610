
export function momentAgo(date:number):string {
  let now = new Date();
  let dataStart = new Date(date*1000)
  // @ts-ignore
  let differSec = (now - dataStart) / 1000;

  if (differSec < 1)
    return "right now";
  if (differSec < 60)
    return `${Math.floor(differSec)} sec. ago`;
  if (differSec < 3600)
    return `${Math.floor(differSec / 60)} min. ago`
  if (differSec < 86400)
    return `${Math.floor(differSec / 3600)} hr. ago`
  if (differSec >= 86400)
    return `${Math.floor(differSec / 86400)} days ago`

  return ""
}