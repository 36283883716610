//stringToNumber.util.ts

export const stringToNumber = (value?: string | number): number | undefined => {
  value = value != undefined ?
    value.toString().trim() :
    "";

  return /^\d+[.,]?\d*$/.test(value) ?
    +(value.replace(
      /,/g,
      "."
    )) :
    undefined;
};