import React, {FC} from "react";
import close from "../../template/img/close.svg";
import {IStateScreen} from "../../core/models";
import {StorageService} from "../../core/services";
import {useAppSelector} from "../../core/stores/store";

interface IChoice {
    openChoice: boolean;
    // isAuthorized: boolean;
    pushSound: HTMLAudioElement
    setOpenChoiceCallback: (param: boolean) => void;
    setOpenConnectWalletCallback: (param: boolean) => void;
    //setFreePlayCallback: (param: boolean) => void;
    setStateScreenCallback: (param: IStateScreen) => void;
    setNoAuthChoiceModCallback: (param: IStateScreen) => void;

}

const ChoiceModal: FC<IChoice> = ({
                                      openChoice,
                                      // isAuthorized,
                                      pushSound,
                                      setOpenChoiceCallback,
                                      setOpenConnectWalletCallback,
                                      setStateScreenCallback,
                                      setNoAuthChoiceModCallback
                                  }) => {
    const profile = useAppSelector(state => state.account.data);
    const isAuthorized = profile.isAuthorized;

    return (
        <div className={`modal-bg${openChoice ? " show" : ""}`}>
            <div className={`modal${openChoice ? " show" : ""}`} id="modal-3">
                <a href="#" className="close modal-close" onClick={() => setOpenChoiceCallback(false)}>
                    <img src={close} alt=""/>
                </a>
                <div className="content">
                    <div className="rw mt-60 mb-60">
                        <div className="c-m-6">
                            <div className="fp-box">
                                <div className="title mb-30">FREE FLIP</div>
                                <div className="text mb-30">Test your luck, completely free!</div>
                                <div className="d-center mt-60">
                                    <a className="btn-vl btn-pink modal-close screen-open"
                                       onClick={() => {
                                           pushSound.play().then();
                                           setOpenChoiceCallback(false);
                                           setStateScreenCallback(IStateScreen.PlayFree);
                                           StorageService.mode.set(IStateScreen.PlayFree);
                                           //setFreePlayCallback(true);
                                       }}
                                    ><span>Flip for free</span></a>
                                </div>
                            </div>
                        </div>
                        <div className="c-m-6">
                            <div className="fp-box">
                                <div className="title mb-30">Gamerfi balance</div>
                                <div className="text mb-30">Deposit into your GamerFi balance, and all flip gas fees are
                                    on us.
                                </div>
                                <div className="d-center mt-60">
                                    <a className="btn-vl btn-blue"
                                       onClick={() => {
                                           pushSound.play().then();
                                           if (isAuthorized) {
                                               setStateScreenCallback(IStateScreen.Deposit);
                                               setOpenChoiceCallback(false);
                                           } else {
                                               setOpenChoiceCallback(false);
                                               setNoAuthChoiceModCallback(IStateScreen.Play)
                                               setOpenConnectWalletCallback(true);
                                           }
                                       }}><span>{isAuthorized ? "Deposit" : "Connect"}</span></a>
                                </div>
                            </div>
                        </div>
                        <div className="c-m-6">
                            <div className="fp-box">
                                <div className="title mb-30">Flip from wallet</div>
                                <div className="text mb-15">Flip directly from your wallet.</div>
                                <div className="text mb-30 note">Note: you will have to pay the gas fees</div>
                                <div className="d-center mt-50">
                                    <a className="btn-vl btn-yellow"
                                       onClick={() => {
                                           pushSound.play().then();
                                           if (isAuthorized) {
                                               setStateScreenCallback(IStateScreen.PlayToWallet);
                                               StorageService.mode.set(IStateScreen.PlayToWallet);
                                               setOpenChoiceCallback(false);
                                           } else {
                                               setOpenChoiceCallback(false);
                                               setNoAuthChoiceModCallback(IStateScreen.PlayToWallet)
                                               setOpenConnectWalletCallback(true);
                                           }
                                       }}><span>{isAuthorized ? "Flip from wallet" : "Connect"}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ChoiceModal;