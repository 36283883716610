//exchangeRate.api.ts

import { XhrService } from "../../services";
import { call } from "./axios";
import { ExchangeRateEndpointsApi } from "../../swaggerFlips/src";
import { getConfiguration } from "./swagger";
import { processError } from "../../utils/helpers";
import { ErrorEnum } from "../../view/react/core/models";
import { contract } from "./contract";

export class ExchangeRateApi {
  public static async ExchangeRatesUsdInToken(networkId: number) {
    try {
      const data = await XhrService.call<{ [key: string]: number; }>(
        // async () => {
        //   return await contract.requestWithCheckNetwork(
        //     networkId,
            async () => {
              return await call<{ [key: string]: number; }>(
                async () => {
                  return await this.getExchangeRateApiInstance()
                    .apiFlipsExchangeRatesUsdInTokenGet();
                });
            }
        //   );
        // }
        );
      return data
    }
    catch (error: any) {
      throw processError(
        ErrorEnum.API,
        "ExchangeRateApi.ExchangeRatesUsdInToken(). Error",
        error.message
      );
    }
  }

  public static async ExchangeRatesWeiInUsd(networkId: number) {
    try {
      const data = await XhrService.call<{ [key: string]: number; }>(
        // async () => {
        //   return await contract.requestWithCheckNetwork(
        //     networkId,
            async () => {
              return await call<{ [key: string]: number; }>(
                async () => {
                  return await this.getExchangeRateApiInstance()
                    .apiFlipsExchangeRatesWeiInUsdGet();
                });
            }
        //   );
        // }
        );
      return data
    }
    catch (error: any) {
      throw processError(
        ErrorEnum.API,
        "ExchangeRateApi.ExchangeRatesWeiInUsd(). Error",
        error.message
      );
    }
  }

  private static getExchangeRateApiInstance(): ExchangeRateEndpointsApi {
    return new ExchangeRateEndpointsApi(getConfiguration(
      undefined,
      false,
      false
    ));
  }
}