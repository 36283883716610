import React, { FC } from "react";
//import discord from "../../template/img/discord.svg";
import twitter from "../../template/img/twitterNew.svg";
import logo from "../../template/img/logo.png";
import { IdentityService } from "../../core/xhr/identity";
import { AccountData, IAccountData } from "../../core/models/account";
import { IStateScreen } from "../../core/models";
import { checkNetwork } from "../../core/services";
import {useAppDispatch, useAppSelector} from "../../core/stores/store";
import {setLoggedOutAccount} from "../../core/stores/accountSlice";

interface IFooter {
  setOpenHowToPlayCallback: (param: boolean) => void;
  setOpenFaqCallback: (param: boolean) => void;
  setOpenProvablyFairCallback: (param: boolean) => void;
  setOpenHowToVerifyCallback: (param: boolean) => void;
  setOpenWrongNetworkCallback: (param: boolean) => void;
  setStateScreenCallback: (param: IStateScreen) => void;
  setOpenConnectWalletCallback: (param: boolean) => void;
  // setProfileCallback: (prevState: (prevState: IAccountData) => IAccountData) => void;
  stateScreen: IStateScreen;
  // isAuthorized: boolean
  pushSound: HTMLAudioElement
  networkId: number
}

const Footer: FC<IFooter> = ({
  setOpenHowToPlayCallback,
  setOpenFaqCallback,
  setOpenProvablyFairCallback,
  setOpenHowToVerifyCallback,
  setOpenWrongNetworkCallback,
  setStateScreenCallback,
  setOpenConnectWalletCallback,
  // setProfileCallback,
  stateScreen,
  networkId,
  pushSound
}) => {
  const settings = useAppSelector(state => state.globalSettings.settings);
  const profile = useAppSelector(state => state.account.data);
  const isAuthorized = profile.isAuthorized;
  const dispatch = useAppDispatch();

  function connect() {
    setOpenConnectWalletCallback(true);
  }

  function logOut() {
    IdentityService.logOut(false);
    dispatch(setLoggedOutAccount());
  }

  return (
    <div className="footer">
      <div className="container">
        <div className="footer-logo">
          <img src={logo} alt="" />
        </div>
        <div className="footer-btn">
          {isAuthorized &&
            <>
              <div className={`btn-vl btn-blue${stateScreen === IStateScreen.Deposit ? " active" : ""}`}
                   onClick={() => {
                     pushSound.play().then();
                     if (checkNetwork(networkId, settings)) {
                      setStateScreenCallback(IStateScreen.Deposit);
                       window.scrollTo(0,0);
                     }
                     else {
                       setOpenWrongNetworkCallback(true);
                     }
                   }}><span>Deposit</span></div>
              <div className={`btn-vl btn-blue${stateScreen === IStateScreen.Withdraw ? " active" : ""}`}
                   onClick={() => {
                     pushSound.play().then();
                     if (checkNetwork(networkId, settings)) {
                      setStateScreenCallback(IStateScreen.Withdraw)
                       window.scrollTo(0,0);
                     }
                     else {
                       setOpenWrongNetworkCallback(true);
                     }
                   }}><span>Withdraw</span></div>
            </>
          }
        </div>
        <div className="footer-box">
          <div className="socio">
            {/*<a href="#" className="btn-footer">*/}
            {/*  <img src={discord} alt="" />*/}
            {/*</a>*/}
            <a href="https://twitter.com/gamerfi_pro" target="_blank" className="btn-footer">
              <img src={twitter} alt="" />
            </a>
          </div>
          <div className="footer-link">
            <a className="btn-footer" onClick={() => setOpenHowToPlayCallback(true)}>How to Play</a>
            <a className="btn-footer" onClick={() => setOpenProvablyFairCallback(true)}>Provably fair</a>
            <a className="btn-footer" onClick={() => setOpenHowToVerifyCallback(true)}>How to verify</a>
            <a className="btn-footer" onClick={() => setOpenFaqCallback(true)}>FAQ</a>
          </div>

          <div className="btn-vl btn-pink"
               onClick={() => {
                 pushSound.play().then();
                 if (!isAuthorized) connect();
                 else logOut();
               }}>
            {!isAuthorized ? <span>Connect</span> : <span>Logout</span>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;