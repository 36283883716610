import { IAccountData } from "../models/account";
import { formatEther } from "../utils/formatEther.util";
import {
  bnbId,
  bnbStr, bnbTestId,
  bnbTestStr,
  ethereumId,
  ethereumSrt, mumbaiId,
  mumbaiStr, polygonId,
  polygonStr, sepoliaId,
  sepoliaStr
} from "../settings/common.settings";
import { roundUp } from "../utils/helpers";
import { IProfileData } from "../models";

export interface IGetBalance {
  balance: number;
  balanceWei: number
  approximateUsdCents: number;
  symbol: string
}

export function getBalance(profile: IProfileData, networkId: number):IGetBalance {
  let profileBalance = profile.balances ? profile.balances[networkId.toString()] : null;
  if (!profileBalance) {
    return {
      balance: 0,
      balanceWei: 0,
      approximateUsdCents: 0,
      symbol: getNetworkSymbol(networkId)
    }
  }

  return {
      balance: formatEther(profileBalance?.wei ? profileBalance.wei.toString() : ""),
      balanceWei: profileBalance?.wei ? profileBalance.wei : 0,
      approximateUsdCents: profileBalance?.usdCents ? roundUp(profileBalance.usdCents / 100) : 0,
      symbol: getNetworkSymbol(networkId)
  }

  // if (networkId === ethereumId && profile.balances
  //   && typeof profile.balances[ethereumSrt] !== undefined) {
  //   return {
  //     balance: formatEther(profile.balances[ethereumSrt]?.wei ? profile.balances[ethereumSrt].wei.toString() : ""),
  //     balanceWei: profile.balances[ethereumSrt]?.wei ? profile.balances[ethereumSrt].wei : 0,
  //     approximateUsdCents: profile.balances[ethereumSrt]?.usdCents ? roundUp(profile.balances[ethereumSrt].usdCents / 100) : 0,
  //     symbol: "ETH"
  //   };
  // }
  // if (networkId === bnbId && profile.balances
  //   && typeof profile.balances[bnbStr] !== undefined) {
  //   return {
  //     balance: formatEther(profile.balances[bnbStr]?.wei ? profile.balances[bnbStr].wei.toString() : ""),
  //     balanceWei: profile.balances[bnbStr]?.wei ? profile.balances[bnbStr].wei : 0,
  //     approximateUsdCents: profile.balances[bnbStr]?.usdCents ? roundUp(profile.balances[bnbStr].usdCents / 100) : 0,
  //     symbol: "BNB"
  //   };
  // }
  // if (networkId === polygonId && profile.balances
  //   && typeof profile.balances[polygonStr] !== undefined) {
  //   return {
  //     balance: formatEther(profile.balances[polygonStr]?.wei ? profile.balances[polygonStr].wei.toString() : ""),
  //     balanceWei: profile.balances[polygonStr]?.wei ? profile.balances[polygonStr].wei : 0,
  //     approximateUsdCents: profile.balances[polygonStr]?.usdCents ? roundUp(profile.balances[polygonStr].usdCents / 100) : 0,
  //     symbol: "MATIC"
  //   };
  // }
  //
  // if (networkId === sepoliaId && profile.balances
  //   && typeof profile.balances[sepoliaStr] !== undefined) {
  //   return {
  //     balance: formatEther(profile.balances[sepoliaStr]?.wei ? profile.balances[sepoliaStr].wei.toString() : ""),
  //     balanceWei: profile.balances[sepoliaStr]?.wei ? profile.balances[sepoliaStr].wei : 0,
  //     approximateUsdCents: profile.balances[sepoliaStr]?.usdCents ? roundUp(profile.balances[sepoliaStr].usdCents / 100) : 0,
  //     symbol: "Sepolia"
  //   };
  // }
  // if (networkId === bnbTestId && profile.balances
  //   && typeof profile.balances[bnbTestStr] !== undefined) {
  //   return {
  //     balance: formatEther(profile.balances[bnbTestStr]?.wei ? profile.balances[bnbTestStr].wei.toString() : ""),
  //     balanceWei: profile.balances[bnbTestStr]?.wei ? profile.balances[bnbTestStr].wei : 0,
  //     approximateUsdCents: profile.balances[bnbTestStr]?.usdCents ? roundUp(profile.balances[bnbTestStr].usdCents / 100) : 0,
  //     symbol: "TBNB"
  //   };
  // }
  // if (networkId === mumbaiId && profile.balances
  //   && typeof profile.balances[mumbaiStr] !== undefined) {
  //   return {
  //     balance: formatEther(profile.balances[mumbaiStr]?.wei ? profile.balances[mumbaiStr].wei.toString() : ""),
  //     balanceWei: profile.balances[mumbaiStr]?.wei ? profile.balances[mumbaiStr].wei : 0,
  //     approximateUsdCents: profile.balances[mumbaiStr]?.usdCents ? roundUp(profile.balances[mumbaiStr].usdCents / 100) : 0,
  //     symbol: "MATIC"
  //   };
  // }
  //
  // return {
  //   balance: 0,
  //   balanceWei: 0,
  //   approximateUsdCents: 0,
  //   symbol: "wrong network"
  // }
}

function getNetworkSymbol(networkId: number):string {
  switch (networkId) {
    case ethereumId: return "ETH";
    case bnbId: return "BNB";
    case polygonId: return "MATIC";
    case sepoliaId: return "Sepolia";
    case bnbTestId: return "TBNB";
    case mumbaiId: return "MATIC";
    default: {
      console.warn("Can't find symbol for network " + networkId)
      return "";
    }
  }
}

export function readBalance(data:{ [key: string]: number; }, networkId:number):number {
  try {
    return roundUp(+ data[networkId]);
  }
  catch (error){
    return 0
  }

}