//network.model.ts

export interface INetwork {
  id: number;
  name: string;
  network: string;
}

export class Network implements INetwork {
  public id: number;
  public name: string;
  public network: string;

  constructor(
    id?: number,
    name?: string,
    network?: string
  ) {
    this.id = id || 0;
    this.name = name || "";
    this.network = network || "";
  }
}