//index.ts

import { StorageService } from "../../../services";
import { Configuration } from "../../../swaggerAuth/src";
import { Configuration as ConfigurationFlips } from "../../../swaggerFlips/src";
import { apiSettings } from "../api.settings";

export const getConfiguration = (
  baseUrl?: string,
  withoutAuthHeader: boolean = false,
  auth: boolean              = true
) => {
  if (auth) {
    return new Configuration({
      basePath: baseUrl || apiSettings.baseUrl,
      apiKey: (() => {
        if (!withoutAuthHeader) {
          const auth = StorageService.auth.get();
          if (auth.accessToken) {
            return "Bearer " + auth.accessToken;
          }
        }
      })()
    });
  }
  else {
    return new ConfigurationFlips({
      basePath: baseUrl || apiSettings.baseUrl,
      apiKey: (() => {
        if (!withoutAuthHeader) {
          const auth = StorageService.auth.get();
          if (auth.accessToken) {
            return "Bearer " + auth.accessToken;
          }
        }
      })()
    });
  }
};