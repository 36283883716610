//index.ts

import { AxiosInstance, AxiosResponse } from "axios";
import { extend } from "underscore";
import { StorageService } from "../../../services";
import { Error, ErrorEnum } from "../../../view/react/core/models";
import { identitySettings } from "../../identity/identity.settings";
import { API } from "./api.client";

export const call = async <T>(func: () => Promise<AxiosResponse<T>>): Promise<T> => {
  try {
    const data = await func();

    return processSuccessResponse<T>(data);
  }
  catch (error: any) {
    throw processResponseError(error);
  }
};

export const call2 = async <T>(
  url: string,
  method: RequestMethodsEnum = RequestMethodsEnum.Get,
  params?: {},
  data?: {},
  headers?: {},
  apiClient?: AxiosInstance
): Promise<T> => {
  try {
    const response: AxiosResponse = await (apiClient || API.axiosApiClient).request({
                                                                                      url,
                                                                                      method,
                                                                                      params,
                                                                                      data,
                                                                                      headers: (() => {
                                                                                        const defaultHeaders: any = {};

                                                                                        return extend(
                                                                                          (() => {
                                                                                            const headers: any = {};

                                                                                            const auth = StorageService.auth.get();
                                                                                            if (auth.accessToken) {
                                                                                              headers[identitySettings.headers.authorization.name] = `${identitySettings.bearerPrefix}${auth.accessToken}`;
                                                                                            }

                                                                                            return headers;
                                                                                          })(),
                                                                                          defaultHeaders,
                                                                                          headers
                                                                                        );
                                                                                      })()
                                                                                    });

    return processSuccessResponse(response);
  }
  catch (error: any) {
    throw processResponseError(error);
  }
};

const processSuccessResponse = <T>(response: AxiosResponse<T>): T => {
  if (!(response && response.data)) {
    throw processResponseError();
  }

  return response.data;
};

const processResponseError = (error?: any): Error => {
  if (!(error instanceof Error)) {
    error = new Error(
      ErrorEnum.API,
      error?.response?.data?.error || error?.response?.data?.message || error?.response?.statusText || error?.message,
      (() => {
        let innerMessage;

        try {
          innerMessage = JSON.stringify(error);
        }
        catch (error: any) {

        }

        if (!innerMessage && error?.request?.responseURL) {
          return `${error?.request?.responseURL} error`;
        }

        return innerMessage || "API error";
      })(),
      error?.response?.status || error?.code,
      error?.response?.data?.status
    );
  }

  return error;
};

export enum RequestMethodsEnum {
  Get = "get",
  Post = "post",
  Put = "put",
  Delete = "delete"
}