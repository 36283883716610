import React, { FC, useEffect, useRef, useState } from "react";
import { SpinningCoin } from "./SpinningCoin";
import { IBullState } from "../../core/models";

interface IWinFree {
  setFlipCancelLost: () => void;
  setFlipCancelAll: () => void
  bullState: IBullState;
  win: boolean | null;
  pushSound: HTMLAudioElement
  winAmount: number
  setOpenChoiceAfterFreePlayModalCallback: (param: boolean) => void;
}

const LostFree: FC<IWinFree> = ({
  setFlipCancelLost,
  setFlipCancelAll,
  bullState,
  win,
  pushSound,
  winAmount,
  setOpenChoiceAfterFreePlayModalCallback
}) => {
  const refMainBox = useRef<HTMLDivElement>(null);
  const [spin, setSpin] = useState<boolean | undefined>(undefined);


  useEffect(()=> {
    if (win === false)
      setSpin(false)
    setTimeout(()=>setSpin(undefined),300)
  },[win])

  return (
    <div className={`b-1-box lose${win === false ? " active" : ""}`} id="screen-4" ref={refMainBox}>
      <div className="title">
        <div className="text-red mb-15">YOU LOST!</div>
        <div className="text text-red">-${winAmount}</div>
      </div>
      <div className="coints">
        <SpinningCoin bullState={bullState} endSpin={spin}/>
      </div>
      <div className="controll">
        <div className="earnings mb-30">Turn your luck around and play for<br />real money</div>
        <div className="lose-btn-1">
          <a className="btn-vl btn-flip btn-yellow screen-open"
               onClick={()=> {
                 pushSound.play().then();
                 setFlipCancelLost()
               }}
               data-id="screen-1"><span>New free game</span></a>
        </div>
        <div className="lose-btn-2">
          <a className="btn-vl btn-flip btn-blue screen-open"
               onClick={() => {
                 pushSound.play().then();
                 setOpenChoiceAfterFreePlayModalCallback(true);
                 setFlipCancelAll();
               }}
               data-id="screen-1"><span>Exit free flip</span></a>
        </div>
      </div>
    </div>
  );
};
export default LostFree;