//index.ts

import * as StorageService from "./storage";
import { Xhr } from "./xhr";
import { checkNetwork } from "./checkNetwork"
import { getBalance, readBalance } from "./getBalance"
import { calculationAmount } from "./calculationAmount"

const XhrService = Xhr.instance;

export {
  StorageService,
  XhrService,
  checkNetwork,
  getBalance,
  readBalance,
  calculationAmount
};