//refresh_token.ts


import {IAuthData} from "../models";
import {StorageService} from "../services";
import {IXhrInstance} from "../services/xhr/instance";
import {IXhrRequest} from "../services/xhr/xhrRequest.model";
//import { navigation } from "../utils/helpers";
import {Error, ErrorEnum} from "../view/react/core/models";
import {LoginAPI} from "./api/login.api";
import {IdentityService} from "./identity";
import {store} from "../stores/store";

const shouldIntercept = (status?: number) => {
  try {
    return status === 401;
  }
  catch (e) {
    return false;
  }
};

const setAuthData = (
  authData: IAuthData
) => {
  StorageService.auth.set(authData);
};

const handleTokenRefresh = (): Promise<IAuthData> => {
  return new Promise((
                       resolve,
                       reject
                     ) => {
    const data = store.getState().account.data;
    if (data == null) {
      reject(new Error(ErrorEnum.RefreshToken, "Account data was null in the store"));
      return;
    }
    LoginAPI.refreshToken(data.walletData.address).then((data) => {
      resolve(data);
    }).catch((error: any) => {
      reject(error);
    });
  });
};

const attachTokenToRequest = (
  request: any,
  token: string
) => {
  return;
};

export default (
  xhrClient: IXhrInstance,
  customOptions = {}
) => {
  let isRefreshing = false;
  let failedQueue: Array<any> = [];

  const options = {
    attachTokenToRequest,
    handleTokenRefresh,
    setAuthData,
    shouldIntercept,
    ...customOptions
  };

  const processQueue = (
    error: any,
    token?: string
  ) => {
    failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  const interceptor = (request: IXhrRequest): Promise<Error | unknown> => {
    if (!options.shouldIntercept(request.error.code ?
                                   +request.error.code :
                                   0)) {
      return Promise.reject(request.error);
    }

    if (request._retry || request._queued) {
      return Promise.reject(request.error);
    }

    if (isRefreshing) {
      return new Promise<any>(function (
        resolve,
        reject
      ) {
        failedQueue.push({
                           resolve,
                           reject
                         });
      }).then((token?: string) => {
        request._queued = true;
        options.attachTokenToRequest(
          request,
          token || ""
        );
        return xhrClient.request(request);
      }).catch(error => {
        return Promise.reject(error); // Ignore refresh token request's "err" and return actual "error" for the original request
      });
    }

    request._retry = true;
    isRefreshing = true;

    return new Promise((
                         resolve,
                         reject
                       ) => {
      options.handleTokenRefresh.call(options.handleTokenRefresh).then((authData: IAuthData) => {
        options.setAuthData(
          authData
        );
        options.attachTokenToRequest(
          request,
          authData.accessToken || ""
        );
        processQueue(
          null,
          authData.accessToken
        );
        resolve(xhrClient.request(request));
      }).catch((error: any) => {
        if (!request.ignoreLogInJump) {
          //navigation.goToLogin();   //TO DO!!!!!!!!!
        }
        processQueue(
          error,
          undefined
        );
        reject(new Error(
          ErrorEnum.SessionEnded,
          undefined,
          "session ended"
        ));
      }).finally(() => {
        isRefreshing = false;
      });
    });
  };

  xhrClient.interceptors.response.use(
    undefined,
    interceptor
  );
}