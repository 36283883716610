import React, {FC, useEffect, useMemo, useState} from "react";
import logo from "../../template/img/logo.png";
import logoEth from "../../template/img/logo-eth.svg";
import logoBnb from "../../template/img/logo-bnb.svg";
import logoPolygon from "../../template/img/logo-polygon.svg";
import {getBalance} from "../../core/services/getBalance";
import {IStateScreen} from "../../core/models";
import ConnectorBalance, {INewUserData} from "./ConnectorBalance";
import {StorageService} from "../../core/services";
import {useAppDispatch, useAppSelector} from "../../core/stores/store";
import {updateBalance} from "../../core/stores/accountSlice";


interface INavConnected {
    setOpenWrongNetworkCallback: (param: boolean) => void;
    setStateScreenCallback: (param: IStateScreen) => void;
    stateScreen: IStateScreen;
    networkId: number
    isTrueNetwork: boolean
    openDropDown: boolean
    setOpenDropDownCallback: (param: boolean) => void;
}

const NavConnected: FC<INavConnected> = ({
                                             setOpenWrongNetworkCallback,
                                             setStateScreenCallback,
                                             stateScreen,
                                             networkId,
                                             isTrueNetwork,
                                             setOpenDropDownCallback,
                                             openDropDown
                                         }) => {
    const profile = useAppSelector(state => state.account.data.profile);
    const dispatch = useAppDispatch();

    const balance = getBalance(profile, networkId);

    const {events} = ConnectorBalance();

    useEffect(
        () => {
            const handleNewUserData = async (payload: INewUserData) => {
                console.log(payload)
                dispatch(updateBalance(payload));
            }
            events(handleNewUserData);
        },
        []
    );

    return (
        <div className={`logo${openDropDown ? " show" : ""}`} onClick={() => setOpenDropDownCallback(!openDropDown)}>
            <a href="/" className="logo-img">
                <img src={logo} alt=""/>
            </a>

            <div className="chose">
                {(stateScreen === IStateScreen.PlayInitial) && <div className="chose-text">Choose game mode</div>}
                {stateScreen === IStateScreen.PlayFree && <div className="chose-text">Free flip</div>}
                {(stateScreen === IStateScreen.Play || stateScreen === IStateScreen.Deposit || stateScreen === IStateScreen.Withdraw) && isTrueNetwork &&
                    <div className="chose-text">GamerFi balance
                        <div className="balance">
                            <div className="balance-img">
                                <img src={getChainLogo(balance.symbol)} alt=""/>
                            </div>
                            <div>{balance.balance} {balance.symbol}
                                ≈ <span>${(+balance.approximateUsdCents + +(profile.bonusCents ? profile.bonusCents / 100 : 0).toFixed(2)).toFixed(2)}</span>
                            </div>
                        </div>
                    </div>}
                {(stateScreen === IStateScreen.Play || stateScreen === IStateScreen.Deposit || stateScreen === IStateScreen.Withdraw) && !isTrueNetwork &&
                    <div className="chose-text">GamerFi balance
                        <div className="balance error">
                            wrong network
                        </div>
                    </div>
                }


                {stateScreen === IStateScreen.PlayToWallet && isTrueNetwork &&
                    <div className="chose-text">Flip from wallet
                        <div className="balance">
                            {profile.address?.slice(
                                0,
                                6
                            )}...{profile.address?.slice(-4)}
                        </div>
                    </div>}
                {stateScreen === IStateScreen.PlayToWallet && !isTrueNetwork &&
                    <div className="chose-text">Flip from wallet
                        <div className="balance error">
                            wrong network
                        </div>
                    </div>
                }


                <div className="chose-menu">
                    <a onClick={() => {
                        setStateScreenCallback(IStateScreen.PlayFree);
                        StorageService.mode.set(IStateScreen.PlayFree);
                        setOpenDropDownCallback(!openDropDown)
                    }}
                       className={stateScreen === IStateScreen.PlayFree ? "active" : ""}>Free flip</a>

                    {isTrueNetwork &&
                        <a onClick={() => {
                            setStateScreenCallback(IStateScreen.Play);
                            StorageService.mode.set(IStateScreen.Play);
                            setOpenDropDownCallback(!openDropDown)
                        }}
                           className={(stateScreen === IStateScreen.Play || stateScreen === IStateScreen.Deposit || stateScreen === IStateScreen.Withdraw) ? "active" : ""}>
                            GamerFi balance
                            <div className="balance">
                                <div className="balance-img">
                                    <img src={getChainLogo(balance.symbol)}
                                         alt=""/>
                                </div>
                                <div>{balance.balance} {balance.symbol}
                                    ≈ <span>${(+balance.approximateUsdCents + +(profile.bonusCents ? profile.bonusCents / 100 : 0).toFixed(2)).toFixed(2)}</span>
                                </div>
                            </div>
                        </a>
                    }
                    {!isTrueNetwork &&
                        <a onClick={() => setOpenWrongNetworkCallback(true)}
                           className={(stateScreen === IStateScreen.Play || stateScreen === IStateScreen.Deposit || stateScreen === IStateScreen.Withdraw) ? "active" : ""}>
                            GamerFi balance
                            <div className="balance error">
                                wrong network
                            </div>
                        </a>
                    }

                    {isTrueNetwork &&
                        <a onClick={() => {
                            setStateScreenCallback(IStateScreen.PlayToWallet);
                            StorageService.mode.set(IStateScreen.PlayToWallet);
                            setOpenDropDownCallback(!openDropDown)
                        }
                        }
                           className={stateScreen === IStateScreen.PlayToWallet ? "active" : ""}>
                            Flip from wallet
                            <div className="balance">
                                {profile.address?.slice(
                                    0,
                                    6
                                )}...{profile.address?.slice(-4)}
                            </div>
                        </a>
                    }
                    {!isTrueNetwork &&
                        <a onClick={() => setOpenWrongNetworkCallback(true)}
                           className={stateScreen === IStateScreen.PlayToWallet ? "active" : ""}>
                            Flip from wallet
                            <div className="balance error">
                                wrong network
                            </div>
                        </a>
                    }
                </div>
            </div>
        </div>
    );
};

function getChainLogo(symbol: string) {
    switch (symbol) {
        case "ETH":
            return logoEth
        case "BNB":
            return logoBnb
        case "MATIC":
            return logoPolygon
        case "Sepolia":
            return logoEth
        case "TBNB":
            return logoBnb
        default:
            return logoPolygon
    }
}

export default NavConnected;