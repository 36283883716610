import React, { FC, useEffect, useRef, useState } from "react";
import { Field, Form, Formik, useFormikContext } from "formik";
import CompleteModal from "../modal/CompleteModal";
import { DepositApi } from "../../core/xhr/api/deposit.api";
import { IAccountData } from "../../core/models/account";
import { checkNetwork } from "../../core/services";
import { WithdrawApi } from "../../core/xhr/api/withdraw.api";
import { ExchangeRateApi } from "../../core/xhr/api/exchangeRate.api";
import { getBalance, IGetBalance, readBalance } from "../../core/services/getBalance";
import { isNumber } from "../../core/utils/isNumber";
import { IStateScreen } from "../../core/models";
import { IDepositariumContracts } from "../../core/models/game/gameModels.model";
import {useAppSelector} from "../../core/stores/store";

interface IDepositWithdraw {
  setStateScreenCallback: (param: IStateScreen) => void;
  setOpenProcessingCallback: (param: boolean) => void;
  setOpenWrongNetworkCallback: (param: boolean) => void;
  setOpenErrorCallback: (param: boolean) => void;
  setMessageCallback: (param: string) => void
  stateScreen: IStateScreen;
  // profile: IAccountData;
  pushSound: HTMLAudioElement
}

const DepositWithdraw: FC<IDepositWithdraw> = ({
  setStateScreenCallback,
  setOpenProcessingCallback,
  setOpenWrongNetworkCallback,
  setOpenErrorCallback,
  setMessageCallback,
  stateScreen,
  // profile,
  pushSound
}) => {

  const [openComplete, setOpenComplete] = useState(false);
  const [amount, setAmount] = useState(0);
  const [exchangeRateUsdInToken, setExchangeRateUsdInToken] = useState<number>(0);
  const [balance, setBalance] = useState<IGetBalance>({balance: 0, balanceWei: 0, approximateUsdCents: 0, symbol: ""});
  const [transactionHash, setTransactionHash] = useState("");
  const refButton = useRef<HTMLButtonElement>(null);

  const setOpenCompleteCallback = (param: boolean) => setOpenComplete(param);

  const settings = useAppSelector(state => state.globalSettings.settings);
  const contracts = useAppSelector(state => state.globalSettings.contracts);

  const profile = useAppSelector(state => state.account.data);

  useEffect(() => {
      (async () => {
        setBalance(getBalance(profile.profile, profile.walletData.network.id));
        if (profile.isAuthorized && profile.isTrueNetwork) {
          const exchangeRateArray = await ExchangeRateApi.ExchangeRatesUsdInToken(profile.walletData.network.id);
          const exchangeRate = readBalance(
            exchangeRateArray,
            profile.walletData.network.id
          );
          setExchangeRateUsdInToken(exchangeRate);
        }
      })();
    },
    [profile]
  );

  useEffect(()=> {
    if (stateScreen === IStateScreen.Withdraw || stateScreen === IStateScreen.Deposit) {
      refButton.current?.click()
    }

  },[stateScreen])
  const depositFunc = async (values: any) => {
    if (checkNetwork(profile.walletData.network.id, settings)) {
      setOpenProcessingCallback(true);
      if (stateScreen === IStateScreen.Deposit) {
        try {
          const data = await DepositApi.setDeposit(
            profile.walletData.address,
            profile.walletData.network.id,
            +values.amount.replace(/,/,'.').replace('$', ''),
            contracts
          );
          if (data === "NoMoney") {
            setOpenProcessingCallback(false);
            setMessageCallback("You don't have enough money in your decentralized wallet.");
            setOpenErrorCallback(true);

          }
          else {
            setTransactionHash(data);
            setOpenProcessingCallback(false);
            setAmount(values.amount.replace(/,/,'.').replace('$', ''));
            setOpenComplete(true);
          }
        }
        catch (error: any) {
          setOpenProcessingCallback(false);
          setMessageCallback("Something went wrong");
          setOpenErrorCallback(true);
        }
      }
      else {
        try {
          const exchangeRateWeiInUsdArray = await ExchangeRateApi.ExchangeRatesWeiInUsd(profile.walletData.network.id);
          const exchangeRateWeiInUsd = readBalance(
            exchangeRateWeiInUsdArray,
            profile.walletData.network.id
          );
          const tr = await WithdrawApi.setWithdraw(
            +values.amount.replace(/,/,'.').replace('$', '') === balance.approximateUsdCents ?
              (balance.balanceWei) :
              (+values.amount.replace(/,/,'.').replace('$', '')) * exchangeRateWeiInUsd,
            profile.walletData.network.id,
            contracts
          );
            setTransactionHash(tr);
            setOpenProcessingCallback(false);
            setAmount(values.amount.replace(/,/, ".").replace('$', ''));
            setOpenComplete(true);
        }
        catch (error: any) {
          setOpenProcessingCallback(false);
          setMessageCallback("Something went wrong");
          setOpenErrorCallback(true);
        }
      }
    }
    else {
      setOpenWrongNetworkCallback(true);
    }
  };

  function validateAmount(value:string){
    const val = value.replace('$', '');
    if (!isNumber(+val))
      return "Numbers only"

    if (+val <= 0 )
      return "Number should be more than zero"

    if (stateScreen === IStateScreen.Withdraw && +val.replace(/,/,'.') > balance.approximateUsdCents)
      return "You can't withdraw more than you have on GamerFi balance"

    if (stateScreen === IStateScreen.Deposit &&
      (+val.replace(/,/,'.') > 10000000 ))
      return "Number is out of allowed range"

    if (val.length > 9 || (+val > 1 && val.indexOf("0") === 0) || (+val < 1 && val.indexOf("00") === 0)) {
      return "Number is out of allowed range";
    }
  }

  return (
    <>
      <div className="b-1">
        <div className="b-1-box money-box active">
          <div className="money">
            <div className="h-1 mb-30">Enter sum</div>
            <div className="text earnings mb-15">This amount will
              be {stateScreen === IStateScreen.Deposit ? "deposited" : "withdrawn"}</div>
            <div className="text earnings mb-30">{stateScreen === IStateScreen.Deposit ? "to your GamerFi wallet" : "from your GamerFi wallet"}</div>
            {profile.isTrueNetwork ?
              <div className="text earnings">1 [{balance.symbol}] = ${exchangeRateUsdInToken}</div>
              :
              <div className="text earnings">wrong network</div>
            }
            <Formik
              initialValues={{ amount: "" }}
              onSubmit={(values) => depositFunc(values)}
            >{({setFieldValue, errors, handleReset}) => (
              <Form>
                <div className="form mt-60">
                  {errors.amount && <div className="error-text">{errors.amount}</div>}
                  <Field name="amount" type="text" placeholder="$" validate={validateAmount}
                         autoComplete="off"
                         onChange={(e:any)=>{
                         const newValue = e.target.value.replace('$', '')
                           setFieldValue("amount", "$" + newValue)}
                         }/>
                </div>
                {stateScreen === IStateScreen.Withdraw &&
                  <div className="mt-30">
                    <a onClick={()=> setFieldValue("amount", "$" + balance.approximateUsdCents.toString())} className="link-red"><span>Max</span></a>
                  </div>
                }
                <div className="mt-50">
                  <button type="submit" className="btn-vl btn-flip btn-blue modal-open"
                    onClick={()=> setTimeout(() => pushSound.play().then(), 0)}>
                    <span>{stateScreen === IStateScreen.Deposit ? "Deposit" : "Withdraw"}</span>
                  </button>
                </div>
                <div className="mt-50">
                  <div className="btn-vl btn-flip screen-open" data-id="screen-2"
                       onClick={(e) => {
                         setTimeout(() => pushSound.play().then(), 0);
                         setStateScreenCallback(IStateScreen.Play)}}
                         >
                    <span>Close</span>
                  </div>
                </div>
                <button style={{ display: "none" }} ref={refButton}
                        onClick={handleReset}></button>
              </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <CompleteModal openComplete={openComplete} setOpenCompleteCallback={setOpenCompleteCallback}
                     stateScreen={stateScreen} amount={amount} setStateScreenCallback={setStateScreenCallback}
                     transactionHash={transactionHash} networkId={profile.walletData.network.id}/>
    </>
  );
};
export default DepositWithdraw;