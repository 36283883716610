//langStorage.service.ts

import { set as storageSet, storagePrefix, StorageTypesEnum } from "./storage.service";

const key = `${storagePrefix}lang`,
  storageType = StorageTypesEnum.Cookie;

export const set = (value: string) => {
  storageSet(
    key,
    value,
    storageType
  );
};