import React, { FC } from "react";
import close from "../../template/img/close.svg";
import { IStateScreen } from "../../core/models";
import { StorageService } from "../../core/services";

interface IInsufficientFundsModal {
  openInsufficientFundsModal: boolean;
  setOpenInsufficientFundsModalCallback: (param: boolean) => void;
  setStateScreenCallback: (param: IStateScreen) => void;
  pushSound: HTMLAudioElement
}

const InsufficientFundsModal: FC<IInsufficientFundsModal> = ({
  openInsufficientFundsModal,
  setOpenInsufficientFundsModalCallback,
  setStateScreenCallback,
  pushSound
}) => {

  return (
    <div className={`modal-bg${openInsufficientFundsModal ? " show" : ""}`}>
      <div className={`modal${openInsufficientFundsModal ? " show" : ""}`} id="modal-6">
        <a className="close modal-close" onClick={() => setOpenInsufficientFundsModalCallback(false)}>
          <img src={close} alt="" />
        </a>
        <div className="content">
          <div className="processing error">
            <div>
              <div className="title">Insufficient funds</div>
              <div className="text mt-30">You don't have enough money in your GamerFi wallet.</div>
              <div className="text">Please deposit to play, flip from wallet, or flip for free.</div>
              <div className="mt-60">
                <a onClick={() => {
                  pushSound.play().then();
                  setOpenInsufficientFundsModalCallback(false);
                  setStateScreenCallback(IStateScreen.Deposit)
                }}
                   className="btn-vl btn-blue btn-lg mb-30">
                  <span>Deposit</span>
                </a>
                <a onClick={() => {
                  pushSound.play().then();
                  setOpenInsufficientFundsModalCallback(false);
                  setStateScreenCallback(IStateScreen.PlayToWallet)
                  StorageService.mode.set(IStateScreen.PlayToWallet);
                }}
                   className="btn-vl btn-yellow btn-lg mb-30">
                  <span>Flip from wallet</span>
                </a>
                <a onClick={() => {
                  pushSound.play().then();
                  setOpenInsufficientFundsModalCallback(false);
                  setStateScreenCallback(IStateScreen.PlayFree);
                  StorageService.mode.set(IStateScreen.PlayFree);
                }}
                   className="btn-vl btn-pink btn-lg mb-30">
                  <span>flip for free</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InsufficientFundsModal;