import React, { FC } from "react";
import logo from "../../template/img/logo.png"

interface ILoading {
  loading: boolean
}
const Loading:FC<ILoading> = ({loading})=> {
  return (
  <div className={`loading${loading? " show": ""}`}>
    <div className="b-0">
      <div className="b-0-box">
        <div className="title">
          <div>Loading</div>
        </div>
      </div>
      <div className="b-0-box">
        <div className="footer">
          <div className="container">
            <div className="footer-logo-0">
              <img src={logo} alt="" /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
    )
}

export default Loading