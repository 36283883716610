import React, { FC } from "react";
import close from "../../template/img/close.svg";
import { getBalance } from "../../core/services/getBalance";
import { IAccountData } from "../../core/models/account";
import { IStateScreen } from "../../core/models";
import { StorageService } from "../../core/services";
import {useAppSelector} from "../../core/stores/store";

interface IChoiceAfterFreePlayModal {
  openChoiceAfterFreePlayModal: boolean;
  // profile: IAccountData;
  pushSound: HTMLAudioElement
  setNoAuthChoiceModCallback: (param: IStateScreen) => void;
  setOpenChoiceAfterFreePlayModalCallback: (param: boolean) => void;
  setOpenConnectWalletCallback: (param: boolean) => void;
  setStateScreenCallback: (param: IStateScreen) => void;
}

const ChoiceAfterFreePlayModal: FC<IChoiceAfterFreePlayModal> = ({
  openChoiceAfterFreePlayModal,
  // profile,
  pushSound,
  setNoAuthChoiceModCallback,
  setOpenChoiceAfterFreePlayModalCallback,
  setOpenConnectWalletCallback,
  setStateScreenCallback
}) => {
  const profile = useAppSelector(state => state.account.data);

  return (
    <div className={`modal-bg${openChoiceAfterFreePlayModal ? " show" : ""}`}>
      <div className={`modal${openChoiceAfterFreePlayModal ? " show" : ""}`} id="modal-6">
        <a className="close modal-close" onClick={() => setOpenChoiceAfterFreePlayModalCallback(false)}>
          <img src={close} alt="" />
        </a>
        <div className="content">
          <div className="content">
            <div className="rw mt-60 mb-60">
              <div className="c-m-6">
                <div className="fp-box">
                  <div className="title mb-30">Gamerfi balance</div>
                  <div className="text mb-30">Deposit into your GamerFi balance, and all flip gas fees are on us.</div>
                  <div className="d-center mt-60">
                    <a href="#" className="btn-vl btn-blue modal-close screen-open"
                       data-id="screen-1"
                       onClick={() => {
                         pushSound.play().then();
                         if (profile.isAuthorized) {
                           if (getBalance(profile.profile, profile.walletData.network.id).balance > 0) {
                             setStateScreenCallback(IStateScreen.Play);
                             StorageService.mode.set(IStateScreen.Play);
                           }
                           else {
                             setStateScreenCallback(IStateScreen.Deposit);
                           }
                           setOpenChoiceAfterFreePlayModalCallback(false);
                         }
                         else {
                           setOpenChoiceAfterFreePlayModalCallback(false);
                           setNoAuthChoiceModCallback(IStateScreen.Play);
                           setOpenConnectWalletCallback(true);
                         }
                       }}
                    >
                      <span>
                        {!profile.isAuthorized ? "Connect" : getBalance(profile.profile, profile.walletData.network.id).balance > 0 ? "Gamerfi balance" : "Deposit"}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="c-m-6">
                <div className="fp-box">
                  <div className="title mb-30">Flip from wallet</div>
                  <div className="text mb-15">Flip directly from your wallet.</div>
                  <div className="text mb-30 note">Note: you will have to pay the gas fees</div>
                  <div className="d-center mt-50">
                    <a className="btn-vl btn-yellow" onClick={() => {
                      pushSound.play().then();
                      if (profile.isAuthorized) {
                        setStateScreenCallback(IStateScreen.PlayToWallet);
                        StorageService.mode.set(IStateScreen.PlayToWallet);
                        setOpenChoiceAfterFreePlayModalCallback(false);
                      }
                      else {
                        setOpenChoiceAfterFreePlayModalCallback(false);
                        setNoAuthChoiceModCallback(IStateScreen.PlayToWallet);
                        setOpenConnectWalletCallback(true);
                        }
                    }}><span>{profile.isAuthorized? "Flip from wallet": "Connect"}</span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChoiceAfterFreePlayModal;