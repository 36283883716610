import React, { FC } from "react";
import close from "../../template/img/close.svg";
import { ContractMethods } from "../../core/xhr/api/contractMethods";
import { IStateScreen } from "../../core/models";
import { StorageService } from "../../core/services";

interface ICompleteModal {
  openComplete: boolean;
  setOpenCompleteCallback: (param: boolean) => void;
  setStateScreenCallback: (param: IStateScreen) => void;
  stateScreen: IStateScreen;
  amount: number;
  transactionHash: string;
  networkId: number;
}

const CompleteModal: FC<ICompleteModal> = ({
  openComplete,
  setOpenCompleteCallback,
  setStateScreenCallback,
  stateScreen,
  amount,
  transactionHash,
  networkId
}) => {

  return (
    <div className={`modal-bg${openComplete ? " show" : ""}`}>
      <div className={`modal processing-complete${openComplete ? " show" : ""}`} id="modal-5">
        <a className="close modal-close" onClick={() => setOpenCompleteCallback(false)}>
          <img src={close} alt="" />
        </a>
        <div className="content">
          <div className="processing">
            <div>
              <div className="title">complete</div>
              <div className="text">${amount} will be updated in
                your {stateScreen === IStateScreen.Deposit ? "GamerFi" : "decentralized"} wallet shortly
              </div>
              <div className="z-index">
                <div className="mt-60">
                  <a href={ContractMethods.chooseTx(networkId) + transactionHash} target="_blank"
                     className="btn-vl btn-yellow btn-lg mx-auto"><span>Track transaction</span></a>
                </div>
                <div className="mt-30">
                  <a className="btn-vl btn-pink btn-lg mx-auto" onClick={() => {
                    setOpenCompleteCallback(false);
                    setStateScreenCallback(IStateScreen.Play);
                    StorageService.mode.set(IStateScreen.Play);
                  }}>
                    <span>Play now</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CompleteModal;