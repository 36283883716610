import React, { FC } from "react";
import close from "../../template/img/close.svg";

interface IWrongNetworkModal {
  openWrongNetwork: boolean;
  setOpenWrongNetworkCallback: (param: boolean) => void;
  pushSound: HTMLAudioElement
}

const WrongNetworkModal: FC<IWrongNetworkModal> = ({ openWrongNetwork , setOpenWrongNetworkCallback, pushSound}) => {

  return (
    <div className={`modal-bg${openWrongNetwork ? " show" : ""}`}>
      <div className={`modal${openWrongNetwork ? " show" : ""}`} id="modal-6">
        <a className="close modal-close" onClick={() => setOpenWrongNetworkCallback(false)}>
          <img src={close} alt="" />
        </a>
        <div className="content">
          <div className="processing error">
            <div>
              <div className="title mb-30">Wrong network</div>
              <div className="text">GamerFi does not support the network you’re trying to flip on.</div>
              <div className="text">Please switch to Ethereum, Polygon or Binance Smart Chain.</div>
              <div className="text mt-15">
                <a href="https://metaschool.so/articles/how-to-change-add-new-network-metamask-wallet/#:~:text=Change%20the%20MetaMask%20network,list%20of%20different%20available%20networks"
                   className="text-red text-decoration-underline" target="_blank">
                  How to switch networks
                </a>
              </div>
              <div className="mt-60">
                <a className="btn-vl btn-yellow btn-lg mb-30"
                   onClick={() => {
                     pushSound.play().then();
                     setOpenWrongNetworkCallback(false);
                   }}>
                  <span>Try again</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WrongNetworkModal;