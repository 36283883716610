//formatEther.util.ts

import { priceDigits } from "../settings/common.settings";
import { stringToNumber } from "./stringToNumber.util";

export const formatEther = (wei: string): number => {
  try {
    return +(stringToNumber(window.WalletEnvLib.utils.formatEther(wei)) || 0).toFixed(priceDigits);
  }
  catch {
    return 0;
  }
};