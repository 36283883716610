//langStorage.service.ts

import {
  get as storageGet,
  remove as storageRemove,
  set as storageSet,
  storagePrefix,
  StorageTypesEnum
} from "./storage.service";
import { AuthData, IAuthData, IStateScreen } from "../../models";
import { isObject } from "../../utils";

const key = `${storagePrefix}mode`,
      storageType = StorageTypesEnum.Local;

export const get = (): IStateScreen => {
  const data = storageGet(
    key,
    storageType
  );

  if (data) {
    return data
  }

  return IStateScreen.PlayInitial;
};

export const set = (value: string) => {
  storageSet(
    key,
    value,
    storageType
  );
};

export const remove = () => {
  storageRemove(
    key,
    storageType
  );
};