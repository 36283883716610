//profileData.model.ts


import { ChainBalance, IChainBalance } from "./chainBalance.model";

export interface IProfileData {
  id: string;
  address: string | null;
  balances: { [key: string]: IChainBalance } | null
  bonusCents: number | null
  isBlocked: boolean | null
}

export class ProfileData implements IProfileData {
  public id: string;
  public address: string | null;
  public balances: { [key: string]: IChainBalance } | null
  public bonusCents : number | null
  public isBlocked: boolean

  constructor(
    id?: string,
    address?: string | null,
    balances?: { [key: string]: IChainBalance } | null,
    bonusCents?: number | null,
    isBlocked?: boolean | null
  ) {
    this.id = id || "";
    this.address = address? address : "";
    this.balances = balances !== undefined ? balances : {"0": new ChainBalance()};
    this.bonusCents = bonusCents? bonusCents : 0;
    this.isBlocked = isBlocked || false;
  }
}