//accountData.model.ts


import { IProfileData, ProfileData } from "../profile";
import { IWalletData, WalletData } from "../wallet";
import { IModalWindows, ModalWindows } from "../modalWindows/modalWindows.model";

export interface IAccountData {
  profile: IProfileData;
  walletData: IWalletData;
  isAuthorized: boolean;
  isTrueNetwork: boolean,
  modalWindows: IModalWindows,
  testTest: boolean
}

export class AccountData implements IAccountData {
  public profile: IProfileData;
  public walletData: IWalletData;
  public isAuthorized: boolean;
  public isTrueNetwork: boolean;
  public modalWindows: IModalWindows
  public testTest: boolean

  constructor(
    isAuthorized: boolean,
    isTrueNetwork: boolean,
    profile?: IProfileData,
    walletData?: IWalletData,
    modalWindows?: IModalWindows,
    testTest?: boolean


  ) {
    this.isAuthorized = isAuthorized;
    this.isTrueNetwork = isTrueNetwork;
    this.profile = profile || new ProfileData();
    this.walletData = walletData || new WalletData();
    this.modalWindows = modalWindows || new ModalWindows();
    this.testTest = testTest || false
  }
}