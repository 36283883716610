import React, { FC } from "react";


interface IProcessingModal {
  openProcessing: boolean;
}

const ProcessingModal: FC<IProcessingModal> = ({ openProcessing }) => {

  return (
    <div className={`modal-bg${openProcessing ? " show" : ""}`}>
      <div className={`modal${openProcessing ? " show" : ""}`} id="modal-4">
        <div className="content">
          <div className="processing">
            <div>
            <div className="title">processing...</div>
            <div className="earnings .mt-15 .mt-30">Please wait</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProcessingModal;