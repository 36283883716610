//balance.model.ts

export interface IBalance {
  value: string;
  decimals: number;
  symbol: string;
}

export class Balance implements IBalance {
  public value: string;
  public decimals: number;
  public symbol: string;

  constructor(
    value?: string,
    decimals?: number,
    symbol?: string
  ) {
    this.value = value || "";
    this.decimals = decimals || 0;
    this.symbol = symbol || "";
  }
}