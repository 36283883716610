import React, { FC } from "react";
import close from "../../template/img/close.svg";

interface IErrorModal {
  openError: boolean;
  message:string
  setOpenErrorCallback: (param: boolean) => void;
}

const ErrorModal: FC<IErrorModal> = ({ openError , setOpenErrorCallback, message}) => {

  return (
    <div className={`modal-bg${openError ? " show" : ""}`}>
      <div className={`modal${openError ? " show" : ""}`} id="modal-6">
        <a className="close modal-close" onClick={() => setOpenErrorCallback(false)}>
          <img src={close} alt="" />
        </a>
        <div className="content">
          <div className="processing error">
            <div>
            <div className="title">error</div>
            <div className="text">{message}</div>
            <div className="mt-60">
              {/*<a href="#" className="btn-vl btn-blue btn-flip"><span>View Etherscan</span></a>*/}
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ErrorModal;