//index.ts

import { Error, ErrorEnum } from "../../../view/react/core/models";
import { IXhrRequest, XhrRequest } from "../xhrRequest.model";

export interface IXhrInstance {
  interceptors: {
    response: {
      use: (
        onFulfilled?: (data: any) => void,
        onRejected?: (request: IXhrRequest) => Promise<Error | unknown>
      ) => void
    }
  };

  request<T>(request: IXhrRequest): Promise<T>;

  call<T>(
    func: () => Promise<T>,
    ignoreLogInJump?: boolean
  ): Promise<T>;
}

export class XhrInstance implements IXhrInstance {
  private interceptorResponseOnFulfilled?: (data: any) => void;
  private interceptorResponseOnRejected?: (request: IXhrRequest) => Promise<Error | unknown>;
  public interceptors = {
    response: {
      use: (
        onFulfilled?: (data: any) => void,
        onRejected?: (request: IXhrRequest) => Promise<Error | unknown>
      ): void => {
        if (onFulfilled) {
          this.interceptorResponseOnFulfilled = onFulfilled;
        }

        if (onRejected) {
          this.interceptorResponseOnRejected = onRejected;
        }
      }
    }
  };
  private readonly requestDelay?: number;

  constructor(
    requestDelay?: number
  ) {
    this.requestDelay = requestDelay || 0;
  }

  public request<T>(request: IXhrRequest): Promise<T> {
    return new Promise((
                         resolve,
                         reject
                       ) => {
      setTimeout(
        async () => {
          try {
            resolve(await request.func());
          }
          catch (error: any) {
            setTimeout(
              async () => {
                try {
                  if (this.interceptorResponseOnRejected) {
                    resolve(await this.interceptorResponseOnRejected({
                                                                       ...request,
                                                                       error: error
                                                                     }) as T);
                  } else {
                    throw error;
                  }
                }
                catch (error: any) {
                  reject(error);
                }
              },
              0
            );
          }
        },
        this.requestDelay || 0
      );
    });
  }

  public async call<T>(
    func: () => Promise<T>,
    ignoreLogInJump: boolean = false
  ): Promise<T> {
    try {
      return await this.request<T>(new XhrRequest(
        new Error(ErrorEnum.Empty),
        func,
        undefined,
        undefined,
        ignoreLogInJump
      ));
    }
    catch (error: any) {
      throw error;
    }
  }
}