//authData.model.ts

export interface IAuthData {
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
}

export class AuthData implements IAuthData {
  public accessToken: string;
  public refreshToken: string;
  public expiresIn: number;

  constructor(
    accessToken?: string | null,
    refreshToken?: string | null,
    expiresIn?: number
  ) {
    this.accessToken = accessToken || "";
    this.refreshToken = refreshToken || "";
    this.expiresIn = expiresIn || 0;
  }
}