//index.ts

import applyAppTokenRefreshInterceptor from "../../xhr/refresh_token";
import { IXhrInstance, XhrInstance } from "./instance";

export class Xhr {
  public static instance: IXhrInstance = Xhr.createXhrInstance();

  private static createXhrInstance(): IXhrInstance {
    const client = new XhrInstance();

    applyAppTokenRefreshInterceptor(
      client,
      {}
    );

    return client;
  }
}