//authStorage.service.ts

import { AuthData, IAuthData } from "../../models";
import { isObject } from "../../utils";
import {
  get as storageGet,
  remove as storageRemove,
  set as storageSet,
  storagePrefix,
  StorageTypesEnum
} from "./storage.service";

const key = `${storagePrefix}auth`,
  storageType = StorageTypesEnum.Local;

export const get = (): IAuthData => {
  const data = storageGet(
    key,
    storageType
  );

  if (data && isObject(data)) {
    return new AuthData(
      data.accessToken,
      data.refreshToken,
      data.expiresIn
    );
  }
  
  return new AuthData();
};

export const set = (object: IAuthData) => {
  storageSet(
    key,
    object,
    storageType
  );
};

export const remove = () => {
  storageRemove(
    key,
    storageType
  );
};