import React, { FC, useEffect, useMemo, useState } from "react";
import { IBullState } from "../../core/models";
import CoinFrames from "../../template/img/coin-frames.png"

interface ISpinningCoin {
  bullState: IBullState
  spin?: boolean | null
  endSpin?: boolean | null
}
export const SpinningCoin:FC<ISpinningCoin> = ({bullState, spin, endSpin}) => {

  const [a, setA] = useState<number>(1);
  const [go, setGo] = useState<number>(1);
  const [stop, setStop] = useState<number>(0);
  const [speed, setSpeed] = useState<number>(1);

  function goFrame() {

    if (bullState === IBullState.Bull && a === 30){setGo(1); setStop(1)}
    if (bullState === IBullState.Bull && a !== 30){setGo(1); setStop(0)}
    if (bullState === IBullState.Bear && a === 1){ setGo(1); setStop(1)}
    if (bullState === IBullState.Bear && a !== 1){ setGo(1); setStop(0)}
    if (spin === true || endSpin === true)
      { setGo(1); setSpeed(9); setStop(0)}

    if ((bullState === IBullState.Bull && spin === false) || (bullState === IBullState.Bear && spin === false))
      { setGo(1); setSpeed(7); }

    if ((bullState === IBullState.Bull && endSpin === false) || (bullState === IBullState.Bear && endSpin === false))
      { setGo(1); setSpeed(7); setStop(0)}

    if (spin === null) setSpeed(1)

    let a1 = a;
    if (go === 2 && stop === 0) {
      a1 = a + speed;
    }
    if (go === 1 && stop === 0) {
      a1 = a - speed;
    }
    if (a1 > 59) {
      a1 = 1;
    }
    if (a1 < 1) {
      a1 = 59;
    }
    setA(a1)
    if (speed < 8 && speed > 1) {
      setSpeed(speed - 1);
    }
  }

  useEffect(()=>{
    setTimeout(goFrame, 30);
},[a, bullState, spin, endSpin])

  useEffect(
    () => {
      if (bullState === IBullState.Bear && a === 1 ) {
        setA(2);
      }
    },
    []
  );

  return (
    <div>
      <div className={"coins-animation-frame"} style={{
        width: "320px",
        height: "320px",
        backgroundImage: `url(${CoinFrames})`,
        backgroundPosition: `${-320 * (a - 1)}px 0px`,
      }}/>
      {/*<img src={imgFramesMemo[a-1]} alt=""/>*/}
    </div>
    )
}

export default SpinningCoin