export interface IModalWindows {
  openHowToVerify: boolean
  openHowToPlay: boolean
}

export class ModalWindows implements IModalWindows {
  public openHowToVerify: boolean;
  public openHowToPlay: boolean;
  constructor(
    openHowToVerify?: boolean,
    openHowToPlay?: boolean
  ) {
    this.openHowToVerify = openHowToVerify || false;
    this.openHowToPlay = openHowToPlay || false
  }
}