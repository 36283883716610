//error.model.ts

export enum ErrorEnum {
  Empty = 0,
  Unassigned = 1001,
  Mapping = 1002,
  API = 1003,
  NotFound = 1004,
  Forbidden = 1005,
  LazyLoad = 1006,
  AuthToken = 1007,
  RefreshToken = 1008,
  SessionEnded = 1009,
  GRPC = 1010,
  Wallet = 1011,
  WalletAuth = 1012,
  Identity = 1013,
  Contract = 1014
}

export class Error {
  public readonly status: ErrorEnum;
  public readonly message?: string;
  public readonly code?: string;
  private readonly innerMessage?: string;
  private readonly innerStatus?: string | number;
  private readonly innerError?: Error;
  private readonly time: number;

  constructor(
    status: ErrorEnum,
    message?: string,
    innerMessage?: string,
    code?: string,
    innerStatus?: string | number,
    innerError?: Error
  ) {
    this.status = status;
    this.message = !message ?
      this.setMessage(this.status) :
      message;
    this.innerMessage = innerMessage;
    this.code = code;
    this.innerStatus = innerStatus;
    this.time = (new Date()).getTime();
    this.innerError = innerError;
  }

  private setMessage(status: ErrorEnum): string | undefined {
    switch (status) {
      case ErrorEnum.Empty: {
        return undefined;
      }
      case ErrorEnum.Mapping: {
        return `Error while converting object`

      }
      case ErrorEnum.API: {
        return `API error`
      }
      case ErrorEnum.NotFound: {
        return `Not found`
      }
      case ErrorEnum.Forbidden: {
        return `Forbidden`
      }
      case ErrorEnum.LazyLoad: {
        return `Chunk load error`
      }
      case ErrorEnum.AuthToken: {
        return `Auth token error`
      }
      case ErrorEnum.RefreshToken: {
        return `Refresh token error`
      }
      case ErrorEnum.SessionEnded: {
        return `Session ended`
      }
      case ErrorEnum.GRPC: {
        return `GRPC error`
      }
      case ErrorEnum.Wallet: {
        return`Wallet error`
      }
      case ErrorEnum.WalletAuth: {
        return `Wallet authentication error`
      }
      case ErrorEnum.Identity: {
        return `User identity error`
      }
      case ErrorEnum.Contract: {
        return `Contact usage error`
      }
      default: {
        return `Unassigned error`
      }
    }
  }
}