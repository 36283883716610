import { configureStore } from '@reduxjs/toolkit'
import {globalSettingsSlice} from "./globalSettingsSlice";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {accountSlice} from "./accountSlice";

export const store = configureStore({
    reducer: {
        globalSettings: globalSettingsSlice.reducer,
        account: accountSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: false,
        })
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector