//deposit.api.ts

import { XhrService } from "../../services";
import { call } from "./axios";
import { DepositEndpointDefinitionApi, DepositResponse } from "../../swaggerFlips/src";
import { getConfiguration } from "./swagger";
import { processError } from "../../utils/helpers";
import { ErrorEnum } from "../../view/react/core/models";
import { contract } from "./contract";
import { ContractMethods } from "./contractMethods";
import { IDepositariumContracts } from "../../models/game/gameModels.model";

export class DepositApi {
  public static async setDeposit(
    address: string,
    networkId: number,
    amount: number,
    depositariumContracts: Array<IDepositariumContracts>,
    ignoreLogInJump: boolean = false
  ): Promise<string> {
    try {
      const data = await XhrService.call<DepositResponse>(
        // async () => {
        //   return await contract.requestWithCheckNetwork(
        //     networkId,
            async () => {
              return await call<DepositResponse>(
                async () => {
                  return await this.getDepositApiInstance()
                    .apiFlipsDepositGet(
                      networkId,
                      amount * 100
                    );
                });
            }
          // );
        //}
        );
      const tr =
        //       await contract.requestWithCheckNetwork(
        // networkId,
        // async ()=>{
        // return
          await ContractMethods.deposit(data.amountWei || 0 , networkId, depositariumContracts)
      //   }
      // )
      return tr
    }
    catch (error: any) {
      throw processError(
        ErrorEnum.API,
        "DepositApi.setDeposit(). Error",
        error.message
      );
    }
  }

  private static getDepositApiInstance(): DepositEndpointDefinitionApi {
    return new DepositEndpointDefinitionApi(getConfiguration(
      undefined,
      false,
      false
    ));
  }
}