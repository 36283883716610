import { CommonSettings, SettingsEndpointApi } from "../../swaggerFlips/src";
import { getConfiguration } from "./swagger";
import { XhrService } from "../../services";
import { processError } from "../../utils/helpers";
import { ErrorEnum } from "../../view/react/core/models";
import { call } from "./axios";
import { settingsSmToVm } from "../../mapping/api/smToVm/settings.smToVm";

export class SettingsApi {
  public static async getSettings() {
    try {
      const data = await XhrService.call<CommonSettings>(
        async () => {
          return await call<CommonSettings>(
            async () => {
              return await this.getSettingsApiInstance()
                .apiFlipsSettingsGet();
            }
          );
        });
      return settingsSmToVm(data);
    }
    catch (error: any) {
      throw processError(
        ErrorEnum.API,
        "SettingsApi.getSettings(). Error",
        error.message
      );
    }
  }

  private static getSettingsApiInstance(): SettingsEndpointApi {
    return new SettingsEndpointApi(getConfiguration(
      undefined,
      false,
      false
    ));
  }
}