//withdraw.api.ts

import { XhrService } from "../../services";
import { call } from "./axios";
import { WithdrawEndpointDefinitionApi, WithdrawResponse } from "../../swaggerFlips/src";
import { getConfiguration } from "./swagger";
import { processError } from "../../utils/helpers";
import { ErrorEnum } from "../../view/react/core/models";
import { contract } from "./contract";
import { ContractMethods } from "./contractMethods";
import { IDepositariumContracts } from "../../models/game/gameModels.model";

export class WithdrawApi {
  public static async setWithdraw(
    amountWei: number,
    networkId: number,
    depositariumContracts: Array<IDepositariumContracts>
  ): Promise<string> {
    try {
      const data = await XhrService.call<WithdrawResponse>(
        // async () => {
        //   return await contract.requestWithCheckNetwork(
        //     networkId,
            async () => {
              return await call<WithdrawResponse>(
                async () => {
                  return await this.postWithdrawApiInstance()
                    .apiFlipsWithdrawPost({
                        amountWei: +amountWei.toFixed(0),
                        chainId: networkId
                      }
                    );
                });
            }
        //   );
        // }
        );

      const tr =
        //       await contract.requestWithCheckNetwork(
        // networkId,
        // async () => {
        //   return
          await ContractMethods.withdraw(
            data.address? data.address : "",
            data.amountWei? data.amountWei: 0,
            data.withdrawnTotal? data.withdrawnTotal: 0,
            data.validUntil? data.validUntil : 0,
            data.signature? data.signature: "",
            networkId,
            depositariumContracts
          );
      //   }
      // )
      return tr
    }
    catch (error: any) {
      throw processError(
        ErrorEnum.API,
        "WithdrawApi.setWithdraw(). Error",
        error.message
      );
    }
  }

  private static postWithdrawApiInstance(): WithdrawEndpointDefinitionApi {
    return new WithdrawEndpointDefinitionApi(getConfiguration(
      undefined,
      false,
      false
    ));
  }
}