import React, { FC } from "react";
import close from "../../template/img/close.svg";
import { IStateScreen } from "../../core/models";

interface IFaqModal {
  openFaq: boolean;
  setOpenFaqCallback: (param: boolean) => void;
  setOpenHowToVerifyCallback: (param: boolean) => void;
  setOpenProvablyFairCallback: (param: boolean) => void;
  setStateScreenCallback: (param: IStateScreen) => void
  pushSound: HTMLAudioElement
}

const FaqModal: FC<IFaqModal> = ({
  openFaq,
  setOpenFaqCallback,
  setOpenHowToVerifyCallback,
  setOpenProvablyFairCallback,
  setStateScreenCallback,
  pushSound
}) => {

  const qBox = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    document.querySelector(".q-box.open")
      ?.classList.remove("open");
    (e.currentTarget as Element).classList.add("open");
  };

  return (
    <div className={`modal-bg${openFaq ? " show" : ""}`}>
      <div className={`modal${openFaq ? " show" : ""}`} id="modal-1">
        <a className="close modal-close" onClick={() => setOpenFaqCallback(false)}>
          <img src={close} alt="" />
        </a>
        <div className="content">
          <div className="faq-title mb-30">Frequently Asked Questions</div>
          <div className="q-box open" onClick={(e) => qBox(e)}>
            <div className="title">What is GamerFi?</div>
            <div className="text">GamerFi was born with the intention of providing players all over the world a
              transparently fair and fun way of playing simple easy to understand games we all loved as kids, with big
              payouts!
            </div>
          </div>
          <div className="q-box" onClick={(e) => qBox(e)}>
            <div className="title">Can I trust GamerFi?</div>
            <div className="text">Don't trust us! Gamerfi uses the blockchain technology to produce the outcome of all
              50/50 results. Anyone can check and audit their result. Full transparency! Check out <a
                className="text-red text-decoration-underline"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setOpenFaqCallback(false);
                  setOpenProvablyFairCallback(true);
                }}>Provably fair</a> and <a
                  className="text-red text-decoration-underline"
                  style={{cursor: "pointer"}}
                  onClick={() => {
                  setOpenFaqCallback(false);
                  setOpenHowToVerifyCallback(true);
                }}>How to verify</a> sections in the website's menu for more information.
            </div>
          </div>
          <div className="q-box" onClick={(e) => qBox(e)}>
            <div className="title">What are the fees?</div>
            <div className="text">Gamerfi only charges 3% (the lowest in the industry) for winning flips (!) to help
              further development of the platform. When depositing into the platform and playing with your GamerFi
              balance, all flip gas fees are free. Flipping directly from your wallet will incur gas fees on the network
              you choose to flip on to generate the fair outcome. We recommend depositing and playing from your GamerFi
              balance.
            </div>
          </div>
          <div className="q-box" onClick={(e) => qBox(e)}>
            <div className="title">Why can't I see my GamerFi balance?</div>
            <div className="text">If this happens, please check you're connected to the correct network. For example If
              you deposited BNB, please switch to Binance Smart Chain on your decentralized wallet to see your deposited
              funds.
            </div>
          </div>
          <div className="q-box" onClick={(e) => qBox(e)}>
            <div className="title">How do I withdraw my money?</div>
            <div className="text">Simply click withdraw when your wallet is connected, the funds will be transferred
              from our smart contract directly into your metamask wallet. We recommend you leave some crypto in your
              wallet to pay for gas fees when withdrawing.
            </div>
          </div>
          <div className="q-box" onClick={(e) => qBox(e)}>
            <div className="title">Why can't I withdraw my full GamerFi balance?</div>
            <div className="text">Bonus/giveaway money that is deposited into your GamerFi wallet cannot be withdrawn.
              However any winnings using the deposit/giveaway money can be withdrawn. If this is not the case, please
              get in touch with us on our social media and we will fix the issue.
            </div>
          </div>
          <div className="q-box" onClick={(e) => qBox(e)}>
            <div className="title">How can I contact you?</div>
            <div className="text">You can contact us on our twitter, just DM us with any issues you're facing and we
              will respond as soon as possible.
            </div>
          </div>
          <div className="q-box" onClick={(e) => qBox(e)}>
            <div className="title">Am I addicted to flipping?</div>
            <div className="text">Please remember this is meant to be just for fun! If you feel like you're getting
              addicted, please check out advice to consider if you are gambling on BeGambleAware website.
            </div>
          </div>
          <div className="q-box" onClick={(e) => qBox(e)}>
            <div className="title">GAS FEES</div>
            <div className="text">Gas fees are not included in our commission. We set a flat rate ($2–$4, depending on
              network congestion) when using Flip from wallet mode on the Ethereum network. Please note, gas depends on
              network congestion and may vary.
              To avoid paying gas fees on every flip, <a
                className="text-red text-decoration-underline"
                onClick={()=>{
                  setOpenFaqCallback(false);
                  setStateScreenCallback(IStateScreen.Deposit);
                }}>Deposit</a> into the platform and play completely gas free. It's on
              us!
            </div>
          </div>
          {/*<div className="q-box" onClick={(e) => qBox(e)}>*/}
          {/*  <div className="title">Deposit Vs Flipping directly from your wallet?</div>*/}
          {/*  <div className="text">Depositing your crypto will allow you to play with polygon gas fees instead, which are*/}
          {/*    the cheapest on the market. We recommend depositing when playing with ETH. You can also gain access to*/}
          {/*    multiple free airdrops and goodies.*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="mt-60 btn-play">
            <a className="btn-vl btn-pink modal-close screen-open" data-id="screen-1"
                 onClick={() => {
                   pushSound.play().then();
                   setOpenFaqCallback(false);
                 }}>
              <span>Play Now</span>
            </a>
          </div>

        </div>
      </div>
    </div>
  );
};
export default FaqModal;