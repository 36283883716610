//statistics.api.ts

import { StatisticsEndpointsApi, StatisticsResponse } from "../../swaggerFlips/src";
import { getConfiguration } from "./swagger";
import { XhrService } from "../../services";
import { call } from "./axios";
import { processError } from "../../utils/helpers";
import { ErrorEnum } from "../../view/react/core/models";

export class StatisticsApi {
  public static async Statistics(): Promise<StatisticsResponse> {
    try {
      const data = await XhrService.call(
        async () => {
          return await call(
            async () => {
              return await this.getStatisticsEndpointsApiInstance()
                .apiFlipsStatisticsGet();
            });
        });
      return data;
    }
    catch (error: any) {
      throw processError(
        ErrorEnum.API,
        "StatisticsApi.Statistics(). Error",
        error.message
      );
    }
  }

  private static getStatisticsEndpointsApiInstance(): StatisticsEndpointsApi {
    return new StatisticsEndpointsApi(getConfiguration(
      undefined,
      false,
      false
    ));
  }
}