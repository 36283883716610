//processError.util.ts

import { Error, ErrorEnum } from "../../view/react/core/models";
import { logger } from "./index";


export const processError = (
  status: ErrorEnum,
  innerMessage: string,
  error: any
) => {
  return _(new Error(
    status,
    error?.message,
    innerMessage,
    error?.code,
    undefined,
    _(
      error,
      false
    )
  ));

  function _(
    error: any,
    traceError: boolean = true
  ): Error {
    if (!(error instanceof Error)) {
      error = new Error(
        status,
        error?.message,
        undefined,
        error?.code
      );
    }

    if (traceError) {
      logger.trace(error);
    }

    return error;
  }
};