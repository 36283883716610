import React, { FC } from "react";
import close from "../../template/img/close.svg";

interface IHowToPlayModal {
  openHowToPlay: boolean;
  setOpenHowToPlayCallback: (param: boolean) => void;
  pushSound: HTMLAudioElement
}

const HowToPlayModal: FC<IHowToPlayModal> = ({ openHowToPlay, setOpenHowToPlayCallback, pushSound }) => {

  return (
    <div className={`modal-bg${openHowToPlay ? " show" : ""}`}>
      <div className={`modal${openHowToPlay ? " show" : ""}`} id="modal-2">
        <a className="close modal-close" onClick={() => setOpenHowToPlayCallback(false)}>
          <img src={close} alt="" />
        </a>
        <div className="content htp2">
          <div className="rw mt-30 mb-30">
            <div className="c-m-6 htp">
            </div>
            <div className="c-m-6">
              <div className="htp-title mb-30">How To Play</div>
              <ol className="htp-text">
                <li>Connect your decentralized wallet</li>
                <li>Choose the chain you want to flip on in your decentralized wallet directly (you may choose between
                  Ethereum, Polygon and Binance Smart Chain)
                </li>
                <li>Either deposit or flip directly from your wallet</li>
                <li>Pick either Bull or Bear.</li>
                <li>Select the amount you want to flip for.</li>
                <li>Press “Flip”.</li>
                <li>Confirm the transaction if you flip from wallet and wait for the coin to flip.</li>
                <li>Withdraw your winnings to your decentralized wallet</li>
                <li>Flip it to win it!</li>
              </ol>
              <div className="mt-60">
                <a className="btn-vl btn-pink modal-close screen-open mx-auto" data-id="screen-1"
                   onClick={() => {
                     pushSound.play().then();
                     setOpenHowToPlayCallback(false)
                   }}>
                  <span>Play Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HowToPlayModal;