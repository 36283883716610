//api.client.ts

import axios, { AxiosInstance } from "axios";
import { apiSettings } from "../api.settings";

export class API {
  public static axiosApiClient = API.getAxiosApiClient();

  public static getAxiosApiClient(
    baseUrl?: string
  ): AxiosInstance {
    const client = axios.create(
      {
        baseURL: baseUrl || apiSettings.baseUrl
      }
    );

    client.interceptors.request.use(
      (config) => {
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    return client;
  }
}