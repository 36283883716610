import React, { FC, useEffect, useState } from "react";
import close from "../../template/img/close.svg";
import { addressBaseURL } from "../../core/settings/common.settings";
import { store, useAppDispatch, useAppSelector } from "../../core/stores/store";
import { setOpenHowToVerifyAction, setOpenHowToVerifyActionFalse } from "../../core/stores/accountSlice";

interface IHowToVerifyModal {
  openHowToVerify: boolean;
  setOpenHowToVerifyCallback: (param: boolean) => void;
  pushSound: HTMLAudioElement
}

const HowToVerifyModal: FC<IHowToVerifyModal> = ({
    openHowToVerify, setOpenHowToVerifyCallback,
    pushSound
}
) => {

  //const [openHowToVerifyState, setOpenHowToVerifyState] = useState<boolean>();

  // @ts-ignore
  //const openHowToVerifySelector = useAppSelector(state => state.account.data.modalWindows.openHowToVerify);
  //const dispatch = useAppDispatch();

  // useEffect(()=>{
  //   // @ts-ignore
  //   setOpenHowToVerifyState(openHowToVerifySelector)
  // }, [openHowToVerifySelector])

  // useEffect(()=>{
  //   const unsubscribe = store.subscribe(()=>{
  //     const currentValue = (store.getState().account.data.modalWindows.openHowToVerify);
  //     setOpenHowToVerifyState(currentValue)
  //   })
  //   return ()=> unsubscribe()
  // },[])


  //console.log(openHowToVerifyState)

  return (
    <div className={`modal-bg${openHowToVerify ? " show" : ""}`}>
     {/*<div className={`openHowToVerify modal-bg${openHowToVerifyState ? " show" : ""}`}>*/}
      <div className={`modal${openHowToVerify ? " show" : ""}`} id="modal-2">
      {/*<div className={`modal${openHowToVerifyState ? " show" : ""}`} id="modal-2">*/}
        <a className="close modal-close"
           onClick={() => {
             // dispatch(setOpenHowToVerifyAction(false))
             setOpenHowToVerifyCallback(false)
             }}
        >
          <img src={close} alt="" />
        </a>
        <div className="content">
          <div className="rw mt-30 mb-30">
            <div className="c-m-6 htp htp4"></div>
            <div className="c-m-6">
              <div className="htp-title mb-30">How to verify</div>
              <div className="htp-text mb-15">To verify your result:
              </div>
              <div className="htp-text mb-15">
                1. Click "Verify result" to see the transaction details.
              </div>
              <div className="htp-text mb-15">
                2. Go to Logs tab.
              </div>
              <div className="htp-text mb-15">
                3. Find a RandomSeedGenerated Event containing your wallet address in the 4th line (usually it's the only event in the list).
              </div>
              <div className="htp-text mb-15">
                4. Change the drop-down value on the last line in the event from HEX to NUMBER - this is your random seed.
              </div>
              <div className="htp-text mb-15">
                5. Go to <a href={addressBaseURL+"/random"} target="_blank" className="text-red text-decoration-underline">link</a> or any random verify page with the Mersenne Twister algorithm to generate random numbers.
              </div>
              <div className="htp-text mb-15">
                6. Paste your seed to the seed field.
              </div>
              <div className="htp-text mb-15">
                7. If the resulting number is {'<'} 0.5, the result is BEAR. If it's {'>'}= 0.5, the result is BULL.
              </div>
              <div className="htp-text mb-15">
                8. You win if your prediction and the result match.
              </div>
              <div className="htp-text">Don't trust us. Verify!</div>
              <div className="mt-60">
                <a className="btn-vl btn-pink modal-close screen-open mx-auto"
                   onClick={() => {
                     pushSound.play().then();
                     setOpenHowToVerifyCallback(false);
                     //dispatch(setOpenHowToVerifyAction(false))
                   }}><span>Play Now</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HowToVerifyModal;