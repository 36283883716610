import {IChainSettings, IStateScreen} from "../models/game/gameModels.model";
import { roundUp } from "../utils/helpers";

export function calculationAmount(
  amount: number,
  settings: IChainSettings,
  doublePlay: boolean
): number {
  let percentage = 1 - (doublePlay ? settings.doubleOrNothingPercentage : settings.percentage) / 100;
  let flat = (doublePlay ? settings.doubleOrNothingFlat : settings.flat);
  let total = (amount - flat) * 2 * percentage;

  return roundUp(total);
}
