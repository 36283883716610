//standAloneFlip.api.ts

import { XhrService } from "../../services";
import { call } from "./axios";
import {
  StandaloneFlipEndpointsApi, StandaloneFlipResponse
} from "../../swaggerFlips/src";
import { getConfiguration } from "./swagger";
import { processError } from "../../utils/helpers";
import { ErrorEnum } from "../../view/react/core/models";
import { ContractMethods } from "./contractMethods";
import { IBullState } from "../../models";
import { IDepositariumContracts } from "../../models/game/gameModels.model";

export class StandAloneFlipApi {
  public static async FlipsStandAlone(
    rangeState: number,
    bullState: IBullState,
    networkId: number,
    depositariumContracts: Array<IDepositariumContracts>
  ): Promise<any> {
    try {
      const data = await XhrService.call<StandaloneFlipResponse>(
        // async () => {
        //   return await contract.requestWithCheckNetwork(
        //     networkId,
            async () => {
              return await call<StandaloneFlipResponse>(
                async () => {
                  return await this.postStandAloneFlipApiInstance()
                    .apiFlipsStandaloneGet(
                      networkId,
                      +(rangeState * 100).toFixed(0),
                    );
                });
            }
        //   );
        // }
        );
      const tr =
        //       await contract.requestWithCheckNetwork(
        // networkId,
        // async () => {
        //   return
          await ContractMethods.flipStandAlone(
            data.amountWei? data.amountWei.toString() : "",
            data.gameId? data.gameId.toString(): "",
            bullState === IBullState.Bull ? false : true,
            networkId,
            depositariumContracts
          );
        //}
      //)
      return tr
    }
    catch (error: any) {
      throw processError(
        ErrorEnum.API,
        "StandAloneFlipApi.FlipsStandAlone(). Error",
        error.message
      );
    }
  }

  public static async FlipsStandaloneDoubleOrNothing(
    bullState: IBullState,
    networkId: number,
    depositariumContracts: Array<IDepositariumContracts>
  ): Promise<any> {
    try {
      const data = await XhrService.call<StandaloneFlipResponse>(
        // async () => {
        //   return await contract.requestWithCheckNetwork(
        //     networkId,
            async () => {
              return await call<StandaloneFlipResponse>(
                async () => {
                  return await this.postStandAloneFlipApiInstance()
                    .apiFlipsStandaloneDoubleOrNothingGet(
                      networkId
                    );
                });
            }
        //   );
        // }
        );
      const tr =
        //       await contract.requestWithCheckNetwork(
        // networkId,
        //async () => {
       //   return
          await ContractMethods.flipStandAlone(
            data.amountWei? data.amountWei.toString() : "",
            data.gameId? data.gameId.toString(): "",
            bullState === IBullState.Bull ? false : true,
            networkId,
            depositariumContracts
          );
         //}
       //)
      return tr
    }
    catch (error: any) {
      throw processError(
        ErrorEnum.API,
        "StandAloneFlipApi.FlipsStandaloneDoubleOrNothing(). Error",
        error.message
      );
    }
  }

  private static postStandAloneFlipApiInstance(): StandaloneFlipEndpointsApi {
    return new StandaloneFlipEndpointsApi(getConfiguration(
      undefined,
      false,
      false
    ));
  }
}