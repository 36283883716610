import React, { FC } from "react";
import bull from "../../template/img/frames/30.png";
import bear from "../../template/img/frames/01.png";
import { IBullState } from "../../core/models";

interface ITimeIsUpFree {
  setFlipCancelAll: () => void;
  setFlipCancelLost: () => void
  bullState: IBullState;
  startTimer: boolean | null
  startTimer2: boolean | null
  win: boolean | null
  pushSound: HTMLAudioElement
  winAmount: number
  setOpenChoiceAfterFreePlayModalCallback: (param: boolean) => void;
}

const TimeIsUpFree: FC<ITimeIsUpFree> = ({
  setFlipCancelAll,
  setFlipCancelLost,
  bullState,
  startTimer,
  startTimer2,
  win,
  pushSound,
  winAmount,
  setOpenChoiceAfterFreePlayModalCallback
}) => {

  return (
    <div className={`b-1-box win${(startTimer === false && win === true) || (startTimer2 === false && win === null) ? " active" : ""}`} id="screen-8">
      <div className="title">
        <div className="text-blue mb-15">Time’s up!</div>
        <div className="text text-blue">YOU COULD’VE WON ${winAmount}</div>
      </div>
      <div className="coints">
        <div>
          <img src={bullState === IBullState.Bull ? bull : bear} alt=""
               className={bullState === IBullState.Bull ? "bull" : "bear"} />
        </div>
      </div>
      <div className="controll">
        <div className="win-btn-1">
          <a className="btn-vl btn-flip btn-yellow screen-open" data-id="screen-1"
               onClick={() => {
                 pushSound.play().then();
                 setFlipCancelLost()
                 //setFlipCancelAll();
               }}>
            <span>New free game</span>
          </a>
        </div>
        <div className="win-btn-2">
          <div className="btn-vl btn-flip btn-blue screen-open" data-id="screen-1"
               onClick={() => {
                 pushSound.play().then();
                 setOpenChoiceAfterFreePlayModalCallback(true);
                 setFlipCancelAll();
               }}>
            <span>Exit free flip</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeIsUpFree