import { contract } from "./contract";
import {
  ContractAbi, ContractBnbTest,
  ContractMumbai, ContractPolygon,
  ContractSepolia, txBnb,
  txBnbTest, txEthereum,
  txMumbai, txPolygon,
  txSepolia
} from "../../settings/contract.settings";
import { processError } from "../../utils/helpers";
import { ErrorEnum } from "../../view/react/core/models";
import {
  bnbId, bnbTestId,
  ethereumId, mumbaiId,
  polygonId, sepoliaId,
  prod
} from "../../settings/common.settings";
import { IDepositariumContracts } from "../../models/game/gameModels.model";

export class ContractMethods {

  private static chooseContract(networkId: number) {
    switch (networkId) {
      case polygonId:
        return ContractPolygon;
      case sepoliaId :
        return ContractSepolia;
      case bnbTestId:
        return ContractBnbTest;
      case mumbaiId:
        return ContractMumbai;
      default:
        console.log("switch network");
        return ContractPolygon;
    }
  }

  private static chooseContractAbi() {
    return ContractAbi
  }



  public static chooseTx(
    networkId: number,
    back: boolean = false
  ) {

    if (back && !prod) {
      return txMumbai;
    }
    if (back && prod) {
      return txPolygon
    }

    switch (networkId) {
      case ethereumId:
        return txEthereum;
      case polygonId:
        return txPolygon;
      case bnbId:
        return txBnb;
      case sepoliaId:
        return txSepolia;
      case bnbTestId:
        return txBnbTest;
      case mumbaiId:
        return txMumbai;
      default:
        console.log("switch network");
        return txPolygon;
    }
  }

  public static async deposit(
    price: number,
    networkId: number,
    depositariumContracts: Array<IDepositariumContracts>
  ): Promise<string> {
    try {
      const data = await contract.write(
        "deposit",
        [],
        {
          value: BigInt(price)
            .toString()
        },
        //this.chooseContract(networkId),
        depositariumContracts.filter(el=>el.chainId === networkId)[0].address,
        this.chooseContractAbi().abi,
      );
      return data.transactionHash;
    }
    catch (error: any) {
      if (error.message.includes("insufficient funds")) {
        return "NoMoney";
      }
      else {
        throw processError(
          ErrorEnum.Contract,
          "ContractMethods.deposit(). Error",
          error
        );
      }
    }
  }


  public static async withdraw(
    address: string,
    amountWei: number,
    withdrawnTotal: number,
    validUntil: number,
    signature: string,
    networkId: number,
    depositariumContracts: Array<IDepositariumContracts>
  ): Promise<string> {
    try {
      const data = await contract.write(
        "withdraw",
        [[
          address,
          amountWei.toString(),
          withdrawnTotal.toString(),
          validUntil.toString()
        ],
          signature
        ],
        {},
        //this.chooseContract(networkId),
        depositariumContracts.filter(el=>el.chainId === networkId)[0].address,
        this.chooseContractAbi().abi
      );
      return data.transactionHash;
    }
    catch (error: any) {
      throw processError(
        ErrorEnum.Contract,
        "ContractMethods.withdraw(). Error",
        error
      );
    }
  }


  public static async flipStandAlone(
    amount: string,
    gameId: string,
    bullState: boolean,
    networkId: number,
    depositariumContracts: Array<IDepositariumContracts>
  ): Promise<any> {
    try {
      const data = await contract.write(
        "depositForInstantGame",
        [
          gameId,
         (bullState ? 0 : 1).toString()
        ],
        {
          value: amount
        },
        //this.chooseContract(networkId),
        depositariumContracts.filter(el=>el.chainId === networkId)[0].address,
        this.chooseContractAbi().abi
      );
      return data;
    }
    catch (error: any) {
      if (error.message.includes("insufficient funds")) {
        return "NoMoney";
      }
      else {
        throw processError(
          ErrorEnum.Contract,
          "ContractMethods.flipStandAlone(). Error",
          error
        );
      }
    }
  }
}