/* tslint:disable */
/* eslint-disable */
/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ChainBalance
 */
export interface ChainBalance {
    /**
     * 
     * @type {number}
     * @memberof ChainBalance
     */
    'wei'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChainBalance
     */
    'usdCents'?: number;
}
/**
 *
 * @export
 * @interface FlipCommission
 */
export interface FlipCommission {
    /**
     *
     * @type {number}
     * @memberof FlipCommission
     */
    flat?: number;
    /**
     *
     * @type {number}
     * @memberof FlipCommission
     */
    percentage?: number;
    /**
     *
     * @type {number}
     * @memberof FlipCommission
     */
    doubleOrNothingFlat?: number;
    /**
     *
     * @type {number}
     * @memberof FlipCommission
     */
    doubleOrNothingPercentage?: number;
    /**
     *
     * @type {number}
     * @memberof FlipCommission
     */
    minBetCents?: number;
    /**
     *
     * @type {number}
     * @memberof FlipCommission
     */
    maxBetCents?: number;
    /**
     *
     * @type {number}
     * @memberof FlipCommission
     */
    maxDoubleOrNothingBetCents?: number;
}
/**
 *
 * @export
 * @interface CommissionsResponse
 */
export interface CommissionsResponse {
    /**
     *
     * @type {FlipCommission}
     * @memberof CommissionsResponse
     */
    fromBalanceCommission?: FlipCommission;
    /**
     *
     * @type {{ [key: string]: FlipCommission; }}
     * @memberof CommissionsResponse
     */
    fromWalletCommissions?: { [key: string]: FlipCommission; } | null;
}

/**
 * 
 * @export
 * @interface CommonSettings
 */
export interface CommonSettings {
    /**
     * 
     * @type {CommissionsResponse}
     * @memberof CommonSettings
     */
    'commissions'?: CommissionsResponse;
    /**
     * 
     * @type {ContractsResponse}
     * @memberof CommonSettings
     */
    'contracts'?: ContractsResponse;
    /**
     * 
     * @type {FlipsConfig}
     * @memberof CommonSettings
     */
    'flips'?: FlipsConfig;
}
/**
 * 
 * @export
 * @interface ContractDto
 */
export interface ContractDto {
    /**
     * 
     * @type {number}
     * @memberof ContractDto
     */
    'chainId'?: number;
    /**
     * 
     * @type {object}
     * @memberof ContractDto
     */
    'address'?: object;
}
/**
 * 
 * @export
 * @interface ContractsResponse
 */
export interface ContractsResponse {
    /**
     * 
     * @type {ContractDto}
     * @memberof ContractsResponse
     */
    'flipsContract'?: ContractDto;
    /**
     * 
     * @type {Array<ContractDto>}
     * @memberof ContractsResponse
     */
    'depositariumContracts'?: Array<ContractDto> | null;
}
/**
 * 
 * @export
 * @interface DepositResponse
 */
export interface DepositResponse {
    /**
     * 
     * @type {string}
     * @memberof DepositResponse
     */
    'address'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DepositResponse
     */
    'chainId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DepositResponse
     */
    'amountUsdCents'?: number;
    /**
     * 
     * @type {number}
     * @memberof DepositResponse
     */
    'amountWei'?: number;
}
/**
 * 
 * @export
 * @interface FlipsConfig
 */
export interface FlipsConfig {
    /**
     * 
     * @type {number}
     * @memberof FlipsConfig
     */
    'minBetCents'?: number;
    /**
     * 
     * @type {number}
     * @memberof FlipsConfig
     */
    'maxBetCents'?: number;
    /**
     * 
     * @type {number}
     * @memberof FlipsConfig
     */
    'doubleOrNothingMaxBetCents'?: number;
}
/**
 * 
 * @export
 * @interface RecentFlipDto
 */
export interface RecentFlipDto {
    /**
     * 
     * @type {number}
     * @memberof RecentFlipDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RecentFlipDto
     */
    'player'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RecentFlipDto
     */
    'isEven'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RecentFlipDto
     */
    'hasWon'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RecentFlipDto
     */
    'betCents'?: number;
    /**
     * 
     * @type {number}
     * @memberof RecentFlipDto
     */
    'winningsCents'?: number;
    /**
     * 
     * @type {number}
     * @memberof RecentFlipDto
     */
    'betWei'?: number;
    /**
     * 
     * @type {number}
     * @memberof RecentFlipDto
     */
    'winningsWei'?: number;
    /**
     * 
     * @type {string}
     * @memberof RecentFlipDto
     */
    'transactionHash'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RecentFlipDto
     */
    'updatedAt'?: number;
}
/**
 * 
 * @export
 * @interface StandaloneFlipResponse
 */
export interface StandaloneFlipResponse {
    /**
     * 
     * @type {number}
     * @memberof StandaloneFlipResponse
     */
    'amountWei'?: number;
    /**
     * 
     * @type {number}
     * @memberof StandaloneFlipResponse
     */
    'gameId'?: number;
}
/**
 * 
 * @export
 * @interface StatisticsResponse
 */
export interface StatisticsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof StatisticsResponse
     */
    'mostSelectedIsEven'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatisticsResponse
     */
    'hasEvenWonMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof StatisticsResponse
     */
    'evenOddWinPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatisticsResponse
     */
    'globalWinPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatisticsResponse
     */
    'totalFlips'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatisticsResponse
     */
    'averageBetSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatisticsResponse
     */
    'highestWinStreak'?: number;
    /**
     * 
     * @type {string}
     * @memberof StatisticsResponse
     */
    'highestWinStreakAddress'?: string | null;
}
/**
 * 
 * @export
 * @interface UserProfileResponse
 */
export interface UserProfileResponse {
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponse
     */
    'address'?: string | null;
    /**
     * 
     * @type {{ [key: string]: ChainBalance; }}
     * @memberof UserProfileResponse
     */
    'balances'?: { [key: string]: ChainBalance; } | null;
    /**
     * 
     * @type {number}
     * @memberof UserProfileResponse
     */
    'bonusCents'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileResponse
     */
    'isBlocked'?: boolean;
}
/**
 * 
 * @export
 * @interface WithdrawRequest
 */
export interface WithdrawRequest {
    /**
     * 
     * @type {number}
     * @memberof WithdrawRequest
     */
    'amountWei'?: number;
    /**
     * 
     * @type {number}
     * @memberof WithdrawRequest
     */
    'chainId'?: number;
}
/**
 * 
 * @export
 * @interface WithdrawResponse
 */
export interface WithdrawResponse {
    /**
     * 
     * @type {string}
     * @memberof WithdrawResponse
     */
    'address'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WithdrawResponse
     */
    'amountWei'?: number;
    /**
     * 
     * @type {number}
     * @memberof WithdrawResponse
     */
    'withdrawnTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof WithdrawResponse
     */
    'validUntil'?: number;
    /**
     * 
     * @type {string}
     * @memberof WithdrawResponse
     */
    'signature'?: string | null;
}

/**
 * ApiApi - axios parameter creator
 * @export
 */
export const ApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configsFetchPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/configs/fetch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/_stop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiApi - functional programming interface
 * @export
 */
export const ApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configsFetchPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configsFetchPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stopPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stopPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiApi - factory interface
 * @export
 */
export const ApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configsFetchPost(options?: any): AxiosPromise<void> {
            return localVarFp.configsFetchPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopPost(options?: any): AxiosPromise<void> {
            return localVarFp.stopPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiApi - object-oriented interface
 * @export
 * @class ApiApi
 * @extends {BaseAPI}
 */
export class ApiApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public configsFetchPost(options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).configsFetchPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public stopPost(options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).stopPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommissionEndpointsApi - axios parameter creator
 * @export
 */
export const CommissionEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlipsCommissionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/flips/commissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommissionEndpointsApi - functional programming interface
 * @export
 */
export const CommissionEndpointsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommissionEndpointsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFlipsCommissionsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommissionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFlipsCommissionsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommissionEndpointsApi - factory interface
 * @export
 */
export const CommissionEndpointsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommissionEndpointsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlipsCommissionsGet(options?: any): AxiosPromise<CommissionsResponse> {
            return localVarFp.apiFlipsCommissionsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommissionEndpointsApi - object-oriented interface
 * @export
 * @class CommissionEndpointsApi
 * @extends {BaseAPI}
 */
export class CommissionEndpointsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommissionEndpointsApi
     */
    public apiFlipsCommissionsGet(options?: AxiosRequestConfig) {
        return CommissionEndpointsApiFp(this.configuration).apiFlipsCommissionsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContractsEndpointsApi - axios parameter creator
 * @export
 */
export const ContractsEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlipsContractsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/flips/contracts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractsEndpointsApi - functional programming interface
 * @export
 */
export const ContractsEndpointsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractsEndpointsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFlipsContractsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFlipsContractsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContractsEndpointsApi - factory interface
 * @export
 */
export const ContractsEndpointsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractsEndpointsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlipsContractsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiFlipsContractsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractsEndpointsApi - object-oriented interface
 * @export
 * @class ContractsEndpointsApi
 * @extends {BaseAPI}
 */
export class ContractsEndpointsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsEndpointsApi
     */
    public apiFlipsContractsGet(options?: AxiosRequestConfig) {
        return ContractsEndpointsApiFp(this.configuration).apiFlipsContractsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DepositEndpointDefinitionApi - axios parameter creator
 * @export
 */
export const DepositEndpointDefinitionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} chainId 
         * @param {number} amountUsdCents 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlipsDepositGet: async (chainId: number, amountUsdCents: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chainId' is not null or undefined
            assertParamExists('apiFlipsDepositGet', 'chainId', chainId)
            // verify required parameter 'amountUsdCents' is not null or undefined
            assertParamExists('apiFlipsDepositGet', 'amountUsdCents', amountUsdCents)
            const localVarPath = `/api/flips/deposit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (chainId !== undefined) {
                localVarQueryParameter['chainId'] = chainId;
            }

            if (amountUsdCents !== undefined) {
                localVarQueryParameter['amountUsdCents'] = amountUsdCents;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepositEndpointDefinitionApi - functional programming interface
 * @export
 */
export const DepositEndpointDefinitionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepositEndpointDefinitionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} chainId 
         * @param {number} amountUsdCents 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFlipsDepositGet(chainId: number, amountUsdCents: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepositResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFlipsDepositGet(chainId, amountUsdCents, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DepositEndpointDefinitionApi - factory interface
 * @export
 */
export const DepositEndpointDefinitionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepositEndpointDefinitionApiFp(configuration)
    return {
        /**
         * 
         * @param {number} chainId 
         * @param {number} amountUsdCents 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlipsDepositGet(chainId: number, amountUsdCents: number, options?: any): AxiosPromise<DepositResponse> {
            return localVarFp.apiFlipsDepositGet(chainId, amountUsdCents, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DepositEndpointDefinitionApi - object-oriented interface
 * @export
 * @class DepositEndpointDefinitionApi
 * @extends {BaseAPI}
 */
export class DepositEndpointDefinitionApi extends BaseAPI {
    /**
     * 
     * @param {number} chainId 
     * @param {number} amountUsdCents 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositEndpointDefinitionApi
     */
    public apiFlipsDepositGet(chainId: number, amountUsdCents: number, options?: AxiosRequestConfig) {
        return DepositEndpointDefinitionApiFp(this.configuration).apiFlipsDepositGet(chainId, amountUsdCents, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExchangeRateEndpointsApi - axios parameter creator
 * @export
 */
export const ExchangeRateEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlipsExchangeRatesUsdInTokenGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/flips/exchangeRates/usdInToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlipsExchangeRatesWeiInUsdGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/flips/exchangeRates/weiInUsd`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExchangeRateEndpointsApi - functional programming interface
 * @export
 */
export const ExchangeRateEndpointsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExchangeRateEndpointsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFlipsExchangeRatesUsdInTokenGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFlipsExchangeRatesUsdInTokenGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFlipsExchangeRatesWeiInUsdGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFlipsExchangeRatesWeiInUsdGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExchangeRateEndpointsApi - factory interface
 * @export
 */
export const ExchangeRateEndpointsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExchangeRateEndpointsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlipsExchangeRatesUsdInTokenGet(options?: any): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.apiFlipsExchangeRatesUsdInTokenGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlipsExchangeRatesWeiInUsdGet(options?: any): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.apiFlipsExchangeRatesWeiInUsdGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExchangeRateEndpointsApi - object-oriented interface
 * @export
 * @class ExchangeRateEndpointsApi
 * @extends {BaseAPI}
 */
export class ExchangeRateEndpointsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeRateEndpointsApi
     */
    public apiFlipsExchangeRatesUsdInTokenGet(options?: AxiosRequestConfig) {
        return ExchangeRateEndpointsApiFp(this.configuration).apiFlipsExchangeRatesUsdInTokenGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeRateEndpointsApi
     */
    public apiFlipsExchangeRatesWeiInUsdGet(options?: AxiosRequestConfig) {
        return ExchangeRateEndpointsApiFp(this.configuration).apiFlipsExchangeRatesWeiInUsdGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RecentFlipsEndpointsApi - axios parameter creator
 * @export
 */
export const RecentFlipsEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} count 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlipsRecentFlipsGet: async (count: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'count' is not null or undefined
            assertParamExists('apiFlipsRecentFlipsGet', 'count', count)
            const localVarPath = `/api/flips/recent-flips`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecentFlipsEndpointsApi - functional programming interface
 * @export
 */
export const RecentFlipsEndpointsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecentFlipsEndpointsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} count 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFlipsRecentFlipsGet(count: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecentFlipDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFlipsRecentFlipsGet(count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RecentFlipsEndpointsApi - factory interface
 * @export
 */
export const RecentFlipsEndpointsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecentFlipsEndpointsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} count 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlipsRecentFlipsGet(count: number, options?: any): AxiosPromise<Array<RecentFlipDto>> {
            return localVarFp.apiFlipsRecentFlipsGet(count, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecentFlipsEndpointsApi - object-oriented interface
 * @export
 * @class RecentFlipsEndpointsApi
 * @extends {BaseAPI}
 */
export class RecentFlipsEndpointsApi extends BaseAPI {
    /**
     * 
     * @param {number} count 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentFlipsEndpointsApi
     */
    public apiFlipsRecentFlipsGet(count: number, options?: AxiosRequestConfig) {
        return RecentFlipsEndpointsApiFp(this.configuration).apiFlipsRecentFlipsGet(count, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingsEndpointApi - axios parameter creator
 * @export
 */
export const SettingsEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlipsSettingsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/flips/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsEndpointApi - functional programming interface
 * @export
 */
export const SettingsEndpointApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingsEndpointApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFlipsSettingsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommonSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFlipsSettingsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingsEndpointApi - factory interface
 * @export
 */
export const SettingsEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingsEndpointApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlipsSettingsGet(options?: any): AxiosPromise<CommonSettings> {
            return localVarFp.apiFlipsSettingsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingsEndpointApi - object-oriented interface
 * @export
 * @class SettingsEndpointApi
 * @extends {BaseAPI}
 */
export class SettingsEndpointApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsEndpointApi
     */
    public apiFlipsSettingsGet(options?: AxiosRequestConfig) {
        return SettingsEndpointApiFp(this.configuration).apiFlipsSettingsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StandaloneFlipEndpointsApi - axios parameter creator
 * @export
 */
export const StandaloneFlipEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} chainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlipsStandaloneDoubleOrNothingGet: async (chainId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chainId' is not null or undefined
            assertParamExists('apiFlipsStandaloneDoubleOrNothingGet', 'chainId', chainId)
            const localVarPath = `/api/flips/standalone-double-or-nothing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (chainId !== undefined) {
                localVarQueryParameter['chainId'] = chainId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} chainId 
         * @param {number} amountCents 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlipsStandaloneGet: async (chainId: number, amountCents: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chainId' is not null or undefined
            assertParamExists('apiFlipsStandaloneGet', 'chainId', chainId)
            // verify required parameter 'amountCents' is not null or undefined
            assertParamExists('apiFlipsStandaloneGet', 'amountCents', amountCents)
            const localVarPath = `/api/flips/standalone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (chainId !== undefined) {
                localVarQueryParameter['chainId'] = chainId;
            }

            if (amountCents !== undefined) {
                localVarQueryParameter['amountCents'] = amountCents;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StandaloneFlipEndpointsApi - functional programming interface
 * @export
 */
export const StandaloneFlipEndpointsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StandaloneFlipEndpointsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} chainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFlipsStandaloneDoubleOrNothingGet(chainId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StandaloneFlipResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFlipsStandaloneDoubleOrNothingGet(chainId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} chainId 
         * @param {number} amountCents 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFlipsStandaloneGet(chainId: number, amountCents: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StandaloneFlipResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFlipsStandaloneGet(chainId, amountCents, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StandaloneFlipEndpointsApi - factory interface
 * @export
 */
export const StandaloneFlipEndpointsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StandaloneFlipEndpointsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} chainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlipsStandaloneDoubleOrNothingGet(chainId: number, options?: any): AxiosPromise<StandaloneFlipResponse> {
            return localVarFp.apiFlipsStandaloneDoubleOrNothingGet(chainId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} chainId 
         * @param {number} amountCents 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlipsStandaloneGet(chainId: number, amountCents: number, options?: any): AxiosPromise<StandaloneFlipResponse> {
            return localVarFp.apiFlipsStandaloneGet(chainId, amountCents, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StandaloneFlipEndpointsApi - object-oriented interface
 * @export
 * @class StandaloneFlipEndpointsApi
 * @extends {BaseAPI}
 */
export class StandaloneFlipEndpointsApi extends BaseAPI {
    /**
     * 
     * @param {number} chainId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StandaloneFlipEndpointsApi
     */
    public apiFlipsStandaloneDoubleOrNothingGet(chainId: number, options?: AxiosRequestConfig) {
        return StandaloneFlipEndpointsApiFp(this.configuration).apiFlipsStandaloneDoubleOrNothingGet(chainId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} chainId 
     * @param {number} amountCents 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StandaloneFlipEndpointsApi
     */
    public apiFlipsStandaloneGet(chainId: number, amountCents: number, options?: AxiosRequestConfig) {
        return StandaloneFlipEndpointsApiFp(this.configuration).apiFlipsStandaloneGet(chainId, amountCents, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatisticsEndpointsApi - axios parameter creator
 * @export
 */
export const StatisticsEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlipsStatisticsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/flips/statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatisticsEndpointsApi - functional programming interface
 * @export
 */
export const StatisticsEndpointsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatisticsEndpointsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFlipsStatisticsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatisticsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFlipsStatisticsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatisticsEndpointsApi - factory interface
 * @export
 */
export const StatisticsEndpointsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatisticsEndpointsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlipsStatisticsGet(options?: any): AxiosPromise<StatisticsResponse> {
            return localVarFp.apiFlipsStatisticsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatisticsEndpointsApi - object-oriented interface
 * @export
 * @class StatisticsEndpointsApi
 * @extends {BaseAPI}
 */
export class StatisticsEndpointsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsEndpointsApi
     */
    public apiFlipsStatisticsGet(options?: AxiosRequestConfig) {
        return StatisticsEndpointsApiFp(this.configuration).apiFlipsStatisticsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserProfileDefinitionApi - axios parameter creator
 * @export
 */
export const UserProfileDefinitionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlipsProfileGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/flips/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserProfileDefinitionApi - functional programming interface
 * @export
 */
export const UserProfileDefinitionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserProfileDefinitionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFlipsProfileGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFlipsProfileGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserProfileDefinitionApi - factory interface
 * @export
 */
export const UserProfileDefinitionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserProfileDefinitionApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlipsProfileGet(options?: any): AxiosPromise<UserProfileResponse> {
            return localVarFp.apiFlipsProfileGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserProfileDefinitionApi - object-oriented interface
 * @export
 * @class UserProfileDefinitionApi
 * @extends {BaseAPI}
 */
export class UserProfileDefinitionApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileDefinitionApi
     */
    public apiFlipsProfileGet(options?: AxiosRequestConfig) {
        return UserProfileDefinitionApiFp(this.configuration).apiFlipsProfileGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WithdrawEndpointDefinitionApi - axios parameter creator
 * @export
 */
export const WithdrawEndpointDefinitionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {WithdrawRequest} withdrawRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlipsWithdrawPost: async (withdrawRequest: WithdrawRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'withdrawRequest' is not null or undefined
            assertParamExists('apiFlipsWithdrawPost', 'withdrawRequest', withdrawRequest)
            const localVarPath = `/api/flips/withdraw`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(withdrawRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WithdrawEndpointDefinitionApi - functional programming interface
 * @export
 */
export const WithdrawEndpointDefinitionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WithdrawEndpointDefinitionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {WithdrawRequest} withdrawRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFlipsWithdrawPost(withdrawRequest: WithdrawRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WithdrawResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFlipsWithdrawPost(withdrawRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WithdrawEndpointDefinitionApi - factory interface
 * @export
 */
export const WithdrawEndpointDefinitionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WithdrawEndpointDefinitionApiFp(configuration)
    return {
        /**
         * 
         * @param {WithdrawRequest} withdrawRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlipsWithdrawPost(withdrawRequest: WithdrawRequest, options?: any): AxiosPromise<WithdrawResponse> {
            return localVarFp.apiFlipsWithdrawPost(withdrawRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WithdrawEndpointDefinitionApi - object-oriented interface
 * @export
 * @class WithdrawEndpointDefinitionApi
 * @extends {BaseAPI}
 */
export class WithdrawEndpointDefinitionApi extends BaseAPI {
    /**
     * 
     * @param {WithdrawRequest} withdrawRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawEndpointDefinitionApi
     */
    public apiFlipsWithdrawPost(withdrawRequest: WithdrawRequest, options?: AxiosRequestConfig) {
        return WithdrawEndpointDefinitionApiFp(this.configuration).apiFlipsWithdrawPost(withdrawRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


