//profile.smToVm.ts

import { IProfileData, ProfileData } from "../../../../models/profile";
import { UserProfileResponse } from "../../../../swaggerFlips/src";
import { processError } from "../../../../utils/helpers";
import { ErrorEnum } from "../../../../view/react/core/models";

export const profileSmToVm = (t?: UserProfileResponse): IProfileData => {
  try {
    return convert(t);
  }
  catch (error: any) {
    throw processError(
      ErrorEnum.Mapping,
      "profileSmToVm(). Error",
      error
    );
  }

  function convert(t?: UserProfileResponse): IProfileData {
    return new ProfileData(
      undefined,
      t?.address,
      t?.balances,
      t?.bonusCents,
      t?.isBlocked,
    );
  }
};
