import React, { useEffect, useRef, useState } from "react";
import bull from "../../template/img/bull.png";
import bear from "../../template/img/bear.png";
import { StatisticsApi } from "../../core/xhr/api/statistics.api";
import { RecentFlipDto, StatisticsResponse } from "../../core/swaggerFlips/src";
import { RecentFlipsApi } from "../../core/xhr/api/recentFlips.api";
import ConnectorStats from "./ConnectorStats";
import { momentAgo } from "../../core/utils/helpers/momentAgo";
import { roundUp } from "../../core/utils/helpers";

enum IStateList {
  recentPlays = "recentPlays",
  stats = "stats"
}


const Stats = () => {
  const [stateList, setStateList] = useState<IStateList>(IStateList.recentPlays);
  const [statistic, setStatistic] = useState<StatisticsResponse | null>(null);
  const [recentFlips, setRecentFlips] = useState<Array<RecentFlipDto> | null>(null)
  const refCircle = useRef(null);

  const { events } = ConnectorStats();

  useEffect(()=>{
    const  handleFlip = async (payload: RecentFlipDto) => {
      if (recentFlips) {
        const newArray = recentFlips;
        newArray.splice(0,0,payload);
        setRecentFlips(newArray.filter((item:RecentFlipDto, index)=> index < 10));
      }
    }
    events(handleFlip);
  },[recentFlips])

  const radius = 60;
  const max = 100;

  function run(
    winningResult: number,
    refCircle: any
  ) {
    let angle = winningResult * 365 / max || 0;
    const circumference = 2 * Math.PI * radius;
    const strokeOffset = (1 / 4) * circumference;
    const strokeDasharray = (angle / 365) * circumference;
    refCircle.current.setAttribute(
      "r",
      radius
    );
    refCircle.current.setAttribute(
      "stroke-dasharray",
      [
        strokeDasharray,
        circumference - strokeDasharray
      ]
    );
    refCircle.current.setAttribute(
      "stroke-dashoffset",
      strokeOffset
    );
  }

  useEffect(
    () => {
      if (refCircle !== null && statistic !== null && statistic.evenOddWinPercentage !== undefined) {
        run(
          statistic.evenOddWinPercentage,
          refCircle
        );
      }
    },
    [refCircle, statistic]
  );

  useEffect(
    () => {
      (async () => {
        const statisticsData = await StatisticsApi.Statistics();
        if (statisticsData) setStatistic(statisticsData);
        const recentFlipsData = await RecentFlipsApi.RecentFlips()
        if (recentFlipsData) setRecentFlips(recentFlipsData);
      })();
    },
    []
  );

  useEffect(
    () => {
      (async () => {
        setInterval(
          async () => {
            const statisticsData = await StatisticsApi.Statistics();
            if (statisticsData) setStatistic(statisticsData);
          },
          3 * 60 * 1000 //3 min update
        );
      })();
    },
    []
  );

  return (
    <div className="container-sm">
      <div className="tabs-btn">
        <a className={`title${stateList === IStateList.recentPlays? " active" : ""}`} data-id="1"
           onClick={()=>setStateList(IStateList.recentPlays)}>
          RECENT PLAYS
        </a>
        <a className={`title${stateList === IStateList.stats? " active" : ""} `} data-id="2"
           onClick={()=>setStateList(IStateList.stats)} >Stats</a>
      </div>
      <div className="tabs-container">
        <div className={`tabs tabs-1${stateList === IStateList.recentPlays? " active" : ""}`}>
          <div className="lists">
            {recentFlips &&
            recentFlips.map((item) =>{
              return (
                <div className={`item${item.winningsCents && item.winningsCents >= 2500? " active" : ""}`} key={item.id}>
                  <img src={item.isEven? bear: bull} alt="" className={item.isEven? "bear" : "bull"} />
                  <div>
                    <div className="text text-wallet">{item.player?.slice(
                      0,
                      6
                    )}...{item.player?.slice(-4)}</div>
                    <div className="text ">flipped <span className="numbersCustomStats">
                      ${item.betCents ? roundUp(item.betCents / 100) : 0}
                    </span>
                    </div>
                  </div>
                  <div>
                    {item.hasWon?
                      <div className="text-won ">WON ≈ <span className="numbersCustomStats">
                        ${item.winningsCents ? roundUp(item.winningsCents / 100) : 0}
                      </span>
                      </div>
                      :
                      <div className="text-lost">LOST ≈ <span className="numbersCustomStats">
                        ${item.betCents ? roundUp(item.betCents / 100) : 0}
                      </span>
                      </div>
                    }
                    <div className="text-time">
                      {item.updatedAt && momentAgo(item.updatedAt)}
                    </div>
                  </div>
                </div>
              )
            })}

          </div>
        </div>
        <div className={`tabs tabs-2${stateList === IStateList.stats? " active" : ""}`}>
          <div className="stats">
            <div className="i-1 stat-bg-1">
              <div className="title">Winning result</div>
              <div className={`round${!statistic?.hasEvenWonMore? " bull":""}`}>
                <div>
                  <div className="data-range">{statistic?.evenOddWinPercentage?.toFixed(0)}</div>
                  <div className="data-text">{statistic?.hasEvenWonMore? "Bear" : "Bull"}</div>
                </div>
                <svg>
                  <circle ref={refCircle} cx="65" cy="65" r="60" />
                </svg>
              </div>
            </div>
            <div className="i-2 stat-bg-2">
              <div className="title">Most chosen</div>
              <div className="value">{statistic?.mostSelectedIsEven? "Bear" : "Bull"}</div>
            </div>
            <div className="i-2 stat-bg-2">
              <div className="title">Average flip size</div>
              <div className="value">${statistic?.averageBetSize?.toFixed(0)}</div>
            </div>
            <div className="i-2 stat-bg-2">
              <div className="title">Highest win streak</div>
              <div className="text">
                {statistic?.highestWinStreakAddress?.slice(
                  0,
                  6
                )}***{statistic?.highestWinStreakAddress?.slice(-4)}
                </div>
              <div className="value">{statistic?.highestWinStreak}</div>
            </div>
            <div className="i-2 stat-bg-2">
              <div className="title">Percentage of winners</div>
              <div className="value">{statistic?.globalWinPercentage?.toFixed(0)}<sup>%</sup></div>
            </div>
            <div className="i-2 stat-bg-2">
              <div className="title">Total flips</div>
              <div className="value">{statistic?.totalFlips}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Stats;