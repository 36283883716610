//api.settings.ts

import { addressBaseURL, prod } from "../../settings/common.settings";

export const baseUrl: string = addressBaseURL
// (prod? "https://gamerfi.pro" : "https://gamerfi.esas.by");
//export const baseUrl: string = "https://gamerfi.esas.by";

export const apiSettings = {
  baseUrl,
  headers: {
    apiVersion: {
      name: "x-api-version",
      value: "1.0"
    }
  }
};