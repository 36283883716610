//xhrRequest.model.ts

import { Error } from "../../view/react/core/models";

export interface IXhrRequest {
  error: Error;
  func: Function;
  _retry: boolean;
  _queued: boolean;
  ignoreLogInJump: boolean;
}

export class XhrRequest implements IXhrRequest {
  public error: Error;
  public func: Function;
  public _retry: boolean;
  public _queued: boolean;
  public ignoreLogInJump: boolean;

  constructor(
    error: Error,
    func: Function,
    _retry?: boolean,
    _queued?: boolean,
    ignoreLogInJump?: boolean
  ) {
    this.error = error;
    this.func = func;
    this._retry = _retry || false;
    this._queued = _queued || false;
    this.ignoreLogInJump = ignoreLogInJump || false;
  }
}