import * as signalR from "@microsoft/signalr";
import { RecentFlipDto } from "../../core/swaggerFlips/src";
import { baseUrl } from "../../core/xhr/api/api.settings";


const URL = baseUrl + "/api/flips/recent-flips-hub"; //or whatever your backend port is

class ConnectorStats {
  private connection: signalR.HubConnection;

  public events: (
    NewFlip: (payload: RecentFlipDto) => void,
  ) => void;
  static instance: ConnectorStats;

  constructor() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(URL)
      .withAutomaticReconnect()
      .build();
    this.connection.start()
      .catch(err => document.write(err));
    this.events = (NewFlip) => {
      this.connection.on(
        "NewFlip",
        (payload) => {
          NewFlip(payload);
        }
      );
    };
  }
  public static getInstance(): ConnectorStats {
    if (!ConnectorStats.instance) {
      ConnectorStats.instance = new ConnectorStats();
    }
    return ConnectorStats.instance;
  }
}

export default ConnectorStats.getInstance;