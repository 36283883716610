//storage.service.ts

import { isObject } from "../../utils";

export enum StorageTypesEnum {
  Local = "Local",
  Session = "Session",
  Cookie = "Cookie"
}

export const storagePrefix: string = "v1_nft_gamerFi_";

export const get = (
  key: string,
  storageType: StorageTypesEnum
): any => {
  let value = null;

  switch (storageType) {
    case StorageTypesEnum.Local:
      value = localStorage.getItem(key);
      break;
    case StorageTypesEnum.Session:
      value = sessionStorage.getItem(key);
      break;
    default:
      break;
  }

  if (value) {
    try {
      return JSON.parse(value);
    }
    catch (err) {
      return value;
    }
  } else {
    return value;
  }
};

export const set = (
  key: string,
  object: any,
  storageType: StorageTypesEnum
): void => {
  remove(
    key,
    storageType
  );

  const strObject = isObject(object) ?
    JSON.stringify(object) :
    object;
  switch (storageType) {
    case StorageTypesEnum.Local:
      localStorage.setItem(
        key,
        strObject
      );
      break;
    case StorageTypesEnum.Session:
      sessionStorage.setItem(
        key,
        strObject
      );
      break;
    default:
      break;
  }
};

export const remove = (
  key: string,
  storageType: StorageTypesEnum
): void => {
  switch (storageType) {
    case StorageTypesEnum.Local:
      localStorage.removeItem(key);
      break;
    case StorageTypesEnum.Session:
      sessionStorage.removeItem(key);
      break;
    default:
      break;
  }
};