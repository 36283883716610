import React, { FC } from "react";
import close from "../../template/img/close.svg";
import logo from "../../template/img/logo.png";
import metamask from "../../template/img/metamask.svg";
import coinbase from "../../template/img/coinbase.svg";
import walletConnect from "../../template/img/wallet-connect.svg";
import { mobileCheck } from "../../core/utils/helpers";
import { addressBaseURL, prod } from "../../core/settings/common.settings";
import { baseUrl } from "../../core/xhr/api/api.settings";

interface IConnectWallet {
  openConnectWallet: boolean;
  setOpenConnectWalletCallback: (param: boolean) => void;
  connect: (connectorId: string) => void
}

const ConnectWallet:FC<IConnectWallet> = ({
  openConnectWallet,
  setOpenConnectWalletCallback,
  connect,
  }) => {

  return (
    <div className={`modal-bg${openConnectWallet ? " show" : ""}`}>
      <div className={`modal${openConnectWallet ? " show" : ""}`} id="modal-1">
        <a className="close modal-close" onClick={() => setOpenConnectWalletCallback(false)}>
          <img src={close} alt="" />
        </a>
        <div className="content htp2">
          <div className="rw mt-30 mb-30 align-items-center">
            <div className="c-m-4 p-5 htp5">
              <img src={logo} alt="" />
            </div>
            <div className="c-m-7">
              <div className="htp-title mb-30">Connect your wallet</div>
              <a className="btn-connect mb-15"
                 onClick={async () => {
                   if (mobileCheck()) {
                      if (window.ethereum !== undefined ) {
                        await connect(window.WalletEnvLib.getSettings().supportedWalletConnectors[0]);
                      }
                      else {
                        window.location.href = "https://metamask.app.link/dapp/"+ addressBaseURL.slice(7, addressBaseURL.length)+ "/?withMetamask=true";
                      }
                   }
                   else {
                     await connect(window.WalletEnvLib.getSettings().supportedWalletConnectors[0]);
                   }
                 }}>
                <img src={metamask} alt="" />
                metamask</a>
              <a className="btn-connect mb-15"
                 onClick={async () => {
                   await connect(window.WalletEnvLib.getSettings().supportedWalletConnectors[1]);
                 }}>
                <img src={coinbase} alt="" />
                  coinbase wallet</a>
              <a className="btn-connect mb-15"
                 onClick={async () => {
                   await connect(window.WalletEnvLib.getSettings().supportedWalletConnectors[3]);
                 }}>
                <img src={walletConnect} alt="" />
                wallet connect</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConnectWallet