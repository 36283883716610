import * as signalR from "@microsoft/signalr";
import { StorageService } from "../../core/services";
import { IBullState } from "../../core/models";
import { baseUrl } from "../../core/xhr/api/api.settings";

const URL = baseUrl + "/api/flips/hub?access_token="; //or whatever your backend port is

export interface IFlipStatus {
  "status": IStatusFlip,
  "isEven": boolean,
  "winningsWei": number,
  "winningsCents": number,
  "transactionHash": string,
  "error": string,
}

export enum IStatusFlip {
  Pending = "Pending",
  Won = "Won",
  Lost = "Lost",
  Error = "Error",
  Failed = "Failed"
}
class Connector {
  public connection: signalR.HubConnection;
  public events: (
    onFlip: (payload: IFlipStatus) => void,
  ) => void;
  static instance: Connector;

  constructor() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(URL + StorageService.auth.get().accessToken)
      .withAutomaticReconnect()
      .build();
    this.connection.start()
      .catch(err => document.write(err));
    this.events = ( onFlip ) => {
      this.connection.on("FlipStatus", (payload) => {
        onFlip(payload)
      });
    };
  }
  public Flip = (betCents: number, bullState: IBullState, signature: string, chain:number) => {

    try {
      this.connection.send(
        "Flip",
        {
          "isEven": bullState === IBullState.Bull? false : true,
          "betCents": +(betCents * 100).toFixed(0),   //comes in dollars and should be in cents
          "chainId": chain,
          "signature": signature
        }
      );
    } catch (e) {
    }

  }

  public DoubleOrNothing = (bullState: IBullState, signature: string, chain:number) => {
    this.connection.send(
      "DoubleOrNothing",
      {
        "isEven": bullState === IBullState.Bull? false : true,
        "chainId": chain,
        "signature": signature
      }
    )
  }
  public static getInstance(): Connector {
    if (!Connector.instance) {
      Connector.instance = new Connector();
    }
    return Connector.instance;
  }
}

export default Connector.getInstance;