import React, {useEffect, useState} from "react";

import {StorageService} from "./core/services";
import {IdentityService} from "./core/xhr/identity";
import {checkNetwork} from "./core/services/checkNetwork";
import {IAuthData, IProfileData, AccountData, IAccountData, IBullState, IStateScreen} from "./core/models";
import {ProfileAPI} from "./core/xhr/api/profile.api";
import {
    bnbId, bnbTestId, ethereumId, max, maxWin, min, mumbaiId,
    polygonId, prod, sepoliaId, startPrc, startRange
} from "./core/settings/common.settings";
import Nav from "./view/nav/Nav";
import Footer from "./view/footer/Footer";
import FaqModal from "./view/modal/FaqModal";
import Play from "./view/play/Play";
import HowToPlayModal from "./view/modal/HowToPlayModal";
import ProvablyFairModal from "./view/modal/ProvablyFairModal";
import DepositWithdraw from "./view/depositWithdrawn/DepositWithdraw";
import Stats from "./view/stats/Stats";
import ConnectWallet from "./view/modal/ConnectWallet";
import ProcessingModal from "./view/modal/ProcessingModal";
import ErrorModal from "./view/modal/ErrorModal";
import ChoiceModal from "./view/modal/ChoiceModal";
import PlayPay from "./view/play/PlayPay";
import WrongNetworkModal from "./view/modal/WrongNetworkModal";
import ChoiceAfterFreePlayModal from "./view/modal/ChoiceAfterFreePlayModal";
import InsufficientFundsModal from "./view/modal/InsufficientFundsModal";
import Loading from "./view/loading/Loading";
import HowToVerifyModal from "./view/modal/HowToVerifyModal";
import audioS1 from "./template/sound/s1.mp3";
import audioS2 from "./template/sound/s2.mp3";
import audioS3 from "./template/sound/s3.mp3";
import audioS4 from "./template/sound/s4.mp3";
import {SettingsApi} from "./core/xhr/api/settings.api";
import {mobileCheck} from "./core/utils/helpers";
import {useDispatch, useSelector} from "react-redux";
import {store, useAppSelector} from "./core/stores/store";
import {setGlobalSettings} from "./core/stores/globalSettingsSlice";
import { setAccountData, setTestTest } from "./core/stores/accountSlice";


declare global {
    interface Window {
        gmrf: {
            settings: {
                api: {
                    baseUrl: string
                },
                router: {
                    baseHref: string
                }
            }
        },
        WalletEnvLib: any;
        ethereum: any;
    }
}

const App = () => {
    const [openConnectWallet, setOpenConnectWallet] = useState(false);
    const [openHowToPlay, setOpenHowToPlay] = useState(false);
    const [openFaq, setOpenFaq] = useState(false);
    const [openProvablyFair, setOpenProvablyFair] = useState(false);
    const [openHowToVerify, setOpenHowToVerify] = useState(false);
    const [openProcessing, setOpenProcessing] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [openChoice, setOpenChoice] = useState(false);
    const [openChoiceAfterFreePlayModal, setOpenChoiceAfterFreePlayModal] = useState(false);
    const [openWrongNetwork, setOpenWrongNetwork] = useState(false);
    const [openInsufficientFundsModal, setOpenInsufficientFundsModal] = useState(false);
    const [stateScreen, setStateScreen] = useState<IStateScreen>(IStateScreen.PlayInitial);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("Something went wrong");
    const [noAuthChoiceMod, setNoAuthChoiceMod] = useState<IStateScreen>(IStateScreen.PlayInitial);
    const [bullState, setBullState] = useState<IBullState>(IBullState.Initial);
    const [rangeState, setRangeState] = useState<number>(startRange);
    const [prc, setPrc] = useState<number>(startPrc);
    const [xr, setXr] = useState<number>(0);

    const settings = useAppSelector(state => state.globalSettings.settings)
    const account = useAppSelector(state => state.account.data)
    const testTest = useAppSelector(state => state.account.data.testTest);
    const dispatch = useDispatch()
    //console.log("account:", account)


    const [pushSound] = useState(new Audio(audioS1));
    const [winSound] = useState(new Audio(audioS2));
    const [upSound] = useState(new Audio(audioS3));
    const [loseSound] = useState(new Audio(audioS4));

    const setRangeStateCallback = (param: number) => {
        return setRangeState(param);
    };
    const setXrCallback = (param: number) => {
        return setXr(param);
    };
    const setBullStateCallback = (param: IBullState) => {
        return setBullState(param);
    };
    const setPrcCallback = (param: number) => {
        return setPrc(param);
    };
    const setNoAuthChoiceModCallback = (param: IStateScreen) => {
        return setNoAuthChoiceMod(param)
    }
    const setOpenConnectWalletCallback = (param: boolean) => {
        return setOpenConnectWallet(param);
    };
    const setOpenHowToPlayCallback = (param: boolean) => {
        return setOpenHowToPlay(param);
    };
    const setOpenFaqCallback = (param: boolean) => {
        return setOpenFaq(param);
    };
    const setOpenProvablyFairCallback = (param: boolean) => {
        return setOpenProvablyFair(param);
    };
    const setOpenHowToVerifyCallback = (param: boolean) => {
        return setOpenHowToVerify(param);
    };
    const setOpenErrorCallback = (param: boolean) => {
        return setOpenError(param);
    };
    const setOpenChoiceCallback = (param: boolean) => {
        return setOpenChoice(param);
    };
    const setOpenChoiceAfterFreePlayModalCallback = (param: boolean) => {
        return setOpenChoiceAfterFreePlayModal(param);
    };
    const setOpenWrongNetworkCallback = (param: boolean) => {
        return setOpenWrongNetwork(param);
    };
    const setOpenInsufficientFundsModalCallback = (param: boolean) => {
        return setOpenInsufficientFundsModal(param);
    };
    const setOpenProcessingCallback = (param: boolean) => {
        return setOpenProcessing(param);
    };
    const setStateScreenCallback = (param: IStateScreen) => {
        return setStateScreen(param);
    };
    // const setProfileCallback = (prevState: (prevState: IAccountData) => IAccountData) => {
    //     dispatch(setAccountData(prevState))
    // };
    const setMessageCallback = (param: string) => {
        return setMessage(param);
    }

    // useEffect(()=>{
    //   pushSound.load()
    //   winSound.load()
    //   upSound.load()
    //   loseSound.load()
    // },[])

    useEffect(
        () => {
            (async () => {
                try {
                    const data = await SettingsApi.getSettings();
                    if (data) {
                        console.log("settings:", data)
                        dispatch(setGlobalSettings(data))
                        if (!account.isAuthorized) return;
                        dispatch(setAccountData({
                            ...account,
                            isTrueNetwork: checkNetwork(account.walletData.network.id, data)
                        }));
                    }
                } catch (e) {
                }
            })();
        },
        []
    );


    useEffect(
        () => {
            if (!window.gmrf) {
                window.gmrf = {
                    settings: {
                        api: {
                            baseUrl: ""
                        },
                        router: {
                            baseHref: ""
                        }
                    }
                };
            }
        },
        []
    );

    // Query parameters parser
    useEffect(() => {
        const params = {};
        if (window.location.href.match(/.*\?.*/)) {
            for (let i = 0; i < 1; i++) {
                const _tmp = window.location.href.replace(/.*\?/, '')
                    .split('&')[i]
                    .split('=');
                // @ts-ignore
                params[_tmp[0]] = _tmp[1];
            }
        }
        (async () => {
            // @ts-ignore
            const withMetamask = params.withMetamask && params.withMetamask === "true" ? true : false;
            if (withMetamask) {
                await connect(window.WalletEnvLib.getSettings().supportedWalletConnectors[0])
            }
        })()
    }, [])

    // Play mode switch on account authorization
    useEffect(
        () => {
            const mode = StorageService.mode.get();
            if (account.isAuthorized) {
                if (mode && mode === IStateScreen.PlayFree) {
                    setStateScreen(IStateScreen.PlayFree);
                } else if (mode && mode === IStateScreen.Play) {
                    setStateScreen(IStateScreen.Play);
                } else if (mode && mode === IStateScreen.PlayToWallet) {
                    setStateScreen(IStateScreen.PlayToWallet);
                } else {
                    setStateScreen(IStateScreen.PlayInitial);
                }
            } else {
                if (!mode || (mode && mode === IStateScreen.Play) || (mode && mode === IStateScreen.PlayToWallet)) {
                    setStateScreen(IStateScreen.PlayInitial);
                } else if (mode && mode === IStateScreen.PlayFree) {
                    setStateScreen(IStateScreen.PlayFree);
                }
            }
        },
        [account.isAuthorized]
    );

    // WalletEnvLib initialization
    useEffect(() => {
            (async () => {
                if (!window.WalletEnvLib) return;

                await window.WalletEnvLib.init({
                    chains: [
                        ethereumId,
                        bnbId,
                        polygonId,
                        sepoliaId,
                        bnbTestId,
                        mumbaiId
                    ],
                    infura: {
                        apiKey: "5dc554393fa4428882391358c2c20119" //TestGamerFi API Key
                        //apiKey: ""
                    },
                    coinbase: {
                        appName: "wagmi"
                    },
                    walletConnect: {
                        projectId: "dc4fdcd7ec7f23f3bbefd2aed0d88c41"
                    },
                    useTraceErrors: prod, //isProduction
                    useFetchBalance: false
                });

                // We can't use store from hooks here because it gets saved in lambda closure
                await window.WalletEnvLib.walletConnectWithAuth.init(
                    {
                        set: (authData: IAuthData) => {
                            console.log("method 1 set")
                            StorageService.auth.set(authData);
                        },
                        remove: () => {
                            console.log("method 1 remove")
                            StorageService.auth.remove();
                        }
                    },
                    async (walletAddress: string): Promise<{
                        walletAddress: string,
                        profileData: IProfileData
                    }> => {
                        console.log("method 2")
                        const profile = await ProfileAPI.getProfile(
                            //walletAddress,
                            true
                        );
                        return {
                            profileData: profile,
                            walletAddress: walletAddress
                        };
                    },
                    (
                        accountData: any,
                        profile: IProfileData
                    ) => {
                        console.log("method 3")
                        const settings = store.getState().globalSettings.settings;
                        dispatch(setAccountData(new AccountData(
                            accountData.isAuthorized,
                            checkNetwork(accountData.walletData.network.id, settings),
                            profile,
                            accountData.walletData
                        )))
                    },
                    () => {
                        console.log("method 4")
                        //navigation.goToReturnUrlAfterLogin();
                    },
                    () => {
                        console.log("method 5")
                        window.location.reload();
                    },
                    () => {
                        console.log("method 6")
                        //alert('start!');
                        // startup();
                    },
                    (data: any) => {
                        console.log("method 7")
                        const account = store.getState().account.data;
                        const settings = store.getState().globalSettings.settings;
                        console.log("dispatching new account data from wallet connect env lib with existing account ", account)
                        dispatch(setAccountData({
                                ...account,
                                walletData: data.walletData,
                                isTrueNetwork: checkNetwork(data.walletData.network.id, settings)
                            } as IAccountData
                        ))
                        // setProfile((prevState) => ({
                        //     ...prevState,
                        //     walletData: accountData.walletData,
                        //     isTrueNetwork: checkNetwork(accountData.walletData.network.id, settings)
                        // }));
                        //accountWalletDataSet(accountData.walletData);
                    },
                    {
                        set: (): boolean => {
                            return IdentityService.setUserPermissions();
                        },
                        reset: () => {
                            IdentityService.resetUserPermissions();
                        }
                    }
                );
            })();
        },
        [window.WalletEnvLib]
    );

    useEffect(
      () => {
          if (document.readyState == "complete") {  // Check if the page has already loaded
              setLoading(false);
          }
          else {
              window.addEventListener("load", () => {setLoading(false);});
              return () => window.removeEventListener('load', ()=>{setLoading(false);});
          }
      }, []);

    const connect = async (connectorId: string) => {
        setOpenConnectWallet(false);
        try {
            await IdentityService.logIn(connectorId);
            const profile = await ProfileAPI.getProfile(
                true
            );
            if (noAuthChoiceMod === IStateScreen.Play) {
                StorageService.mode.set(IStateScreen.Play);
                setStateScreen(IStateScreen.Play);
            }
            if (noAuthChoiceMod === IStateScreen.PlayToWallet) {
                StorageService.mode.set(IStateScreen.PlayToWallet);
                setStateScreen(IStateScreen.PlayToWallet);
            }
            const libAccount = window.WalletEnvLib.walletConnectWithAuth.getAccountData()
            const accountData = {
                isAuthorized: true,
                isTrueNetwork: checkNetwork(libAccount.walletData.network.id, settings),
                walletData: libAccount.walletData,
                profile: profile
            } as IAccountData
            dispatch(setAccountData(accountData))
            // const accountData = await IdentityService.getAccountData(settings);
            // dispatch(setGlobalAccount(new AccountData(
            //     true,
            //     accountData.isTrueNetwork,
            //     profile,
            //     accountData.walletData,
            // )))
            // setProfile((prevState) => new AccountData(
            //     true,
            //     accountData.isTrueNetwork,
            //     profile,
            //     accountData.walletData,
            // ));
        } catch (error: any) {
            await IdentityService.logOut(false)
            setOpenError(true);
        } finally {

        }
    };

    return (
        <>
            <Loading loading={loading}/>
            <Nav
                setOpenConnectWalletCallback={setOpenConnectWalletCallback}
                setOpenHowToPlayCallback={setOpenHowToPlayCallback}
                setOpenFaqCallback={setOpenFaqCallback}
                setOpenProvablyFairCallback={setOpenProvablyFairCallback}
                setOpenHowToVerify={setOpenHowToVerify}
                setStateScreenCallback={setStateScreenCallback}
                stateScreen={stateScreen}
                // profile={account}
                // setProfileCallback={setProfileCallback}
                setOpenWrongNetworkCallback={setOpenWrongNetworkCallback}
                pushSound={pushSound}
                setNoAuthChoiceModCallback={setNoAuthChoiceModCallback}
            />
            {/*<div>testTest*/}
            {/*{testTest? ' true': ' false'}*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*    <button style={{zIndex:99}} onClick={()=>dispatch(setTestTest(true))}>true</button>*/}
            {/*    <button style={{zIndex:99}} onClick={()=>dispatch(setTestTest(false))}>false</button>*/}
            {/*</div>*/}
            {(stateScreen === IStateScreen.Deposit || stateScreen === IStateScreen.Withdraw) &&
                <DepositWithdraw setStateScreenCallback={setStateScreenCallback}
                                 setOpenProcessingCallback={setOpenProcessingCallback}
                                 setOpenErrorCallback={setOpenErrorCallback}
                                 setMessageCallback={setMessageCallback}
                                 setOpenWrongNetworkCallback={setOpenWrongNetworkCallback}
                                 stateScreen={stateScreen}
                                 // profile={account}
                                 pushSound={pushSound}
                />}
            {(stateScreen === IStateScreen.PlayFree || stateScreen === IStateScreen.PlayInitial) && !loading && //&& (freePlay === true || freePlay === null) &&
                <Play rangeState={rangeState} xr={xr} setXrCallback={setXrCallback} bullState={bullState} prc={prc}
                      stateScreen={stateScreen}
                      setRangeStateCallback={setRangeStateCallback}
                      setBullStateCallback={setBullStateCallback}
                      setPrcCallback={setPrcCallback}
                      setOpenChoiceCallback={setOpenChoiceCallback}
                      setOpenChoiceAfterFreePlayModalCallback={setOpenChoiceAfterFreePlayModalCallback}
                      pushSound={pushSound} upSound={upSound} winSound={winSound} loseSound={loseSound}
                />
            }
            {(stateScreen === IStateScreen.Play || stateScreen === IStateScreen.PlayToWallet) && !loading &&
                <PlayPay rangeState={rangeState} bullState={bullState} prc={prc}
                         stateScreen={stateScreen}
                         networkId={account.walletData.network.id}
                         // profile={account}
                         setOpenWrongNetworkCallback={setOpenWrongNetworkCallback}
                         setRangeStateCallback={setRangeStateCallback}
                         setBullStateCallback={setBullStateCallback}
                         setPrcCallback={setPrcCallback}
                         setOpenProcessingCallback={setOpenProcessingCallback}
                         setOpenErrorCallback={setOpenErrorCallback}
                         setMessageCallback={setMessageCallback}
                         setOpenInsufficientFundsModalCallback={setOpenInsufficientFundsModalCallback}
                         pushSound={pushSound} upSound={upSound} winSound={winSound} loseSound={loseSound}
                />
            }
            <div className="b-2">
                <Stats/>
            </div>
            <ConnectWallet openConnectWallet={openConnectWallet}
                           setOpenConnectWalletCallback={setOpenConnectWalletCallback}
                           connect={connect}/>
            <HowToPlayModal openHowToPlay={openHowToPlay} setOpenHowToPlayCallback={setOpenHowToPlayCallback}
                            pushSound={pushSound} />
            <FaqModal openFaq={openFaq} setOpenFaqCallback={setOpenFaqCallback}
                      setOpenHowToVerifyCallback={setOpenHowToVerifyCallback}
                      setOpenProvablyFairCallback={setOpenProvablyFairCallback}
                      setStateScreenCallback={setStateScreenCallback}
                      pushSound={pushSound} />
            <ProvablyFairModal openProvablyFair={openProvablyFair}
                               setOpenHowToVerifyCallback={setOpenHowToVerifyCallback}
                               setOpenProvablyFairCallback={setOpenProvablyFairCallback} pushSound={pushSound} />
            <HowToVerifyModal
              openHowToVerify={openHowToVerify} setOpenHowToVerifyCallback={setOpenHowToVerifyCallback}
                              pushSound={pushSound}/>
            <ProcessingModal openProcessing={openProcessing}/>
            <ErrorModal openError={openError} setOpenErrorCallback={setOpenErrorCallback} message={message}/>
            <ChoiceModal openChoice={openChoice}
                         // isAuthorized={account.isAuthorized}
                         pushSound={pushSound}
                         setNoAuthChoiceModCallback={setNoAuthChoiceModCallback}
                         setOpenChoiceCallback={setOpenChoiceCallback}
                         setOpenConnectWalletCallback={setOpenConnectWalletCallback}
                         setStateScreenCallback={setStateScreenCallback}/>
            <ChoiceAfterFreePlayModal openChoiceAfterFreePlayModal={openChoiceAfterFreePlayModal}
                                      // profile={account}
                                      pushSound={pushSound}
                                      setOpenChoiceAfterFreePlayModalCallback={setOpenChoiceAfterFreePlayModalCallback}
                                      setOpenConnectWalletCallback={setOpenConnectWalletCallback}
                                      setStateScreenCallback={setStateScreenCallback}
                                      setNoAuthChoiceModCallback={setNoAuthChoiceModCallback}/>
            <WrongNetworkModal openWrongNetwork={openWrongNetwork} pushSound={pushSound}
                               setOpenWrongNetworkCallback={setOpenWrongNetworkCallback}/>
            <InsufficientFundsModal openInsufficientFundsModal={openInsufficientFundsModal} pushSound={pushSound}
                                    setOpenInsufficientFundsModalCallback={setOpenInsufficientFundsModalCallback}
                                    setStateScreenCallback={setStateScreenCallback}/>
            <Footer
                stateScreen={stateScreen}
                // isAuthorized={account.isAuthorized}
                pushSound={pushSound}
                setOpenHowToPlayCallback={setOpenHowToPlayCallback}
                setOpenFaqCallback={setOpenFaqCallback}
                setOpenProvablyFairCallback={setOpenProvablyFairCallback}
                setOpenHowToVerifyCallback={setOpenHowToVerifyCallback}
                setStateScreenCallback={setStateScreenCallback}
                setOpenConnectWalletCallback={setOpenConnectWalletCallback}
                // setProfileCallback={setProfileCallback}
                setOpenWrongNetworkCallback={setOpenWrongNetworkCallback}
                networkId={account.walletData.network.id ? account.walletData.network.id : 0}
            />
        </>
    );
};

export default App;