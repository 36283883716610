import React, {FC, useEffect, useRef, useState} from "react";
import {max, maxTime, min, startRange, step} from "../../core/settings/common.settings";
import {calculationAmount, getBalance} from "../../core/services";
import {IAccountData, IBullState, IStateScreen} from "../../core/models";
import {StandAloneFlipApi} from "../../core/xhr/api/standAloneFlip.api";
import Connector, {IFlipStatus, IStatusFlip} from "./Connector";
import minus from "../../template/img/minus.svg";
import plus from "../../template/img/plus.svg";
import timer2 from "../../template/img/timer-2.svg";
import WinPay from "./WinPay";
import SpinningCoin from "./SpinningCoin";
import TimeIsUpPay from "./TimeIsUpPay";
import {IChainSettings} from "../../core/models";
import {calculateSliderPercent} from "../../core/utils/betSlider.util";
import LostPay from "./LostPay";
import {useAppSelector} from "../../core/stores/store";


interface IPlayPay {
    rangeState: number;
    bullState: IBullState;
    prc: number;
    stateScreen: IStateScreen
    networkId: number
    // profile: IAccountData
    setRangeStateCallback: (param: number) => void
    setBullStateCallback: (param: IBullState) => void
    setPrcCallback: (param: number) => void
    setOpenProcessingCallback: (param: boolean) => void;
    setOpenWrongNetworkCallback: (param: boolean) => void;
    setOpenErrorCallback: (param: boolean) => void;
    setMessageCallback: (param: string) => void
    setOpenInsufficientFundsModalCallback: (param: boolean) => void;
    pushSound: HTMLAudioElement
    winSound: HTMLAudioElement
    upSound: HTMLAudioElement
    loseSound: HTMLAudioElement
}

const PlayPay: FC<IPlayPay> = ({
                                   rangeState,
                                   bullState,
                                   prc,
                                   stateScreen,
                                   networkId,
                                   // profile,
                                   setRangeStateCallback,
                                   setBullStateCallback,
                                   setPrcCallback,
                                   setOpenProcessingCallback,
                                   setOpenErrorCallback,
                                   setMessageCallback,
                                   setOpenWrongNetworkCallback,
                                   setOpenInsufficientFundsModalCallback,
                                   pushSound,
                                   winSound,
                                   upSound,
                                   loseSound
                               }) => {

    const [flip, setFlip] = useState<boolean>(false);
    const [win, setWin] = useState<boolean | null>(null);
    const [doublePlay, setDoublePlay] = useState(false);
    const [startTimer, setStartTimer] = useState<boolean | null>(null)
    const [startTimer2, setStartTimer2] = useState<boolean | null>(null)
    const [amountTimer2, setAmountTimer2] = useState<number>(maxTime);
    const [spin, setSpin] = useState<boolean | undefined | null>(undefined);
    const [showMessage, setShowMessage] = useState(false);
    const [transactionHash, setTransactionHash] = useState("");
    const [winAmount, setWinAmount] = useState<number>(0);

    const {Flip, DoubleOrNothing, events, connection} = Connector();

    const refInput = useRef<HTMLInputElement>(null);
    const refMainBox = useRef<HTMLDivElement>(null);
    const refCircle2 = useRef<HTMLImageElement>(null);

    const settings = useAppSelector(state => state.globalSettings.settings);
    const chainConfig = stateScreen === IStateScreen.PlayToWallet && networkId in settings.fromWallet ?
        settings.fromWallet[networkId] : settings.fromBalance
    const contracts = useAppSelector(state => state.globalSettings.contracts);
    const profile = useAppSelector(state => state.account.data);


    const setDoublePlayCallback = (param: boolean) => {
        return setDoublePlay(param);
    };


    const setStartTimerCallback = (param: boolean | null) => {
        return setStartTimer(param)
    }
    const setStartTimer2Callback = (param: boolean | null) => {
        return setStartTimer2(param)
    }

    function run() {
        const prcCircle = parseInt(((amountTimer2 - 1) * 100 / maxTime).toString());
        if (refCircle2.current) {

            if (amountTimer2 > 0) {
                refCircle2.current.style.width = prcCircle + "%";
                setAmountTimer2(amountTimer2 - 1)
            }
            if (amountTimer2 === 0) {
                refCircle2.current.style.width = '0%';
                refMainBox.current?.classList.remove("active");
                refMainBox.current?.classList.remove("win");
                refMainBox.current?.classList.remove("lose");

                setStartTimer2(false);
            }
        }
    }

    useEffect(() => {
        if (!chainConfig) return;
        if (rangeState < chainConfig.minBet) {
            setRangeStateCallback(chainConfig.minBet);
        }
        if (!doublePlay && rangeState > chainConfig.maxBet) {
            setRangeStateCallback(chainConfig.maxBet);
        }
    }, [chainConfig]);

    useEffect(
        () => {
            if (refMainBox.current) {
                refMainBox.current.classList.add("active");
            }
            return () => {
                refMainBox.current?.classList.remove("active");
            };
        },
        []
    );

    useEffect(() => {

        if (refCircle2 !== null && startTimer2 && win === null && !flip) {
            setTimeout(() => {
                run()
            }, 1000)
        }
        if (refCircle2.current !== null && !startTimer2) {
            refCircle2.current.style.width = "100%";
        }
    }, [amountTimer2, startTimer2])

    useEffect(() => {

        if (doublePlay && startTimer2 === null) {
            setStartTimer2(true)
        }
        if (doublePlay && startTimer2 === false && win === null) {
            setAmountTimer2(maxTime);
            setStartTimer2(true);
        }

        // if (win === true) setWinAmount(calculationAmount(rangeState, chainConfig, doublePlay))
         if (win === false) setWinAmount(rangeState)
    }, [win])

    useEffect(
        () => {
            const handleFlip = async (payload: IFlipStatus) => {
                console.log(payload);
                if (payload.status === IStatusFlip.Won || payload.status === IStatusFlip.Lost) {

                    if (payload.status === IStatusFlip.Won) setWinAmount(payload.winningsCents / 100)
                    //if (payload.status === IStatusFlip.Lost) setWinAmount(payload.winningsCents)

                    refMainBox.current?.classList.add("animation");

                    onanimationend = (e) => {
                        if (refMainBox.current && e.animationName === "flip-out") {
                            setSpin(false);
                            setTimeout(() => {
                                if (payload.status === IStatusFlip.Won) {
                                    winSound.play().then()
                                    setWin(true);
                                }
                                if (payload.status === IStatusFlip.Lost) {
                                    loseSound.play().then();
                                    setWin(false);
                                }
                                refMainBox.current?.classList.remove("animation");
                                refMainBox.current?.classList.remove("active");
                                refMainBox.current?.classList.remove("win");
                                refMainBox.current?.classList.remove("lose");

                                setSpin(null)
                            }, 0)
                        }
                        setTransactionHash(payload.transactionHash);
                    };
                }
                if (payload.status === "Failed") {
                    setSpin(false);
                    setTimeout(() => setFlipCancelAll(), 0);
                    setMessageCallback("Something went wrong");
                    setOpenErrorCallback(true);
                }
            };
            events(
                handleFlip,
            );
        }, []
    );

    const FlipMessage = async () => {

        // @ts-ignore
        if (connection && connection._connectionState === "Connected") {
            setFlip(true);
            setTimeout(
              () => upSound.play()
                .then(),
              0
            );
            //setOpenProcessingCallback(true);
            try {
                setSpin(true);
                if (doublePlay) {
                    DoubleOrNothing(
                      bullState,
                      //signature,
                      "0x123",
                      networkId
                    );
                }
                else {
                    Flip(
                      rangeState,
                      bullState,
                      //signature,
                      "0x123",
                      networkId
                    );
                }
            }
            catch (e) {
                setOpenProcessingCallback(false);
                setMessageCallback("Something went wrong");
                setOpenErrorCallback(true);
                setFlip(false);
            }
        }
        // @ts-ignore
        else if (connection && connection._connectionState === "Disconnected") {
            setOpenProcessingCallback(false);
            setMessageCallback("There was a disconnect, please reload the page");
            setOpenErrorCallback(true);
        }
        else {
            setOpenProcessingCallback(false);
            setMessageCallback("Something went wrong");
            setOpenErrorCallback(true);
        }
    };

    const FlipWallet = async () => {
        setFlip(true);
        setOpenProcessingCallback(true);
        try {
            const data = doublePlay ?
                await StandAloneFlipApi.FlipsStandaloneDoubleOrNothing(
                    bullState,
                    networkId,
                    contracts
                )
                :
                await StandAloneFlipApi.FlipsStandAlone(
                    rangeState,
                    bullState,
                    networkId,
                    contracts
                );
            if (data === "NoMoney") {
                setOpenProcessingCallback(false);
                setMessageCallback("You don't have enough money in your decentralized wallet.");
                setOpenErrorCallback(true);
                setFlip(false);
            } else {
                setTransactionHash(data);
                setOpenProcessingCallback(false);
                setSpin(true);
                setTimeout(() => upSound.play().then(), 0)
            }

        } catch (e) {
            setOpenProcessingCallback(false);
            setMessageCallback("Something went wrong");
            setOpenErrorCallback(true);
            setFlip(false);
        }

    }

    const setFlipCancel = () => {
        setTimeout(() => refMainBox.current?.classList.add("active"), 30);
        if (win === true) {
            refMainBox.current?.classList.add("win");
        }
        if (win === false) {
            refMainBox.current?.classList.add("lose");
        }
        setFlip(false);
        setWin(null);
        setBullStateCallback(IBullState.Initial);
    };

    const setFlipCancelAll = () => {
        setFlipCancel();
        setDoublePlayCallback(false);
        setStartTimer2(null);
        if (chainConfig) {
            setRangeStateCallback(chainConfig.minBet && chainConfig.maxBet ?
              Math.round(chainConfig.maxBet - chainConfig.minBet) / 2 : Math.round(max - min) / 2);
            setPrcCallback(chainConfig.minBet && chainConfig.maxBet ?
              (Math.round(chainConfig.maxBet - chainConfig.minBet) / 2 - chainConfig.minBet) * 100 / (chainConfig.maxBet - chainConfig.minBet) :
              (startRange - min) * 100 / (max - min));
        }
    };

    const setFlipCancelLost = () => {
        if (doublePlay) {
            setFlipCancelAll();
        } else {
            setFlipCancel();
        }
    };

    const sliderPercent = chainConfig ? calculateSliderPercent(rangeState, chainConfig.minBet, chainConfig.maxBet) : rangeState * 2;

    return (
        <div className="b-1">
            <div className="b-1-box" id="screen-1" ref={refMainBox}>
                {win === null &&
                    <>
                        {doublePlay && !flip && (
                            <div className="title">
                                <div className="mb-15">Double or Nothing</div>
                                <div className="text text-blue mb-15">${rangeState}</div>
                                <div className="earnings">You can win <span className="numbersCustom">
                  ${calculationAmount(rangeState, chainConfig, doublePlay)}
                </span></div>
                            </div>
                        )}
                        {!doublePlay && !flip && (
                            <div className="title mt-15">
                                <div>BULL OR BEAR?</div>
                            </div>
                        )}
                        {flip && (
                            <div className="title mt-15">
                                <div>GOOD LUCK!</div>
                            </div>
                        )}

                        <div className={`coints${startTimer2 ? " start-timer-2" : ""}`}>
                            <SpinningCoin bullState={bullState} spin={spin} endSpin={spin}/>
                        </div>
                        <div className={`controll${flip ? " makeInvisible" : ""}`}>
                            {doublePlay &&
                                <div className="timer-2">
                                    <div className="text-center earnings-white">
                                        <span className="earnings">You have</span> <span
                                        className="timer-2-count">{amountTimer2}</span> sec <span className="earnings">to decide</span>
                                    </div>
                                    <div>
                                        <img src={timer2} alt=""/>
                                        <div className="timer-box">
                                            <div className="timer" ref={refCircle2}></div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="check-coints">
                                <div className={`radio${doublePlay ? " mb-30" : ""}`}>
                                    <div>
                                        <input type="radio" name="coint" value="bull" id="bull-1"
                                               checked={bullState === IBullState.Bull ? true : false} disabled={flip}
                                               onChange={() => {
                                               }}
                                        />
                                        <label htmlFor="bull-1" className="btn-vl btn-dark"
                                               onClick={() => {
                                                   pushSound.play().then();
                                                   !flip && setBullStateCallback(IBullState.Bull);
                                                   setShowMessage(false)
                                               }}>
                                            <span>Bull</span>
                                        </label>
                                    </div>
                                    <div>
                                        <input type="radio" name="coint" value="bear" id="bear-1"
                                               checked={bullState === IBullState.Bear ? true : false} disabled={flip}
                                               onChange={() => {
                                               }}
                                        />
                                        <label htmlFor="bear-1" className="btn-vl btn-red"
                                               onClick={() => {
                                                   pushSound.play().then();
                                                   !flip && setBullStateCallback(IBullState.Bear);
                                                   setShowMessage(false)
                                               }}>
                                            <span>Bear</span>
                                        </label>
                                    </div>
                                </div>
                                {!doublePlay &&
                                    <>
                                        <div className="range">
                                            <a className="btn-vl btn-range minus"
                                               onClick={() => {
                                                   pushSound.play().then();
                                                   if (rangeState > chainConfig.minBet && !flip) {
                                                       if (refInput.current) {
                                                           refInput.current.value = (rangeState - step).toString();
                                                       }
                                                       setRangeStateCallback(+(rangeState - step).toFixed(1));
                                                       setPrcCallback((rangeState - step - chainConfig.minBet) * 100 / (chainConfig.maxBet - chainConfig.minBet));
                                                   }
                                               }}>
                                                <span><img src={minus} alt=""/></span>
                                            </a>
                                            <div>
                                                <input type="range" min={chainConfig.minBet} max={chainConfig.maxBet}
                                                       step={step} ref={refInput} value={rangeState}
                                                       disabled={flip}
                                                       style={{backgroundSize: sliderPercent + "% 100%"}}
                                                       onChange={(e) => {
                                                           const target = +e.currentTarget.value;
                                                           setRangeStateCallback(target);
                                                           if (chainConfig)
                                                            setPrcCallback((target - chainConfig.minBet) * 100 / (chainConfig.maxBet - chainConfig.minBet));
                                                       }}
                                                />
                                                <output id="rangevalue" style={{
                                                    left: "calc(" + sliderPercent + "% - 40px)",
                                                    transform: "scale(" + (0.6 + (sliderPercent) / 250) + ") translateX(" + (10 - (sliderPercent) / 10) + "px)"
                                                }}>${rangeState}</output>
                                            </div>
                                            <a className="btn-vl btn-range plus"
                                               onClick={() => {
                                                   pushSound.play().then();
                                                   if (chainConfig && rangeState < chainConfig.maxBet && !flip) {
                                                       if (refInput.current) {
                                                           refInput.current.value = (rangeState + step).toString();
                                                       }
                                                       setRangeStateCallback(+(rangeState + step).toFixed(1));
                                                       setPrcCallback((rangeState - chainConfig.minBet) * 100 / (chainConfig.maxBet - chainConfig.minBet));
                                                   }
                                               }}>
                                                <span><img src={plus} alt=""/></span>
                                            </a>
                                        </div>
                                        <div className="earnings mb-30">You can win <span id="earnings1" className="numbersCustom">
                                                ${calculationAmount(rangeState, chainConfig, doublePlay)}
                                            </span>
                                        </div>
                                    </>
                                }
                                <div className="flip">
                                    <a className="btn-vl btn-flip screen-open" data-id="screen-2"
                                        //disabled={flip ? true : false}
                                       onClick={(e) => {
                                           if (!flip) {
                                               if (bullState === IBullState.Initial) {
                                                   setShowMessage(true);
                                               }
                                               if (bullState !== IBullState.Initial) {
                                                   if (!profile.isTrueNetwork) {
                                                       setOpenWrongNetworkCallback(true);
                                                   } else if (profile.profile.isBlocked) {
                                                       setMessageCallback("Your account has been blocked");
                                                       setOpenErrorCallback(true)
                                                   } else {
                                                       if (stateScreen === IStateScreen.Play &&
                                                           rangeState > getBalance(profile.profile, profile.walletData.network.id).approximateUsdCents + (profile.profile.bonusCents ? profile.profile.bonusCents / 100 : 0)) {
                                                           setOpenInsufficientFundsModalCallback(true);
                                                       }
                                                       if (stateScreen === IStateScreen.Play &&
                                                           rangeState <= getBalance(profile.profile, profile.walletData.network.id).approximateUsdCents + (profile.profile.bonusCents ? profile.profile.bonusCents / 100 : 0)) {
                                                           FlipMessage();
                                                       }
                                                       if (stateScreen === IStateScreen.PlayToWallet) {
                                                           FlipWallet();
                                                       }
                                                   }
                                               }
                                           }
                                       }}>
                                        <span>Flip</span>
                                    </a>
                                    <div className={`earnings mt-30${!showMessage ? " makeInvisible" : ""}`}>Please
                                        choose Bull or Bear
                                    </div>

                                </div>
                            </div>
                        </div>
                    </>
                }

            </div>

            <WinPay bullState={bullState} rangeState={calculationAmount(rangeState, chainConfig, doublePlay)}
                    win={win} startTimer={startTimer} pushSound={pushSound}
                    stateScreen={stateScreen} winAmount={winAmount}
                    setFlipCancel={setFlipCancel} setFlipCancelAll={setFlipCancelAll}
                    setStartTimerCallback={setStartTimerCallback}
                    setStartTimer2Callback={setStartTimer2Callback}
                    setDoublePlayCallback={setDoublePlayCallback} setRangeStateCallback={setRangeStateCallback}
                    transactionHash={transactionHash}
                    networkId={networkId}/>
            <LostPay bullState={bullState} win={win} winAmount={winAmount} pushSound={pushSound}
                     stateScreen={stateScreen} setFlipCancelLost={setFlipCancelLost} setBullStateCallback={setBullStateCallback}
                     transactionHash={transactionHash} networkId={networkId} />
            <TimeIsUpPay bullState={bullState} win={win} startTimer={startTimer} pushSound={pushSound}
                         startTimer2={startTimer2} winAmount={winAmount}
                         setFlipCancelLost={setFlipCancelLost} transactionHash={transactionHash} networkId={networkId}
                         stateScreen={stateScreen}
            />
        </div>
    );
};

export default PlayPay;