//identity.service.ts

import { intersection } from "underscore";
import {IAuthData, ISettingsGlobal} from "../../models";
import { AccountData, IAccountData } from "../../models/account";
import { checkNetwork, StorageService } from "../../services";
import { processError } from "../../utils/helpers";
import { Error, ErrorEnum } from "../../view/react/core/models";
import { LoginAPI } from "../api/login.api";
import { IdentityPermissionsEnum } from "./identity.permissions.enum";

export class IdentityService {
  private static permissions: IdentityPermissionsEnum[] = [];

  public static async logIn(
    connectorId: string
  ): Promise<void> {
    try {
      await window.WalletEnvLib.walletConnectWithAuth.logIn(
        connectorId,
        async (walletAddress: string): Promise<string> => {
          return await LoginAPI.getLogin(walletAddress);
        },
        async (
          walletAddress: string,
          signature: string
        ): Promise<IAuthData> => {
          return await LoginAPI.postLogin(
            walletAddress,
            signature
          );
        }
      );
    }
    catch (error: any) {
      throw processError(
        ErrorEnum.WalletAuth,
        "IdentityService.logIn(). Error",
        error
      );
    }
  }

  public static async logOut(useLogOutCallback?: boolean): Promise<void> {
    try {
      await window.WalletEnvLib.walletConnectWithAuth.logOut(useLogOutCallback);
    }
    catch (error: any) {
      throw processError(
        ErrorEnum.WalletAuth,
        "IdentityService.logOut(). Error",
        error
      );
    }
  }

  public static setUserPermissions(): boolean {
    let isAuth: boolean = false;
      if (StorageService.auth.get()?.accessToken) {
        isAuth = true;
      }
    return isAuth;
  }

  public static checkUserPermissions(
    permissions: IdentityPermissionsEnum[] = []
  ): boolean {
    return !permissions.length || intersection(
      IdentityService.permissions,
      permissions
    ).length > 0;
  }

  // public static async checkUserPermissionsForRoute(
  //   url: string,
  //   onlyAuth: boolean = false,
  //   permissions: IdentityPermissionsEnum[] = [],
  //   settings: ISettingsGlobal
  // ): Promise<void> {
  //   if (onlyAuth) {
  //     if (!this.getAccountData(settings).isAuthorized) {
  //       //navigation.goToLogin(url);  //TO DO
  //
  //       await new Promise(() => setTimeout(
  //         () => {
  //         },
  //         0
  //       ));
  //     }
  //   }
  //
  //   if (!IdentityService.checkUserPermissions(permissions)) {
  //     throw new Error(ErrorEnum.Forbidden);
  //   }
  //
  //   return;
  // }

  public static resetUserPermissions(): void {
    IdentityService.permissions = [];
  }

  // public static getAccountData(settings?: ISettingsGlobal): IAccountData {
  //   const accountData = window.WalletEnvLib.walletConnectWithAuth.getAccountData();
  //   return new AccountData(
  //     accountData.isAuthorized,
  //     checkNetwork(accountData.walletData.network.id, settings),
  //     undefined,
  //     accountData.walletData,
  //   );
  // }
}