//connector.model.ts

export interface IConnector {
  id: string;
  name: string;
}

export class Connector implements IConnector {
  public id: string;
  public name: string;

  constructor(
    id?: string,
    name?: string
  ) {
    this.id = id || "";
    this.name = name || "";
  }
}