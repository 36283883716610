import React, { FC, useEffect, useRef, useState } from "react";
import { maxTime, maxWin } from "../../core/settings/common.settings";
import SpinningCoin from "./SpinningCoin";
import { IBullState } from "../../core/models";

interface IWinFree {
  setFlipCancel: () => void;
  bullState: IBullState;
  rangeState: number;
  winAmount: number
  win: boolean | null
  pushSound: HTMLAudioElement
  startTimer: boolean | null
  setFlipCancelAll: () => void
  setStartTimerCallback: (param: boolean | null) => void;
  setStartTimer2Callback: (param: boolean | null) => void;
  setDoublePlayCallback: (param: boolean) => void;
  setOpenChoiceAfterFreePlayModalCallback: (param: boolean) => void;
  setRangeStateCallback: (param: number) => void;
}

const WinFree: FC<IWinFree> = ({
  setFlipCancel,
  bullState,
  rangeState,
  win,
  pushSound,
  winAmount,
  startTimer,
  setStartTimerCallback,
  setStartTimer2Callback,
  setDoublePlayCallback,
  setRangeStateCallback,
  setFlipCancelAll,
  setOpenChoiceAfterFreePlayModalCallback
}) => {
  const refMainBox = useRef<HTMLDivElement>(null);
  const refCircle = useRef<SVGCircleElement>(null);
  const [spin, setSpin] = useState<boolean | undefined>(undefined);
  const [amountTimer, setAmountTimer] = useState<number>(14);


  const radius = 80;


  function run(
    winningResult: number,
  ) {
    let angle = (winningResult - 1) * 365 / maxTime || 0;
    const circumference = 2 * Math.PI * radius;
    const strokeOffset = (1 / 4) * circumference;
    const strokeDasharray = (angle / 365) * circumference;
    if (refCircle.current) {
      refCircle.current.setAttribute(
        "r",
        radius.toString()
      );
      refCircle.current.setAttribute(
        "stroke-dasharray",
        [
          strokeDasharray.toString(),
          (circumference - strokeDasharray).toString()
        ].toString()
      );
      refCircle.current.setAttribute(
        "stroke-dashoffset",
        strokeOffset.toString()
      );
      if (amountTimer > 0) {
        setAmountTimer(amountTimer - 1)
      }
      if (amountTimer === 0) setStartTimerCallback(false);
    }
  }

  useEffect(()=>{
    if (refCircle !== null && startTimer && win && rangeState <= maxWin) {
      setTimeout(()=>{run(amountTimer)}, 1000)
    }
    if (refCircle !== null && !startTimer) {
      setAmountTimer(14);
      let angle = 14 * 365 / maxTime || 0;
      const circumference = 2 * Math.PI * radius;
      const strokeOffset = (1 / 4) * circumference;
      const strokeDasharray = (angle / 365) * circumference;
      if (refCircle.current) {
        refCircle.current.setAttribute(
          "r",
          radius.toString()
        );
        refCircle.current.setAttribute(
          "stroke-dasharray",
          [
            strokeDasharray.toString(),
            (circumference - strokeDasharray).toString()
          ].toString()
        );
        refCircle.current.setAttribute(
          "stroke-dashoffset",
          strokeOffset.toString()
        );
      }
    }
  },[amountTimer, startTimer])

  useEffect(()=> {
    if (win) {
      setSpin(false)
      setTimeout(()=>setSpin(undefined),0)
      setTimeout(()=>setStartTimerCallback(true), 2000)
    }
    if (win === null) {
      setStartTimerCallback(null);
    }
  },[win])


  return (
    <div className={`b-1-box win${win === true && (startTimer === true || startTimer === null)? " active" : ""}`}
         id="screen-2" ref={refMainBox}>
      <div className="title">
        <div className="text-blue mb-15">CONGRATS!</div>
        <div className="text text-white">YOU COULD’VE WON ${winAmount}</div>
      </div>
      <div className={`coints${startTimer && rangeState <= maxWin? " start-timer-1" : ""}`}>
        {/*<StopCoin bullState={bullState} endSpin={spin}/>*/}
        <SpinningCoin bullState={bullState} endSpin={spin}/>
        {rangeState <= maxWin &&
        <div className="timer-1">
          <div className="timer-count">{amountTimer}</div>
          <svg>
            <circle cx="110" cy="110" r="0" strokeLinecap="round" ref={refCircle}/>
          </svg>
        </div>
        }
      </div>
      <div className="controll">
        {rangeState <= maxWin ?
          <div className="earnings mb-30">Connect your wallet and play to win real money</div>
          :
          <div className="earnings mb-30">You have reached the maximum<br />bet amount</div>
        }
        <div className="win-btn-1">
          {rangeState <= maxWin &&
            <a className="btn-vl btn-flip btn-yellow screen-open"
                 onClick={() => {
                   pushSound.play().then();
                   setDoublePlayCallback(true);
                   setStartTimer2Callback(false);
                   setRangeStateCallback(rangeState);
                   setFlipCancel();
                 }}>
              <span>Double or Nothing</span></a>
          }
          {rangeState > maxWin &&
            <a className="btn-vl btn-flip btn-yellow screen-open"
                 onClick={() => {
                   pushSound.play().then();
                   setFlipCancelAll();
                 }}
                 data-id="screen-1"><span>New free game</span>
            </a>
          }
        </div>

        <div className="win-btn-2">
          <a className="btn-vl btn-flip btn-blue screen-open"
               onClick={() => {
                 pushSound.play().then();
                 setOpenChoiceAfterFreePlayModalCallback(true);
                 setFlipCancelAll();
               }}
               data-id="screen-1"><span>Exit free flip</span></a>
        </div>
      </div>
    </div>
  );
};
export default WinFree;