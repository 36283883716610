//contract.ts

import { processError } from "../../utils/helpers";
import { ErrorEnum } from "../../view/react/core/models";

const requestWithCheckNetwork = async <T>(
  networkId: number,
  func: () => Promise<T>
): Promise<T> => {
  try {
    return await window.WalletEnvLib.walletConnect.requestWithCheckNetwork(
      networkId,
      func
    );
  }
  catch (error: any) {
    throw processError(
      ErrorEnum.Wallet,
      "requestWithCheckNetwork(). Error",
      error
    );
  }
};

const write = async (
  functionName: string,
  args: any[] = [],
  overrides?: {
    from?: string,
    value?: string
  },
  contactAddress?: string,
  abi?: any,
  useWatchContractEvent?: {
    eventName: string;
    callback: (args: [] | {} | undefined) => void;
    delay?: number;
  }
): Promise<any> => {
  try {
    return await window.WalletEnvLib.contract.write(
      functionName,
      args,
      overrides,
      contactAddress,
      abi,
      useWatchContractEvent
    );
  }
  catch (error) {
    throw processError(
      ErrorEnum.Contract,
      "write(). Error",
      error
    );
  }
};

const read = async (
  functionName: string,
  args: any[] = [],
  contactAddress?: string,
  abi?: any
): Promise<any> => {
  try {
    return await window.WalletEnvLib.contract.read(
      functionName,
      args,
      contactAddress,
      abi
    );
  }
  catch (error) {
    throw processError(
      ErrorEnum.Contract,
      "read(). Error",
      error
    );
  }
};

const signTypedData = async (obj: any): Promise<string> => {
  try {
    return await window.WalletEnvLib.walletConnect.signTypedData(obj);
  }
  catch (error: any) {
    throw error;
  }
};

const contract = {
  requestWithCheckNetwork,
  write,
  read,
  signTypedData
};

export {
  contract
};