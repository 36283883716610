import {createSlice} from '@reduxjs/toolkit'
import {IAccountData, ISettingsGlobal} from "../models";
import {max, maxWin, min, startPrc, startRange} from "../settings/common.settings";
import {IDepositariumContracts} from "../models/game/gameModels.model";

export const globalSettingsSlice = createSlice({
    name: 'globalSettings',
    initialState: {
        settings: {
            fromBalance: {
                chainId: 137,
                flat: 0,
                percentage: 0,
                doubleOrNothingFlat: 0,
                doubleOrNothingPercentage: 0,
                minBet: min,
                maxBet: max,
                doubleOrNothingMaxBet: maxWin,
                startRange: startRange,
                startPrc: startPrc,
            },
            fromWallet: {},
        } as ISettingsGlobal,
        contracts: [] as IDepositariumContracts[],
    },
    reducers: {
        setSettings: (state, action: { payload: ISettingsGlobal, type: string }) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes.
            // Also, no return statement is required from these functions.
            state.settings = action.payload;
            state.contracts = action.payload.fromWallet ?
                Object.keys(action.payload.fromWallet).map((key) => {
                    return {
                        address: action.payload.fromWallet[key].address,
                        chainId: action.payload.fromWallet[key].chainId,
                    }
                }) : [];
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setSettings: setGlobalSettings,
} = globalSettingsSlice.actions

export default globalSettingsSlice.reducer