import {CommonSettings, ContractDto, FlipCommission, UserProfileResponse} from "../../../swaggerFlips/src";
import {IChainSettings, ISettingsGlobal} from "../../../models/game/gameModels.model";
import {processError} from "../../../utils/helpers";
import {ErrorEnum} from "../../../view/react/core/models";
import {max, maxWin, min, startRange} from "../../../settings/common.settings";

export const settingsSmToVm = (t?: CommonSettings): ISettingsGlobal => {
    try {
        return convert(t)
    } catch (error: any) {
        throw processError(
            ErrorEnum.Mapping,
            "settingsSmToVm(). Error",
            error
        );
    }

    function convert(t?: CommonSettings): ISettingsGlobal {
        let fromWallet: { [key: string]: IChainSettings; } = {};
        if (t?.commissions?.fromWalletCommissions) {
            Object.entries(t.commissions.fromWalletCommissions).forEach(([key, value]) => {
                let contract = t.contracts?.depositariumContracts?.find((contract) => contract.chainId === Number(key))
                if (contract == undefined || contract.chainId == undefined) {
                    console.warn(`There are commissions for chain ${key} but there is no contract for it`);
                    return;
                }
                fromWallet[key] = convertFlipCommissionToChainSettings(contract.chainId, contract.address?.toString() ?? "", value)
            })
        }
        let chainId: number;
        let address: string;
        let flipContract = t?.contracts?.flipsContract;
        if (flipContract == undefined) {
            console.warn(`There is no flips contract for chain ${flipContract}. Setting polygon (137) as default`);
            chainId = 137;
            address = "";
        } else {
            chainId = flipContract.chainId ?? 137;
            address = flipContract.address?.toString() ?? "";
        }
        return {
            fromBalance: convertFlipCommissionToChainSettings(chainId, address, t?.commissions?.fromBalanceCommission),
            fromWallet: fromWallet
        }
    }

    function convertFlipCommissionToChainSettings(chainId: number, address: string, t?: FlipCommission): IChainSettings {
        return {
            chainId: chainId,
            address: address,
            flat: t?.flat ? t.flat / 100 : 0,
            percentage: t?.percentage ?? 0,
            doubleOrNothingFlat: t?.doubleOrNothingFlat ? t.doubleOrNothingFlat : 0,
            doubleOrNothingPercentage: t?.doubleOrNothingPercentage ?? 0,
            minBet: t?.minBetCents? t.minBetCents / 100 : min,
            maxBet: t?.maxBetCents? t.maxBetCents / 100 : max,
            doubleOrNothingMaxBet: t?.maxDoubleOrNothingBetCents ? t.maxDoubleOrNothingBetCents / 100 : maxWin,
            startRange: t?.minBetCents && t?.maxBetCents ?
                Math.round(t.maxBetCents - t.minBetCents) / 2 / 100 : Math.round(max - min) / 2,
            startPrc: t?.minBetCents && t?.maxBetCents ?
                (Math.round(t.maxBetCents - t.minBetCents) / 2 - t.minBetCents) * 100 / (t.maxBetCents - t.minBetCents) / 100 :
                (startRange - min) * 100 / (max - min)
        }
    }
}