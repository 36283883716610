import {createSlice} from "@reduxjs/toolkit";
import {AccountData, IAccountData} from "../models";
import {INewUserData} from "../../view/nav/ConnectorBalance";
import {IChainBalance} from "../models/profile/chainBalance.model";
import { ModalWindows } from "../models/modalWindows/modalWindows.model";

export const accountSlice = createSlice({
    name: 'account',
    initialState: {
        data: {
            walletData: {
                network: {

                }
            },
            profile: {},
            isTrueNetwork: false,
            isAuthorized: false,
            modalWindows: new ModalWindows(),
            testTest: false,
        } as IAccountData
    },
    reducers: {
        setAccount: (state, action: { payload: IAccountData, type: string }) => {
            //console.log("setAccount", action.payload)
            state.data = action.payload;
        },
        updateBalance(state, action: { payload: INewUserData}) {
            //console.log("updateBalance", action.payload)
            const {chainId, wei, usdCents, bonusCents} = action.payload;
            if (chainId > 0 && state.data.profile.balances) {
                let balance = state.data.profile.balances[chainId];
                if (balance) {
                    balance.wei = wei;
                    balance.usdCents = usdCents;
                } else {
                    state.data.profile.balances[chainId] = {
                        wei: wei,
                        usdCents: usdCents
                    } as IChainBalance
                }
            }
            state.data.profile.bonusCents = bonusCents;
        },
        setLoggedOutAccount(state) {
            //console.log("setLoggedOutAccount")
            state.data = new AccountData(
                false,
                false,
                undefined,
                undefined
            )
        },
        setOpenHowToVerifyAction(state, action: { payload: boolean}) {
            state.data.modalWindows.openHowToVerify = action.payload;
        },
        setOpenHowToVerifyActionFalse(state, action: { payload: boolean}) {
            state.data.modalWindows.openHowToVerify = false;
        },
        setTestTest(state, action: { payload: boolean}) {
            debugger
            state.data.testTest = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const {
    setAccount: setAccountData,
    updateBalance,
    setLoggedOutAccount, setOpenHowToVerifyAction, setOpenHowToVerifyActionFalse, setTestTest
} = accountSlice.actions

export default accountSlice.reducer