//recentFlips.api.ts

import {
  RecentFlipDto,
  RecentFlipsEndpointsApi,
} from "../../swaggerFlips/src";
import { getConfiguration } from "./swagger";
import { XhrService } from "../../services";
import { call } from "./axios";
import { processError } from "../../utils/helpers";
import { ErrorEnum } from "../../view/react/core/models";

export class RecentFlipsApi {
  public static async RecentFlips(): Promise<Array<RecentFlipDto>> {
    try {
      const data = await XhrService.call(
        async () => {
          return await call(
            async () => {
              return await this.getRecentFlipsApiInstance()
                .apiFlipsRecentFlipsGet(10);
            });
        });
      return data;
    }
    catch (error: any) {
      throw processError(
        ErrorEnum.API,
        "RecentFlipsApi.RecentFlips(). Error",
        error.message
      );
    }
  }

  private static getRecentFlipsApiInstance(): RecentFlipsEndpointsApi {
    return new RecentFlipsEndpointsApi(getConfiguration(
      undefined,
      false,
      false
    ));
  }
}