//walletData.model.ts

import { Balance, IBalance } from "./balance.model";
import { Connector, IConnector } from "./connector.model";
import { INetwork, Network } from "./network.model";

export interface IWalletData {
  address: string;
  network: INetwork;
  balance: IBalance;
  connector: IConnector;
  isConnected: boolean;
}

export class WalletData implements IWalletData {
  public address: string;
  public network: INetwork;
  public balance: IBalance;
  public connector: IConnector;
  public isConnected: boolean;

  constructor(
    address?: string,
    network?: INetwork,
    balance?: IBalance,
    connector?: IConnector
  ) {
    this.address = address || "";
    this.network = network || new Network();
    this.balance = balance || new Balance();
    this.connector = connector || new Connector();
    this.isConnected = !!address;
  }
}