//login.api.ts

import { AuthData, IAuthData } from "../../models";
import { StorageService } from "../../services";
import { AuthEndpointDefinitionApi, LoginResult, RefreshTokenInput } from "../../swaggerAuth/src";
import { processError } from "../../utils/helpers";
import { Error, ErrorEnum } from "../../view/react/core/models";
import { call } from "./axios";
import { getConfiguration } from "./swagger";

export class LoginAPI {
  public static async getLogin(walletAddress: string): Promise<string> {
    try {
      const data = await call<string>(
        async () => {
          return await this.getLoginApiInstance().apiAuthVerificationMessageAddressGet(walletAddress)
        }
      );
      if (data) {
        return data;
      } else {
        throw undefined;
      }
    }
    catch (error: any) {
      throw processError(
        ErrorEnum.AuthToken,
        "LoginAPI.getLogin(). Error",
        error
      );
    }
  }

  public static async postLogin(
    walletAddress: string,
    signature: string
  ): Promise<IAuthData> {
    try {
      const data = await call<LoginResult>(
        async () => {
          return await this.getLoginApiInstance()
            .apiAuthLoginPost(
              {
                publicAddress: walletAddress,
                messageSignature: signature
              }
            );
        }
      );
      return new AuthData(
        data.authToken,
        data.refreshToken
      );
    }
    catch (error: any) {
      throw processError(
        ErrorEnum.AuthToken,
        "LoginAPI.postLogin(). Error",
        error
      );
    }
  }

  public static async refreshToken(
    walletAddress: string
  ): Promise<IAuthData> {
    try {
      const authData = StorageService.auth.get();
      //if (!authData.refreshToken || !walletAddress) {
      if (!authData.refreshToken) {
        throw new Error(
          ErrorEnum.RefreshToken,
          undefined,
          "LoginAPI.refreshToken(). Refresh token or wallet address not found"
        );
      }

      const data = await call<RefreshTokenInput>(
        async () => {
          return await this.getLoginApiInstance().apiAuthRefreshTokenPost(
            {
              authToken: authData.accessToken,
              refreshToken: authData.refreshToken
            }
          );
        }
      );

      return new AuthData(
        data.authToken,
        data.refreshToken
      );
    }
    catch (error: any) {
      throw processError(
        ErrorEnum.AuthToken,
        "LoginAPI.refreshToken(). Error",
        error
      );
    }
  }

  private static getLoginApiInstance(): AuthEndpointDefinitionApi {
    return new AuthEndpointDefinitionApi(getConfiguration(
      undefined,
      true
    ));
  }
}