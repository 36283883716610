//common.settings.ts

import { IChainSettings } from "../models";

export const suspenseDelay: number = 250;

export const serverDatePattern: string = "YYYY-MM-DDTHH:mm:ss";

export const priceDigits: number = 4;


//GamerFi

export const ethereumId: number = 1;
export const bnbId: number = 56;
export const polygonId: number = 137;
export const sepoliaId: number = 11155111;
export const bnbTestId: number = 97;
export const mumbaiId: number = 80001;

export const ethereumSrt: string = "1";
export const bnbStr: string = "56";
export const polygonStr: string = "137";
export const sepoliaStr: string = "11155111";
export const bnbTestStr: string = "97";
export const mumbaiStr: string = "80001";

export const maxWin: number = 800;

export const step: number = 0.5;
export const min: number = 0.5;
export const max: number = 50;
export const startRange: number = Math.round(max - min) / 2; //25;
export const startPrc: number = (startRange - min) * 100 / (max - min); //50;
export const maxTime: number = 15;
export const chainSettingsDefault: IChainSettings = {
  chainId:0,
  address:"",
  flat:0,
  percentage: 0,
  doubleOrNothingFlat: 0,
  doubleOrNothingPercentage: 0,
  minBet: 0.5,
  maxBet: 50,
  doubleOrNothingMaxBet: 1000,
  startRange: 25,
  startPrc: 50
}

export const prod: boolean = process.env.REACT_APP_IS_PROD ? (process.env.REACT_APP_IS_PROD === "true") : false;
export const addressBaseURL: string = process.env.REACT_APP_BASE_URL ?? "https://test.gamerfi.pro";
//gamerfi.esas.by - это дев среда
//test.gamerfi.pro- это домен для тестовой среды
//staging.gamerfi.pro- это уже предпродовская среда