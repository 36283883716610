import React, { FC } from "react";
import bull from "../../template/img/frames/30.png";
import bear from "../../template/img/frames/01.png";
import { ContractMethods } from "../../core/xhr/api/contractMethods";
import eye from "../../template/img/eye.svg";
import { IBullState, IStateScreen } from "../../core/models";

interface ITimeIsUpPay {
  networkId: number
  transactionHash: string
  stateScreen: IStateScreen
  setFlipCancelLost: () => void
  bullState: IBullState;
  startTimer: boolean | null
  startTimer2: boolean | null
  win: boolean | null
  pushSound: HTMLAudioElement
  winAmount: number
}

const TimeIsUpPay: FC<ITimeIsUpPay> = ({
  networkId,
  transactionHash,
  stateScreen,
  setFlipCancelLost,
  bullState,
  startTimer,
  startTimer2,
  win,
  pushSound,
  winAmount
}) => {

  return (
    <div className={`b-1-box win${(startTimer === false && win === true) || (startTimer2 === false && win === null) ? " active" : ""}`} id="screen-8">
      <div className="title">
        <div className="text-blue mb-15">Time’s up!</div>
        <div className="text text-blue">YOU WON ${winAmount}</div>
      </div>
      <div className="coints">
        <div>
          <img src={bullState === IBullState.Bull ? bull : bear} alt=""
               className={bullState === IBullState.Bull ? "bull" : "bear"} />
        </div>
      </div>
      <div className="controll">
        <div className="win-btn-1">
          <a className="btn-vl btn-flip btn-blue screen-open" data-id="screen-1"
               onClick={() => {
                 pushSound.play().then();
                 setFlipCancelLost()
                 //setFlipCancelAll();
               }}>
            <span>New game</span>
          </a>
        </div>
        <div>
          <a href={ContractMethods.chooseTx(networkId, stateScreen === IStateScreen.Play) + transactionHash} className="link-red" target="_blank"><span>Verify result</span>
            <img src={eye} alt="" className="bull" /></a>
        </div>
      </div>
    </div>
  );
};

export default TimeIsUpPay