//contract.settings.ts

import { bnbTestId, mumbaiId, polygonId, prod, sepoliaId } from "./common.settings";

export const txSepolia = "https://sepolia.etherscan.io/tx/";
export const txMumbai = "https://mumbai.polygonscan.com/tx/";
export const txBnbTest = "https://testnet.bscscan.com/tx/";

export const txEthereum = "https://etherscan.io/tx/";
export const txPolygon = "https://polygonscan.com/tx/";
export const txBnb = "https://bscscan.com/tx/";


export const ContractSepolia = "0x2679af93F61AE18aA5ceF55eEde688d5a842D0f8";
//https://sepolia.etherscan.io/address/0x2679af93f61ae18aa5cef55eede688d5a842d0f8

export const ContractMumbai = "0xedC854d72F1677c9830608D80DC8DD1fC4A4C152";
//https://mumbai.polygonscan.com/address/0xedC854d72F1677c9830608D80DC8DD1fC4A4C152

export const ContractBnbTest = "0xEfa68D5d039b8653936624d7AcFbDAB24A04cfd9";
//https://testnet.bscscan.com/address/0xefa68d5d039b8653936624d7acfbdab24a04cfd9


export const ContractPolygon = "0x0ba726541ecb8b1bd85271de673064a3a908d97d";
//https://polygonscan.com/address/0x0ba726541ecb8b1bd85271de673064a3a908d97d

export const ContractStandAlonePolygon = "0x77A8970F2Dfd5c009d35A8418273Ab86Ea07821D"
//https://polygonscan.com/address/0x77A8970F2Dfd5c009d35A8418273Ab86Ea07821D

export const ContractAbi = {
  abi: [{ "inputs": [], "stateMutability": "nonpayable", "type": "constructor" },
    {
      "inputs": [{ "internalType": "uint256", "name": "contribution", "type": "uint256" },
        { "internalType": "uint256", "name": "requestedAmount", "type": "uint256" }],
      "name": "Depositarium__ContributionLessThanWithdrawal",
      "type": "error"
    },
    {
      "inputs": [{ "internalType": "address", "name": "requestFor", "type": "address" },
        { "internalType": "address", "name": "requestBy", "type": "address" }],
      "name": "Depositarium__NotAuthorized",
      "type": "error"
    },
    {
      "inputs": [{ "internalType": "uint256", "name": "balance", "type": "uint256" },
        { "internalType": "uint256", "name": "requestedAmount", "type": "uint256" }],
      "name": "Depositarium__NotEnoughFunds",
      "type": "error"
    },
    { "inputs": [], "name": "Depositarium__SignatureNotLongerValid", "type": "error" },
    {
      "inputs": [{ "internalType": "address", "name": "trustedSigner", "type": "address" },
        { "internalType": "address", "name": "requestSigner", "type": "address" }],
      "name": "Depositarium__UntrustedSigner",
      "type": "error"
    },
    { "inputs": [], "name": "Depositarium__ZeroAddressNotAllowed", "type": "error" },
    { "inputs": [], "name": "Depositarium__ZeroAmountNotAllowed", "type": "error" },
    { "inputs": [], "name": "InvalidShortString", "type": "error" },
    {
      "inputs": [{ "internalType": "string", "name": "str", "type": "string" }],
      "name": "StringTooLong",
      "type": "error"
    },
    {
      "anonymous": false,
      "inputs": [{ "indexed": false, "internalType": "uint256", "name": "amount", "type": "uint256" }],
      "name": "ContributedToPrizePool",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [{ "indexed": true, "internalType": "address", "name": "player", "type": "address" },
        { "indexed": false, "internalType": "uint256", "name": "amount", "type": "uint256" }],
      "name": "Deposited",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [{ "indexed": true, "internalType": "uint256", "name": "requestId", "type": "uint256" },
        { "indexed": true, "internalType": "uint8", "name": "gameId", "type": "uint8" },
        { "indexed": true, "internalType": "address", "name": "player", "type": "address" },
        { "indexed": false, "internalType": "uint256", "name": "amount", "type": "uint256" },
        { "indexed": false, "internalType": "uint16", "name": "prediction", "type": "uint16" }],
      "name": "DepositedForInstantGame",
      "type": "event"
    },
    { "anonymous": false, "inputs": [], "name": "EIP712DomainChanged", "type": "event" },
    {
      "anonymous": false,
      "inputs": [{ "indexed": true, "internalType": "uint256", "name": "requestId", "type": "uint256" },
        { "indexed": true, "internalType": "uint8", "name": "gameId", "type": "uint8" },
        { "indexed": true, "internalType": "address", "name": "player", "type": "address" },
        { "indexed": false, "internalType": "uint256", "name": "amount", "type": "uint256" }],
      "name": "PaidOut",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [{ "indexed": true, "internalType": "bytes32", "name": "role", "type": "bytes32" },
        { "indexed": true, "internalType": "bytes32", "name": "previousAdminRole", "type": "bytes32" },
        { "indexed": true, "internalType": "bytes32", "name": "newAdminRole", "type": "bytes32" }],
      "name": "RoleAdminChanged",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [{ "indexed": true, "internalType": "bytes32", "name": "role", "type": "bytes32" },
        { "indexed": true, "internalType": "address", "name": "account", "type": "address" },
        { "indexed": true, "internalType": "address", "name": "sender", "type": "address" }],
      "name": "RoleGranted",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [{ "indexed": true, "internalType": "bytes32", "name": "role", "type": "bytes32" },
        { "indexed": true, "internalType": "address", "name": "account", "type": "address" },
        { "indexed": true, "internalType": "address", "name": "sender", "type": "address" }],
      "name": "RoleRevoked",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [{ "indexed": true, "internalType": "address", "name": "player", "type": "address" },
        { "indexed": false, "internalType": "uint256", "name": "amount", "type": "uint256" }],
      "name": "Withdrawn",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [{ "indexed": false, "internalType": "uint256", "name": "amount", "type": "uint256" }],
      "name": "WithdrawnContributionFromPrizePool",
      "type": "event"
    },
    {
      "inputs": [],
      "name": "ACCOUNTANT_ROLE",
      "outputs": [{ "internalType": "bytes32", "name": "", "type": "bytes32" }],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "DEFAULT_ADMIN_ROLE",
      "outputs": [{ "internalType": "bytes32", "name": "", "type": "bytes32" }],
      "stateMutability": "view",
      "type": "function"
    },
    { "inputs": [], "name": "contributeToPrizePool", "outputs": [], "stateMutability": "payable", "type": "function" },
    { "inputs": [], "name": "deposit", "outputs": [], "stateMutability": "payable", "type": "function" },
    {
      "inputs": [{ "internalType": "uint8", "name": "gameId", "type": "uint8" },
        { "internalType": "uint16", "name": "prediction", "type": "uint16" }],
      "name": "depositForInstantGame",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "eip712Domain",
      "outputs": [{ "internalType": "bytes1", "name": "fields", "type": "bytes1" },
        { "internalType": "string", "name": "name", "type": "string" },
        { "internalType": "string", "name": "version", "type": "string" },
        { "internalType": "uint256", "name": "chainId", "type": "uint256" },
        { "internalType": "address", "name": "verifyingContract", "type": "address" },
        { "internalType": "bytes32", "name": "salt", "type": "bytes32" },
        { "internalType": "uint256[]", "name": "extensions", "type": "uint256[]" }],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getPrizePoolContributionBalance",
      "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [{ "internalType": "bytes32", "name": "role", "type": "bytes32" }],
      "name": "getRoleAdmin",
      "outputs": [{ "internalType": "bytes32", "name": "", "type": "bytes32" }],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getTrustedSigner",
      "outputs": [{ "internalType": "address", "name": "", "type": "address" }],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [{ "internalType": "bytes32", "name": "role", "type": "bytes32" },
        { "internalType": "address", "name": "account", "type": "address" }],
      "name": "grantRole",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [{ "internalType": "bytes32", "name": "role", "type": "bytes32" },
        { "internalType": "address", "name": "account", "type": "address" }],
      "name": "hasRole",
      "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [{ "internalType": "uint256", "name": "requestId", "type": "uint256" },
        { "internalType": "uint8", "name": "gameId", "type": "uint8" },
        { "internalType": "address payable", "name": "player", "type": "address" },
        { "internalType": "uint256", "name": "amount", "type": "uint256" }],
      "name": "payOut",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [{ "internalType": "bytes32", "name": "role", "type": "bytes32" },
        { "internalType": "address", "name": "account", "type": "address" }],
      "name": "renounceRole",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [{ "internalType": "bytes32", "name": "role", "type": "bytes32" },
        { "internalType": "address", "name": "account", "type": "address" }],
      "name": "revokeRole",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [{ "internalType": "address", "name": "trustedSigner", "type": "address" }],
      "name": "setTrustedSigner",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [{ "internalType": "bytes4", "name": "interfaceId", "type": "bytes4" }],
      "name": "supportsInterface",
      "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [{
        "components": [{ "internalType": "address payable", "name": "player", "type": "address" },
          { "internalType": "uint256", "name": "amount", "type": "uint256" },
          { "internalType": "uint256", "name": "withdrawnTotal", "type": "uint256" },
          { "internalType": "uint32", "name": "requestValidTill", "type": "uint32" }],
        "internalType": "struct IDepositarium.WithdrawalRequest",
        "name": "withdrawalRequest",
        "type": "tuple"
      },
        { "internalType": "bytes", "name": "signature", "type": "bytes" }],
      "name": "withdraw",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" },
        { "internalType": "address payable", "name": "withdrawTo", "type": "address" }],
      "name": "withdrawContributionFromPrizePool",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [{
        "components": [{ "internalType": "address payable", "name": "withdrawTo", "type": "address" },
          { "internalType": "uint256", "name": "amount", "type": "uint256" }],
        "internalType": "struct IDepositarium.FeeWithdrawalRequest[]",
        "name": "withdrawalRequests",
        "type": "tuple[]"
      }], "name": "withdrawFees", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    }]
};

export const defaultContracts = prod ?
  [
    {
      address: ContractPolygon,
      chainId: polygonId
    }
  ]
  : [
    {
      address: ContractSepolia,
      chainId: sepoliaId
    },
    {
      address: ContractMumbai,
      chainId: mumbaiId
    },
    {
      address: ContractBnbTest,
      chainId: bnbTestId
    }
  ]
