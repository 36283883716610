import React, { FC, useEffect, useRef, useState } from "react";
import { ContractMethods } from "../../core/xhr/api/contractMethods";
import eye from "../../template/img/eye.svg";
import { SpinningCoin } from "./SpinningCoin";
import { IBullState, IStateScreen } from "../../core/models";

interface ILostPay {
  setFlipCancelLost: () => void;
  setBullStateCallback: (param: IBullState) => void
  bullState: IBullState;
  networkId: number;
  win: boolean | null;
  pushSound: HTMLAudioElement
  winAmount: number
  stateScreen: IStateScreen
  transactionHash: string
}

const LostPay: FC<ILostPay> = ({
  setFlipCancelLost,
  setBullStateCallback,
  bullState,
  networkId,
  win,
  pushSound,
  winAmount,
  stateScreen,
  transactionHash
}) => {
  const refMainBox = useRef<HTMLDivElement>(null);
  const [spin, setSpin] = useState<boolean | undefined>(undefined);

  useEffect(()=> {
    if (win === false) {
      setBullStateCallback(bullState === IBullState.Bull? IBullState.Bear: IBullState.Bull)
      setSpin(false);
    }

    setTimeout(()=>setSpin(undefined),300)
  },[win])

  return (
    <div className={`b-1-box lose${win === false ? " active" : ""}`} id="screen-4" ref={refMainBox}>
      <div className="title">
        <div className="text-red mt-30 mb-15">YOU LOST!</div>
        <div className="text text-red">-${winAmount}</div>
      </div>
      <div className="coints">
        {/*<StopCoin bullState={bullState} endSpin={spin}/>*/}
        <SpinningCoin bullState={bullState} endSpin={spin}/>
      </div>
      <div className="controll">
        <div className="lose-btn-1">
          <a className="btn-vl btn-flip btn-yellow screen-open"
             onClick={()=> {
               pushSound.play().then();
               setFlipCancelLost()
             }}
               data-id="screen-1"><span>Flip again</span></a>
        </div>
        <div className="lose-btn-2">
          <a className="btn-vl btn-flip btn-blue screen-open"
             onClick={() => {
               pushSound.play().then();
               setFlipCancelLost();
             }}
               data-id="screen-1"><span>New game</span></a>
        </div>
        <div>
          <a href={ContractMethods.chooseTx(networkId, true) + transactionHash} className="link-red" target="_blank"><span>Verify result</span>
            <img src={eye} alt="" className="bull" /></a>
        </div>
      </div>
    </div>
  );
};
export default LostPay;