import { startPrc, startRange } from "../../settings/common.settings";

export enum IStateScreen {
  Deposit      = "Deposit",
  Withdraw     = "Withdraw",
  PlayFree     = "PlayFree",
  PlayInitial  = "PlayInitial",
  Play         = "Play",
  PlayToWallet = "PlayToWallet"
}

export enum IBullState {
  Bull    = "Bull",
  Bear    = "Bear",
  Initial = "Initial",
  Spin    = "Spin",
  SpinEnd = "SpinEnd"
}

export interface IChainSettings {
  chainId: number;
  address: string;
  flat: number;
  percentage: number;
  doubleOrNothingFlat: number;
  doubleOrNothingPercentage: number;
  minBet: number;
  maxBet: number;
  doubleOrNothingMaxBet: number;
  startRange: number;
  startPrc: number;
}

export interface ISettingsGlobal {
  fromBalance: IChainSettings;
  fromWallet: { [key :string]: IChainSettings; }
}

export interface IDepositariumContracts {
  address: string
  chainId: number
}