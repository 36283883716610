//profile.api.ts

import { profileSmToVm } from "../../mapping/api/smToVm/profile";
import { IProfileData } from "../../models/profile";
import { XhrService } from "../../services";
import {
  UserProfileDefinitionApi,
  UserProfileResponse,
} from "../../swaggerFlips/src";
import { processError } from "../../utils/helpers";
import { ErrorEnum } from "../../view/react/core/models";
import { call } from "./axios";
import { getConfiguration } from "./swagger";


export class ProfileAPI {
  public static async getProfile(
    ignoreLogInJump: boolean = false
  ): Promise<IProfileData> {
    try {
      const data = await XhrService.call<UserProfileResponse>(
        async () => {
          return await call<UserProfileResponse>(
            async () => {
              return await this.getProfileApiInstance().apiFlipsProfileGet()
            }
          );
        },
        ignoreLogInJump
      );
      return profileSmToVm(data);
    }
    catch (error: any) {
      throw processError(
        ErrorEnum.API,
        "ProfileAPI.getProfile(). Error",
        error.message
      );
    }
  }


  private static getProfileApiInstance(): UserProfileDefinitionApi {
    return new UserProfileDefinitionApi(getConfiguration(undefined,false,false));
  }
}