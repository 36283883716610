import React, { FC, useState } from "react";
import logo from "../../template/img/logo.png";
import { AccountData, IAccountData } from "../../core/models/account";
import { IdentityService } from "../../core/xhr/identity";
import { checkNetwork, StorageService } from "../../core/services";
import NavConnected from "./NavConnected";
import { IStateScreen } from "../../core/models";
import {useAppDispatch, useAppSelector} from "../../core/stores/store";
import { setLoggedOutAccount, setOpenHowToVerifyAction } from "../../core/stores/accountSlice";

interface INav {
  setOpenConnectWalletCallback: (param: boolean) => void;
  setOpenHowToPlayCallback: (param: boolean) => void;
  setOpenFaqCallback: (param: boolean) => void;
  setOpenProvablyFairCallback: (param: boolean) => void;
  setOpenHowToVerify: (param: boolean) => void;
  setNoAuthChoiceModCallback: (param: IStateScreen) => void;
  setOpenWrongNetworkCallback: (param: boolean) => void;
  setStateScreenCallback: (param: IStateScreen) => void;
  stateScreen: IStateScreen;
  pushSound: HTMLAudioElement
}

const Nav: FC<INav> = ({
  setOpenConnectWalletCallback,
  setOpenHowToPlayCallback,
  setOpenFaqCallback,
  setOpenProvablyFairCallback,
  setOpenHowToVerify,
  setOpenWrongNetworkCallback,
  setNoAuthChoiceModCallback,
  setStateScreenCallback,
  stateScreen,
  pushSound
}) => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);

  const settings = useAppSelector(state => state.globalSettings.settings);
  const profile = useAppSelector(state => state.account.data);
  const dispatch = useAppDispatch();

  const setOpenDropDownCallback = (param: boolean) => {
    setOpenDropDown(param)
  }

  return (
    <div className={`nav${openMobileMenu ? " open" : ""}`}>
      <div className="container">
        {profile.isAuthorized ?
          <NavConnected setOpenWrongNetworkCallback={setOpenWrongNetworkCallback}
                        setOpenDropDownCallback={setOpenDropDownCallback}
                        setStateScreenCallback={setStateScreenCallback}
                        stateScreen={stateScreen} openDropDown={openDropDown}
                        isTrueNetwork={profile.isTrueNetwork}
                        networkId={profile.walletData.network.id}/>
          :
          <div className={`logo${openDropDown? " show" :""}`} onClick={()=>setOpenDropDown(!openDropDown)}>
            <a href="/" className="logo-img">
              <img src={logo} alt="" />
            </a>

            <div className="chose">
              {(stateScreen === IStateScreen.PlayInitial) && <div className="chose-text">Choose game mode</div>}
              {stateScreen === IStateScreen.PlayFree && <div className="chose-text">Free flip</div>}

              <div className="chose-menu">
                <a className={stateScreen === IStateScreen.PlayFree ? "active" : ""}
                   onClick={() => {
                     setStateScreenCallback(IStateScreen.PlayFree);
                     StorageService.mode.set(IStateScreen.PlayFree);
                     setOpenDropDown(!openDropDown)
                   }}>
                  Free flip
                </a>

                <a
                  className={(stateScreen === IStateScreen.Play || stateScreen === IStateScreen.Deposit || stateScreen === IStateScreen.Withdraw) ? "active" : ""}
                  onClick={() => {
                    setOpenDropDown(!openDropDown);
                    setNoAuthChoiceModCallback(IStateScreen.Play);
                    setOpenConnectWalletCallback(true);
                  }}>
                  GamerFi balance
                </a>

                <a className={stateScreen === IStateScreen.PlayToWallet ? "active" : ""}
                   onClick={() => {
                     setOpenDropDown(!openDropDown);
                     setNoAuthChoiceModCallback(IStateScreen.PlayToWallet);
                     setOpenConnectWalletCallback(true)
                   }}>
                  Flip from wallet
                </a>

              </div>
            </div>
          </div>
        }
        <div className="menu-btn">
          {profile.isAuthorized &&
            <>
              <a className={`btn-vl btn-blue screen-open${stateScreen === IStateScreen.Deposit ? " active" : ""}`}
                   onClick={() => {
                     pushSound.play().then();
                     if (checkNetwork(profile.walletData.network.id, settings)) {
                       setStateScreenCallback(IStateScreen.Deposit);
                     }
                     else {
                       setOpenWrongNetworkCallback(true);
                     }
                   }}><span>Deposit</span></a>
              <a className={`btn-vl btn-blue screen-open${stateScreen === IStateScreen.Withdraw ? " active" : ""}`}
                   data-id="modal-13"
                   onClick={() => {
                     pushSound.play().then();
                     if (checkNetwork(profile.walletData.network.id, settings)) {
                       setStateScreenCallback(IStateScreen.Withdraw);
                     }
                     else {
                       setOpenWrongNetworkCallback(true);
                     }
                   }}><span>Withdraw</span></a>
            </>
          }
          <a className="btn-vl btn-pink modal-open"
               onClick={() => {
                 pushSound.play().then();
                 if (profile.isAuthorized) {
                   IdentityService.logOut(false);
                   dispatch(setLoggedOutAccount());
                 }
                 else {
                   setOpenConnectWalletCallback(true);
                 }
               }}>
            {!profile.isAuthorized ? <span>Connect</span> : <span>Logout</span>}
          </a>

        </div>
        <div className="menu-burger">
          <div className="burger" onClick={() => {
            setOpenMobileMenu(!openMobileMenu);
            if (openMobileMenu)
              document.querySelector("body")?.classList.remove("overflow-hidden");
            else
              document.querySelector("body")?.classList.add("overflow-hidden");
          }}>Menu</div>
          <div className="burger-box">
            <a className="modal-open" onClick={() => {
              setOpenHowToPlayCallback(true);
              setOpenMobileMenu(false);
              document.querySelector("body")?.classList.remove("overflow-hidden");
            }}>How To Play</a>
            <a className="modal-open" onClick={() => {
              setOpenProvablyFairCallback(true);
              setOpenMobileMenu(false);
              document.querySelector("body")?.classList.remove("overflow-hidden");
            }}>Provably fair</a>
            <a className="modal-open" onClick={() => {
              setOpenHowToVerify(true);
              //dispatch(setOpenHowToVerifyAction(true))
              setOpenMobileMenu(false);
              document.querySelector("body")?.classList.remove("overflow-hidden");
            }}>How to verify</a>
            <a className="modal-open" onClick={() => {
              setOpenFaqCallback(true);
              setOpenMobileMenu(false);
              document.querySelector("body")?.classList.remove("overflow-hidden");
            }}>FAQ</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;