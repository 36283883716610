import React, { FC } from "react";
import close from "../../template/img/close.svg";
import { addressBaseURL } from "../../core/settings/common.settings";

interface IProvablyFairModal {
  openProvablyFair: boolean;
  setOpenProvablyFairCallback: (param: boolean) => void;
  setOpenHowToVerifyCallback: (param: boolean) => void;
  pushSound: HTMLAudioElement
}

const ProvablyFairModal: FC<IProvablyFairModal> = ({
  openProvablyFair,
  setOpenProvablyFairCallback,
  setOpenHowToVerifyCallback,
  pushSound
}) => {

  return (
    <div className={`modal-bg${openProvablyFair ? " show" : ""}`}>
      <div className={`modal${openProvablyFair ? " show" : ""}`} id="modal-7">
        <a className="close modal-close" onClick={() => setOpenProvablyFairCallback(false)}>
          <img src={close} alt="" />
        </a>
        <div className="content">
          <div className="rw mt-30 mb-30">
            <div className="c-m-6 htp htp3">

            </div>
            <div className="c-m-6">
              <div className="htp-title mb-30">Provably fair</div>
              <div className="htp-text mb-15">GAMERFI uses blockchain technology with every paid flip to ensure
                fairness and transparency with everyone being able to verify their results. We use random seed
                generation for verifiable randomness, a tamper-proof source of randomness beyond our control. This
                ensures GAMERFI is fair and unbiased.
              </div>
              <div className="htp-text mb-15">You can verify how we got the result at the end of every paid flip. It
                will take you to blockchain scan platform to track your transaction, and then you can copy and paste
                the converted hex number into the <a href={addressBaseURL + "/random"}
                                                     target="_blank" className="text-red text-decoration-underline">
                  Mersenne twister algorithm</a> (either on GamerFi or Google any other website using this algorithm)
                providing the final untampered result. Check out <a className="text-red text-decoration-underline"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                      setOpenProvablyFairCallback(false);
                                                                      setOpenHowToVerifyCallback(true);
                                                                    }}>How to verify</a> detailed guidance.
              </div>
              <div className="htp-text mb-15">The fairest 50/50 gaming online.</div>
              <div className="htp-text">PLAY, WIN, CASH IN with peace of mind.</div>
              <div className="mt-60">
                <a className="btn-vl btn-pink modal-close screen-open mx-auto"
                   onClick={() => {
                     pushSound.play().then();
                     setOpenProvablyFairCallback(false);
                   }}>
                  <span>Play Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProvablyFairModal;