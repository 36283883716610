import React, {FC, useEffect, useRef, useState} from "react";
import minus from "../../template/img/minus.svg";
import plus from "../../template/img/plus.svg";
import timer2 from "../../template/img/timer-2.svg";
import {maxTime, step} from "../../core/settings/common.settings";
import WinFree from "./WinFree";
import SpinningCoin from "./SpinningCoin";
import TimeIsUpFree from "./TimeIsUpFree";
import {IBullState, IStateScreen, IChainSettings, ISettingsGlobal} from "../../core/models";
import {calculationAmount} from "../../core/services";
import {calculateSliderPercent} from "../../core/utils/betSlider.util";
import LostFree from "./LostFree";
import {useAppSelector} from "../../core/stores/store";


interface IPlay {
    rangeState: number;
    bullState: IBullState;
    prc: number;
    xr: number
    stateScreen: IStateScreen;
    //freePlay: boolean | null
    setRangeStateCallback: (param: number) => void;
    setXrCallback: (param: number) => void;
    setBullStateCallback: (param: IBullState) => void;
    setPrcCallback: (param: number) => void;
    setOpenChoiceCallback: (param: boolean) => void;
    setOpenChoiceAfterFreePlayModalCallback: (param: boolean) => void;
    pushSound: HTMLAudioElement
    winSound: HTMLAudioElement
    upSound: HTMLAudioElement
    loseSound: HTMLAudioElement
}

const Play: FC<IPlay> = ({
                             rangeState,
                             bullState,
                             prc,
                             xr,
                             stateScreen,
                             setRangeStateCallback,
                             setXrCallback,
                             setBullStateCallback,
                             setPrcCallback,
                             setOpenChoiceCallback,
                             setOpenChoiceAfterFreePlayModalCallback,
                             pushSound,
                             winSound,
                             upSound,
                             loseSound
                         }) => {

    const [flip, setFlip] = useState<boolean>(false);
    const [win, setWin] = useState<boolean | null>(null);
    const [doublePlay, setDoublePlay] = useState(false);
    const [startTimer, setStartTimer] = useState<boolean | null>(null)
    const [startTimer2, setStartTimer2] = useState<boolean | null>(null)
    const [amountTimer2, setAmountTimer2] = useState<number>(maxTime);
    const [spin, setSpin] = useState<boolean | undefined | null>(undefined);
    const [showMessage, setShowMessage] = useState(false);
    const [winAmount, setWinAmount] = useState<number>(0);


    const refInput = useRef<HTMLInputElement>(null);
    const refMainBox = useRef<HTMLDivElement>(null);
    const refCircle2 = useRef<HTMLImageElement>(null);

    const settings = useAppSelector(state => state.globalSettings.settings);
    const chainConfig: IChainSettings = settings.fromBalance;

    const setDoublePlayCallback = (param: boolean) => {
        return setDoublePlay(param);
    };

    const setStartTimerCallback = (param: boolean | null) => {
        return setStartTimer(param)
    }
    const setStartTimer2Callback = (param: boolean | null) => {
        return setStartTimer2(param)
    }

    function run() {
        const prcCircle = parseInt(((amountTimer2 - 1) * 100 / maxTime).toString());
        if (refCircle2.current) {

            if (amountTimer2 > 0) {
                refCircle2.current.style.width = prcCircle + "%";
                setAmountTimer2(amountTimer2 - 1)
            }
            if (amountTimer2 === 0) {
                refCircle2.current.style.width = '0%';
                refMainBox.current?.classList.remove("active");
                setStartTimer2(false);
            }
        }
    }

    // function rangemove(e:any) {
    //   let x = 0, valueHover = 0;
    //   if(xr == 1 && e.currentTarget){
    //     const inp = refInput;
    //     const rect = e.currentTarget.getBoundingClientRect();
    //     if(e.targetTouches){
    //       x = e.targetTouches[0].pageX;
    //     }
    //     if(x < 1) x = e.offsetX;
    //     if (inp.current && refInput.current) {
    //       // @ts-ignore
    //       valueHover = ((x-70) / inp.current.clientWidth) *  parseInt(inp.current.getAttribute('max'),10).toFixed(2);
    //       refInput.current.value = valueHover.toString();
    //     }
    //
    //     console.log(x);
    //   }
    //   //handleInputChange();
    // }

    useEffect(() => {
        if (rangeState < chainConfig.minBet) {
            setRangeStateCallback(chainConfig.minBet);
        }
        if (!doublePlay && rangeState > chainConfig.maxBet) {
            setRangeStateCallback(chainConfig.maxBet);
        }
    }, [stateScreen]);

    useEffect(
        () => {
            if (refMainBox.current) {
                refMainBox.current.classList.add("active");
            }
            return () => {
                refMainBox.current?.classList.remove("active");
            };
        },
        []
    );

    useEffect(() => {
        if (refCircle2 !== null && startTimer2 && win === null && !flip) {
            setTimeout(() => {
                run()
            }, 1000)
        }
        if (refCircle2.current !== null && !startTimer2) {
            refCircle2.current.style.width = "100%";
        }
    }, [amountTimer2, startTimer2])

    useEffect(() => {
        if (doublePlay && startTimer2 === null) {
            setStartTimer2(true)
        }
        if (doublePlay && startTimer2 === false && win === null) {
            setAmountTimer2(maxTime);
            setStartTimer2(true);
        }
    }, [win])

    const startFlip = (e: React.MouseEvent<HTMLAnchorElement>) => {

        e.preventDefault();

        setFlip(true);
        upSound.play().then()
        setSpin(true);
        const amount = Math.random();
        if (refMainBox.current) {
            setTimeout(
                () => {
                    refMainBox.current?.classList.add("animation");
                    setSpin(true);
                },
                1000
            )
            refMainBox.current.onanimationend = (e) => {
                if (refMainBox.current && e.animationName === "flip-out") {
                    setSpin(false);
                    setTimeout(
                        () => {
                            if (amount <= 0.65) {
                                winSound.play().then()
                                setWin(true);
                                setWinAmount(calculationAmount(rangeState, chainConfig, doublePlay))
                            } else {
                                loseSound.play().then();
                                setBullStateCallback(bullState === IBullState.Bull ? IBullState.Bear : IBullState.Bull)
                                setWinAmount(rangeState)
                                setWin(false);
                            }
                            refMainBox.current?.classList.remove("animation");
                            refMainBox.current?.classList.remove("active");
                            refMainBox.current?.classList.remove("win");
                            refMainBox.current?.classList.remove("lose");
                            setSpin(null);
                        },
                        0
                    );
                }
            };
        }
    };
    const setFlipCancel = () => {
        setTimeout(() => refMainBox.current?.classList.add("active"), 30);
        if (win === true) {
            refMainBox.current?.classList.add("win");
        }
        if (win === false) {
            refMainBox.current?.classList.add("lose");
        }
        setFlip(false);
        setWin(null);
        setBullStateCallback(IBullState.Initial);
    };

    const setFlipCancelAll = () => {
        setFlipCancel();
        setDoublePlayCallback(false);
        setStartTimer2(null);
        setRangeStateCallback(chainConfig.startRange);
        setPrcCallback(chainConfig.startPrc);
    };

    const setFlipCancelLost = () => {
        if (doublePlay) {
            setFlipCancelAll();
        } else {
            setFlipCancel();
        }
    };

    const sliderPercent = calculateSliderPercent(rangeState, chainConfig.minBet, chainConfig.maxBet);

    return (
        <div className={`b-1${stateScreen === IStateScreen.PlayFree ? " free" : ""}`}>
            <div className="b-1-box" id="screen-1" ref={refMainBox}>
                {win === null &&
                    <>
                        {doublePlay && !flip && (
                            <div className="title">
                                <div className="mb-15">Double or Nothing</div>
                                <div className="text text-blue mb-15">${rangeState}</div>
                                <div className="earnings">You can win <span className="numbersCustom">
                  ${calculationAmount(rangeState, chainConfig, doublePlay)}
                </span></div>
                            </div>
                        )}
                        {!doublePlay && !flip && (
                            <div className="title mt-15">
                                <div>BULL OR BEAR?</div>
                            </div>
                        )}
                        {flip && (
                            <div className="title mt-15">
                                <div>GOOD LUCK!</div>
                            </div>
                        )}

                        <div className={`coints${startTimer2 ? " start-timer-2" : ""}`}>
                            <SpinningCoin bullState={bullState} spin={spin}/>
                        </div>
                        <div className={`controll${flip ? " makeInvisible" : ""}`}>
                            {doublePlay &&
                                <div className="timer-2">
                                    <div className="text-center earnings-white">
                                        <span className="earnings">You have</span> <span
                                        className="timer-2-count">{amountTimer2}</span> sec <span className="earnings">to decide</span>
                                    </div>
                                    <div>
                                        <img src={timer2} alt=""/>
                                        <div className="timer-box">
                                            <div className="timer" ref={refCircle2}></div>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="check-coints">
                                <div className={`radio${doublePlay ? " mb-30" : ""}`}>
                                    <div>
                                        <input type="radio" name="coint" value="bull" id="bull-1"
                                               checked={bullState === IBullState.Bull ? true : false} disabled={flip}
                                               onChange={() => {
                                               }}
                                        />
                                        <label htmlFor="bull-1" className="btn-vl btn-dark"
                                               onClick={() => {
                                                   pushSound.play().then();
                                                   !flip && setBullStateCallback(IBullState.Bull);
                                                   setShowMessage(false);
                                               }}>
                                            <span>Bull</span>
                                        </label>
                                    </div>
                                    <div>
                                        <input type="radio" name="coint" value="bear" id="bear-1"
                                               checked={bullState === IBullState.Bear ? true : false} disabled={flip}
                                               onChange={() => {
                                               }}
                                        />
                                        <label htmlFor="bear-1" className="btn-vl btn-red"
                                               onClick={() => {
                                                   pushSound.play().then();
                                                   !flip && setBullStateCallback(IBullState.Bear);
                                                   setShowMessage(false)
                                               }}>
                                            <span>Bear</span>
                                        </label>
                                    </div>
                                </div>
                                {!doublePlay &&
                                    <>
                                        <div className="range"
                                            // onMouseMove={(e)=>rangemove(e)}
                                            // onTouchMove={(e)=>rangemove(e)}
                                        >
                                            <a className="btn-vl btn-range minus"
                                               onClick={() => {
                                                   pushSound.play().then();
                                                   if (rangeState > chainConfig.minBet && !flip) {
                                                       if (refInput.current) {
                                                           refInput.current.value = (rangeState - step).toString();
                                                       }
                                                       setRangeStateCallback(rangeState - step);
                                                       setPrcCallback((rangeState - step - chainConfig.minBet) * 100 / (chainConfig.maxBet - chainConfig.minBet));
                                                   }
                                               }}>
                                                <span><img src={minus} alt=""/></span>
                                            </a>
                                            <div>
                                                <input type="range" min={chainConfig.minBet} max={chainConfig.maxBet}
                                                       step={step} ref={refInput} value={rangeState}
                                                       disabled={flip}
                                                       style={{backgroundSize: sliderPercent + "% 100%"}}
                                                       onChange={(e) => {
                                                           const target = +e.currentTarget.value;
                                                           setRangeStateCallback(target);
                                                           setPrcCallback((target - chainConfig.minBet) * 100 / (chainConfig.maxBet - chainConfig.minBet));
                                                       }}
                                                />
                                                <output id="rangevalue" style={{
                                                    left: "calc(" + sliderPercent + "% - 40px)",
                                                    transform: "scale(" + (0.6 + (sliderPercent) / 250) + ") translateX(" + (10 - (sliderPercent) / 10) + "px)"
                                                }}
                                                        onMouseDown={(e) => e.currentTarget.style.zIndex = "-1"}
                                                        onMouseUp={(e) => e.currentTarget.style.zIndex = "1"}

                                                >${rangeState}</output>
                                            </div>
                                            <a className="btn-vl btn-range plus"
                                               onClick={() => {
                                                   pushSound.play().then();
                                                   if (rangeState < chainConfig.maxBet && !flip) {
                                                       if (refInput.current) {
                                                           refInput.current.value = (rangeState + step).toString();
                                                       }
                                                       setRangeStateCallback(rangeState + step);
                                                       setPrcCallback((rangeState - chainConfig.minBet) * 100 / (chainConfig.maxBet - chainConfig.minBet));
                                                   }
                                               }}>
                                                <span><img src={plus} alt=""/></span>
                                            </a>
                                        </div>
                                        <div className="earnings mb-30">You can win <span id="earnings1"
                                                                                          className="numbersCustom">
                      ${calculationAmount(rangeState, chainConfig, doublePlay)}
                    </span>
                                        </div>
                                    </>
                                }

                                {stateScreen === IStateScreen.PlayFree ?
                                    <>
                                        <div className="win-btn-1">
                                            <a className="btn-vl btn-flip screen-open" data-id="screen-2"
                                               onClick={(e) => {
                                                   if (!flip) {
                                                       if (bullState === IBullState.Initial) {
                                                           setShowMessage(true);
                                                       }
                                                       if (bullState !== IBullState.Initial) {
                                                           startFlip(e);
                                                       }
                                                   }
                                               }}>
                                                <span>Flip</span>
                                            </a>
                                        </div>
                                        <div className="win-btn-2">
                                            <a className="btn-vl btn-flip btn-blue screen-open" data-id="screen-2"
                                               onClick={() => {
                                                   pushSound.play().then();
                                                   setOpenChoiceAfterFreePlayModalCallback(true);
                                                   setFlipCancelAll();
                                               }}>
                                                <span>Exit free flip</span>
                                            </a>
                                            <div
                                                className={`earnings mt-30${!showMessage ? " makeInvisible" : ""}`}>Please
                                                choose Bull or Bear
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="flip">
                                            <a className="btn-vl btn-flip screen-open" data-id="screen-2"
                                               onClick={(e) => {
                                                   if (!flip) {
                                                       if (bullState === IBullState.Initial) {
                                                           setShowMessage(true);
                                                       }
                                                       if (bullState !== IBullState.Initial) {
                                                           setOpenChoiceCallback(true);
                                                       }
                                                   }
                                               }}>
                                                <span>Flip</span>
                                            </a>
                                        </div>
                                        <div className={`earnings mt-30${!showMessage ? " makeInvisible" : ""}`}>Please
                                            choose Bull or Bear
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </>
                }
            </div>
            <WinFree bullState={bullState} rangeState={calculationAmount(rangeState, chainConfig, doublePlay)}
                     win={win} startTimer={startTimer} pushSound={pushSound}
                     winAmount={winAmount}
                     setDoublePlayCallback={setDoublePlayCallback} setRangeStateCallback={setRangeStateCallback}
                     setOpenChoiceAfterFreePlayModalCallback={setOpenChoiceAfterFreePlayModalCallback}
                     setFlipCancel={setFlipCancel}
                     setStartTimerCallback={setStartTimerCallback}
                     setStartTimer2Callback={setStartTimer2Callback}
                     setFlipCancelAll={setFlipCancelAll}/>
            <LostFree bullState={bullState} win={win} winAmount={winAmount} pushSound={pushSound}
                      setFlipCancelLost={setFlipCancelLost}
                      setOpenChoiceAfterFreePlayModalCallback={setOpenChoiceAfterFreePlayModalCallback}
                      setFlipCancelAll={setFlipCancelAll} />
            <TimeIsUpFree bullState={bullState} win={win} winAmount={winAmount} pushSound={pushSound}
                          startTimer={startTimer} startTimer2={startTimer2}
                          setOpenChoiceAfterFreePlayModalCallback={setOpenChoiceAfterFreePlayModalCallback}
                          setFlipCancelAll={setFlipCancelAll}
                          setFlipCancelLost={setFlipCancelLost}/>
        </div>
    );
};

export default React.memo(Play);