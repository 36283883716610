export interface IChainBalance {
  wei?: number;
  usdCents?: number;
}

export class ChainBalance implements IChainBalance {
  public wei: number;
  public usdCents: number;

  constructor(
    wei?: number,
    usdCents?: number
  ) {

    this.wei = wei || 0;
    this.usdCents = usdCents || 0;
  }
}