import * as signalR from "@microsoft/signalr";
import { StorageService } from "../../core/services";
import { IProfileData } from "../../core/models/profile";
import { baseUrl } from "../../core/xhr/api/api.settings";

const URL = baseUrl + "/api/flips/hub?access_token="; //or whatever your backend port is

export interface INewUserData {
  bonusCents: number
  chainId: number,
  wei: number,
  usdCents: number
}

class ConnectorBalance {
  private connection: signalR.HubConnection;
  public events: (
    onNewUserData: (payload: INewUserData) => void
  ) => void;
  static instance: ConnectorBalance;

  constructor() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(URL + StorageService.auth.get().accessToken)
      .withAutomaticReconnect()
      .build();
    this.connection.start()
      .catch(err => document.write(err));
    this.events = (onNewUserData) => {
      this.connection.on("NewUserData", (payload) => {
        onNewUserData(payload)
      })
    };
  }

  public static getInstance(): ConnectorBalance {
    if (!ConnectorBalance.instance) {
      ConnectorBalance.instance = new ConnectorBalance();
    }
    return ConnectorBalance.instance;
  }
}

export default ConnectorBalance.getInstance;